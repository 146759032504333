
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";
// import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form } from 'antd';
import { Select } from 'antd';
// import { Typography } from 'antd';
import { loadStripe } from '@stripe/stripe-js';

// const { Title } = Typography;
const { Option } = Select;
// var moment = require("moment");

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};
const stripePromise = loadStripe('pk_live_1NNfbEQHqrpbF9ouaKrVFilx00Npb3yl14',
  {
    stripeAccount: 'acct_1GYvZVLdj3tiY8tr'
  });
class Subscription_New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_plan_id: null
    }
  }

  async componentDidMount() {

    API.get("plans", `/list`)
      .then((response) => {
        // console.log(response)
        this.setState({
          plan_list: response.payload
        })
      }).catch(err => {
        // API.get("products", `/list`)
        //   .then((response) => {
        //     // console.log(response)
        //     this.setState({
        //       product_list: response.payload
        //     })
        //   }).catch(err => {
        //     console.log(err);
        //   });
        console.log(err);
      });
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    //console.log(nextProps)
  }

  async saveSubscriptionRecord(subscription) {
    await API.post("subscription", `/create`, {
      body: subscription
    });
  }

  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSelectChange = (value, option, name) => {
    console.log(value);
    // console.log(option.props.children);
    // console.log(name);

    this.setState({
      current_plan_id: value,
      current_plan: option.props.children
    })
  }

  handleSubmit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const client_id = this.props.app_state.current_user_id;
    const plan_id = this.state.current_plan_id;

    // console.log(plan_id);
    // console.log(client_id);
    // return;

    API.post("subscription", `/create`, {
      body: {
        client_id,
        plan_id
      }
    })
      .then(async database_result => {
        console.log(database_result)
        openNotificationWithIcon('info', "Subscription Started", "Processing subscription application.")
        // return database_result;
        console.log(database_result.payload.session.id)
        await API.post("email", `/interaction`, {
          body: {
            interaction: "Subscription Starting",
            data: {
              client_id: this.props.app_state.current_user_id,
              client_nickname: this.props.app_state.current_client_record.nickname,
              client_email: this.props.app_state.current_client_record.email,
              sessionId: database_result.payload.session.id,
              ...database_result.payload.session
            },
            result: "Subscription Commencing"
          }
        })
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });


        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: database_result.payload.session.id
        }, {
          stripe_account: "acct_1GYvZVLdj3tiY8tr",
        });
      })
      .catch(error => {
        console.log(error);
      });


    // try {
    //   let response = await this.saveSubscriptionRecord({
    //     client_id,
    //     plan_id,
    //   });
    //   console.log(response)
    //   openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
    //   this.props.history.push("/");
    //   window.location.href = "/";
    // } catch (e) {
    //   console.log(e)

    // //Code block to workaround recovering API error 
    // let response = await this.saveSubscriptionRecord({
    //   product_id,
    //   client_id
    // });
    // console.log(response)
    // openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
    // this.props.history.push("/");
    // window.location.href = "/";
    // //End of Code block to workaround recovering API error

    //// comment out error notification 
    //openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
    // this.setState({ isLoading: false });

    // }
  };
  render() {
    // console.log(this.state);
    // if (!this.props.app_state.current_user_id) return;

    // Prepare Selects
    let plan_list_options = []
    if (this.state.plan_list) {
      // console.log(this.state.plan_list)
    plan_list_options = this.state.plan_list.map(d => <Option key={d.id} value={d.id}>{d.nickname} - {new Intl.NumberFormat("en-GB", { style: "currency", currency: "AUD" }).format(d.retail_price)}</Option>);
    }
    // console.log(plan_list_options)
    return (

      <React.Fragment>
        <Form
          layout="vertical"
          onSubmit={this.handleSubmit}>
          <Row gutter={[96, 24]}>
          <Col xs={24} sm={12}>
              <p>
                You have no current subscription. <br />
                Please use the form below to select a new subscription.
              </p>
            </Col>
          </Row>
          <Row gutter={[96, 24]}>
          <Col xs={24} sm={12}>
              <Form.Item
                // name={['user', 'nickname']}
                label="Select Subscription Plan"
              >
                <Select
                  id="current_plan_id"
                  showSearch
                  value={
                    this.state.current_plan_id
                  }
                  placeholder="Set Status"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  onChange={(event, option) =>
                    this.handleSelectChange(event, option, "current_plan_id")
                  }
                >
                  {plan_list_options}
                </Select>
              </Form.Item>
              {/* <React.Fragment>
                Nickname: {" "}
                {(this.state.current_plan_id > 0)
                  ? this.state.plan_list[this.state.current_plan_id - 1].nickname
                  : ""
                } <br />
                Price: {" "}
                {(this.state.current_plan_id > 0)
                  ? this.state.plan_list[this.state.current_plan_id - 1].retail_price
                  : ""
                }
              </React.Fragment> */}
            </Col>
            <Col xs={24} sm={12}>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              {/* <p>
                <strong>Copy this for the next page: <br/>4000 0003 6000 0006<br/>Exp 08/22<br/>CCV 123<br/>to test the payment system</strong>
              </p> */}
              <Button
                type="primary"
                disabled={!this.state.current_plan_id}
                onClick={this.handleSubmit}
                htmlType="submit">
                Subscribe Now
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

// export default UserSummary;

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const Subscription_NewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscription_New);

export default withRouter(Subscription_NewContainer);
