// 06/08/2020 take out client rating code by Tom

// take out no using APIs - get completed date
// - API.get("videos", `/get/${this.props.app_state.current_user_id}/${this.props.file_id}`)

import React, {Component} from 'react';
import {connect} from 'react-redux';
import withStyles from 'react-jss';
import ReactPlayer from 'react-player';

// import { API, Storage } from "aws-amplify";
import {API} from 'aws-amplify';
// import { Auth } from "aws-amplify";
// import { Layout } from 'antd';
// import { Checkbox } from 'antd';
import {Row, Col} from 'antd';
// import { Card } from 'antd';
import {Switch} from 'antd';
// import { notification } from 'antd';
// import { Form, Input } from 'antd';
import {Form} from 'antd';
import {Typography} from 'antd';
import {Tag} from 'antd';
import {Skeleton} from 'antd';
//import { HeartOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {HeartOutlined, CloseOutlined} from '@ant-design/icons';

// import styles from '../../../../assets/css/react-player.css';

import {get_url} from '../../library/aws_library';

// import { Rate } from 'antd';
// const desc = ['meh', 'average', 'good', 'wow', 'amazing'];

const {Title} = Typography;

const styles = {
  video_description: {
    padding: 0,
    // paddingTop: 24,
    // background: '#fff',
    // minHeight: 360,
    '@media (min-width: 800px)': {
      padding: 24
      // paddingTop: 24,
    }
    // '@media (min-width: 1000px)': {
    //   padding: 0,
    //   paddingTop: 24,
    // },
    // '@media (min-width: 1200px)': {
    //   padding: 24,
    // },
  }
};

// const openNotificationWithIcon = (type, header, message) => {
//   notification[type]({
//     message: header,
//     description: message,
//   });
// };

var moment = require('moment');
class VideoViewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: {
        id: '',
        record_created: '',
        file_id: '',
        description: ''
      },
      // client_rating: 4,
      completed: false,
      favourite: false,
      video_watch_10: false,
      video_watch_20: false,
      video_watch_30: false,
      video_watch_40: false,
      video_watch_50: false,
      video_watch_60: false,
      video_watch_70: false,
      video_watch_80: false,
      video_watch_90: false,
      video_watch_100: false
    };
  }

  async componentDidMount() {
    await API.get('videos', `/get/id/${this.props.file_id}`)
      .then(async response => {
        // console.log(response)
        if (response.payload.file_id) {
          let video_url = await get_url(response.payload.file_id);
          this.setState({video_url});
        }
        if (response.payload.poster_file_id) {
          let poster_url = await get_url(response.payload.poster_file_id);
          this.setState({poster_url});
        }

        this.setState({
          video: response.payload
        });
      })
      .catch(err => {
        console.log(err);
      });

    if (this.props.app_state.current_user_id) {
      API.get('videos', `/ViewVideoCrossDetail/${this.props.app_state.current_user_id}/${this.props.file_id}`)
        .then(response => {
          // console.log(response.payload[0])
          // console.log(!!response.payload[0])
          if (!!response.payload[0]) {
            this.setState({
              // client_rating: response.payload[0].client_rating,
              completed: response.payload[0].completed,
              favourite: response.payload[0].favourite
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps)
    // console.log(nextProps.app_state.current_user_id);
    // console.log(nextProps.file_id);

    API.get('videos', `/ViewVideoCrossDetail/${nextProps.app_state.current_user_id}/${nextProps.file_id}`)
      .then(response => {
        // console.log(response.payload[0])
        // console.log(!!response.payload[0])
        if (!!response.payload[0]) {
          this.setState({
            // client_rating: response.payload[0].client_rating,
            completed: response.payload[0].completed,
            favourite: response.payload[0].favourite
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  // toggleChecked = () => {
  //   this.setState({ completed: !this.state.completed });
  // };

  // toggleDisable = () => {
  //   this.setState({ disabled: !this.state.disabled });
  // };

  // handleRateChange = (value) => {
  //   // console.log('client_rating = ', value);
  //   // this.setState({ client_rating: value });

  //   API.put("videos", `/update/${this.props.app_state.current_user_id}`, {
  //     body: {
  //       // client_rating: value,
  //       completed: this.state.completed,
  //       //completed_date: moment().format(), //#43 NOTE: We'll set it from backend
  //       client_id: this.props.app_state.current_user_id,
  //       video_id: this.state.video.id,
  //     },
  //   });
  // };

  // onChange = async (e) => {
  //   console.log("checked = ", e.target.checked);
  //   console.log("user = ", this.props.app_state.current_user_id);
  //   console.log("video = ", this.state.video.id);
  //   this.setState({
  //     completed: e.target.checked,
  //   });

  //   API.put("videos", `/update/${this.props.app_state.current_user_id}`, {
  //     body: {
  //       completed: e.target.checked,
  //       // client_rating: this.state.client_rating,
  //       //completed_date: moment().format(), //#43 NOTE: We'll set it from backend
  //       client_id: this.props.app_state.current_user_id,
  //       video_id: this.state.video.id,
  //     },
  //   });
  // };

  // handle_completed = async (e) => {
  //   console.log("checked = ", e);
  //   console.log("user = ", this.props.app_state.current_user_id);
  //   console.log("video = ", this.state.video.id);

  //   this.setState({
  //     completed: e,
  //   });
  //   API.put("videos", `/update/${this.props.app_state.current_user_id}`, {
  //     body: {
  //       completed: e,
  //       // client_rating: this.state.client_rating,
  //       favourite: this.state.favourite,
  //       //completed_date: moment().format(), //#43 NOTE: We'll set it from backend
  //       client_id: this.props.app_state.current_user_id,
  //       video_id: this.state.video.id,
  //     },
  //   });
  // };
  onToggleFavourite = async e => {
    // console.log('favourite checked = ', e);
    // console.log('user = ', this.props.app_state.current_user_id);
    // console.log('video = ', this.state.video.id);

    this.setState({
      favourite: e
    });
    API.put('videos', `/update/${this.props.app_state.current_user_id}`, {
      body: {
        completed: this.state.completed,
        // client_rating: this.state.client_rating,
        favourite: e,
        //completed_date: moment().format(), //#43 NOTE: We'll set it from backend
        client_id: this.props.app_state.current_user_id,
        video_id: this.state.video.id
      }
    });
  };

  onVideoStart = () => {
    // console.log('On Video Start');
    API.put('videos', `/on_start/${this.props.app_state.current_user_id}/${this.state.video.id}`, {
      body: {
        client_id: this.props.app_state.current_user_id,
        video_id: this.state.video.id
      }
    });
  };

  onVideoProgress = e => {
    // console.log(e.played.toFixed(2))
    if (this.state.video_watch_100) return;
    // console.log(this.state)
    switch (e.played.toFixed(2)) {
      case '0.10':
        // console.log("Video view: 10%")
        this.setState({
          video_watch_10: true
        });
        break;
      case '0.20':
        // console.log("Video view: 20%")
        this.setState({
          video_watch_20: true
        });
        break;
      case '0.30':
        // console.log("Video view: 30%")
        this.setState({
          video_watch_30: true
        });
        break;
      case '0.40':
        // console.log("Video view: 40%")
        this.setState({
          video_watch_40: true
        });
        break;
      case '0.50':
        // console.log("Video view: 50%")
        this.setState({
          video_watch_50: true
        });
        break;
      case '0.60':
        // console.log("Video view: 60%")
        this.setState({
          video_watch_60: true
        });
        break;
      case '0.70':
        // console.log("Video view: 70%")
        this.setState({
          video_watch_70: true
        });
        break;
      case '0.80':
        // console.log("Video view: 80%")
        this.setState({
          video_watch_80: true
        });
        break;
      case '0.90':
        // console.log("Video view: 90%")
        this.setState({
          video_watch_90: true
        });
        break;
      default:
        break;
    }
    if (
      // this.state.video_watch_10 &&
      this.state.video_watch_20 &&
      // this.state.video_watch_30 &&
      // this.state.video_watch_40 &&
      // this.state.video_watch_50 &&
      // this.state.video_watch_60 &&
      this.state.video_watch_70
    ) {
      // Add code here which writes to video_client_view table
      // console.log("Video view: 10, 20, 30, 40, 50, 60, 70% triggered")
      // console.log("Video view: 20 and 70% triggered")

      if (!this.state.video_watch_100) {
        // console.log("Writing to database that video completed")
        API.put('videos', `/on_finish/${this.props.app_state.current_user_id}/${this.state.video.id}`, {
          body: {
            client_id: this.props.app_state.current_user_id,
            video_id: this.state.video.id
          }
        });

        // console.log(this.state);
        // console.log("Set system to treat video at 100% viewed")
        this.setState({
          video_watch_100: true
        });
      }
    }
    // console.log(this.state)
  };

  render() {
    const {classes} = this.props;
    // console.log(this.state);
    // console.log(this.props);

    //if (!this.state.isLoading) return null;
    // if (!this.props.app_state || !this.props.app_state.data_countries_list) return null;

    // let main_component = <YogaPilatesPageComponentContainer />;
    if (!this.state.video.description) return <Skeleton active />;

    let content_type;
    switch (this.state.video.free_content) {
      case 1:
        content_type = (
          <Tag color={'green'} style={{width: 100, textAlign: 'center'}} key={'free'}>
            FREE CONTENT
          </Tag>
        );
        break;
      default:
        content_type = (
          <Tag color={'blue'} style={{width: 100, textAlign: 'center'}} key={'standard'}>
            STANDARD
          </Tag>
        );
    }

    return (
      <React.Fragment>
        <div className={classes.root}>
          {/* <Row gutter={[96, 24]}>
          <Col xs={12}>
            
            
          </Col>
        </Row> */}

          <Row style={{padding: 0}}>
            <Col xs={24} md={12}>
              <div className='player-wrapper'>
                <ReactPlayer
                  url={this.state.video_url} //'https://tom-test-1234.s3-ap-southeast-1.amazonaws.com/Saturday+1.mp4'
                  // playing
                  style={{padding: 0}}
                  light={this.state.poster_url}
                  width='100%'
                  height='400px'
                  controls
                  onStart={this.onVideoStart}
                  onProgress={this.onVideoProgress}
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload'
                      }
                    }
                  }}
                />
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className={classes.video_description}>
                <Title level={2}>
                  {this.state.video.description} - {moment(this.state.video.public_date).format('DD MMM YYYY')}
                </Title>
                <Form layout='vertical'>
                  <Form.Item>{this.state.video.long_description}</Form.Item>
                  <Form.Item>
                    {this.state.completed_date && (
                      <React.Fragment>
                        <span style={{color: '#c30f48'}}>
                          You have completed this video on {moment(this.state.completed_date).format('DD/MM/YYYY')}
                        </span>
                      </React.Fragment>
                    )}
                    {/*<Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={this.handle_completed}
                      checked={!!this.state.completed}
                    /> &nbsp;&nbsp;<span style={{ color: "#c30f48" }}>Have you given this workout a crack?</span>*/}
                  </Form.Item>
                  <Form.Item>
                    <Switch
                      checkedChildren={<HeartOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={this.onToggleFavourite}
                      checked={!!this.state.favourite}
                    />{' '}
                    &nbsp;&nbsp;
                    <span style={{color: '#c30f48'}}>Is this a favourite?</span>
                  </Form.Item>
                  {/* <Form.Item
                label="Favourite Workout"
              >
                <Checkbox
                  checked={this.state.completed}
                  //disabled={this.state.disabled}
                  onChange={this.onChange}
                >
                  Is this a favourite?
                </Checkbox>
              </Form.Item> */}
                  {/* <Form.Item
                label="My Rating"
              >
                <span>
                  <Rate 
                    tooltips={desc} 
                    onChange={this.handleRateChange} 
                    value={this.state.client_rating} 
                  />
                  {
                    this.state.client_rating 
                      ? <span className="ant-rate-text">{desc[this.state.client_rating - 1]}</span> 
                      : ''
                  }
                </span>
              </Form.Item> */}
                </Form>
              </div>
            </Col>
          </Row>
          <Row gutter={[96, 24]}>
            <Col xs={24} style={{textAlign: 'right'}}>
              {/*<Button 
                type="primary" 
                htmlType="submit" 
                disabled
              >
                Save Record
              </Button>*/}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

// export default UserSummary;

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  };
};

const VideoViewPageComponentContainer = connect(mapStateToProps, mapDispatchToProps)(VideoViewPageComponent);

export default withStyles(styles)(VideoViewPageComponentContainer);
