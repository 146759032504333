import DashboardPageContainer from '../views/dashboard/DashboardPage';
// import DailyHiitsListPage from "../views/dashboard/daily_hiits/DailyHiitsListPage";
// import DailyHiitsViewPage from "../views/dashboard/daily_hiits/DailyHiitsViewPage";
import FavouritesListPage from '../views/dashboard/favourites/FavouritesListPage';
import HistoryListPage from '../views/dashboard/history/HistoryListPage';
// import QuickHiitsListPage from "../views/dashboard/quickhiits/QuickHiitsListPage";
// import QuickHiitsViewPage from "../views/dashboard/quickhiits/QuickHiitsViewPage";
// import YogaPilatesListPage from "../views/dashboard/yoga_pilates/YogaPilatesListPage";
import VideoMainCategoryPage from '../views/dashboard/video/VideoMainCategoryPage';
import VideoStudioFavouritesPage from '../views/dashboard/video/VideoStudioFavouritesPage';
import VideoSubCategoryPage from '../views/dashboard/video/VideoSubCategoryPage';
import VideoViewPage from '../views/dashboard/video/VideoViewPage';
import MyAccountPageContainer from '../views/dashboard/MyAccountPage';
import MyAccountSubscriptionSuccessPageContainer from '../views/dashboard/MyAccountSubscriptionSuccessPage';
import MyAccountSubscriptionCancelPageContainer from '../views/dashboard/MyAccountSubscriptionCancelPage';
import WorkoutBuilderCreatePage from '../views/dashboard/workout_builder/WorkoutBuilderCreatePage'
import WorkoutBuilderListPage from '../views/dashboard/workout_builder/WorkoutBuilderListPage'
import WorkoutBuilderViewPage from '../views/dashboard/workout_builder/WorkoutBuilderViewPage'
import ExercisePreviewPage from '../views/dashboard/workout_builder/ExercisePreviewPage'

const dashRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPageContainer
  },
  // {
  //   path: "/daily-hiits/list",
  //   name: "Daily HIITS",
  //   component: DailyHiitsListPage
  // },
  {
    path: '/favourites',
    name: 'Favourites',
    component: FavouritesListPage
  },
  {
    path: '/history',
    name: 'History',
    component: HistoryListPage
  },
  // {
  //   path: "/quick-hiits/list",
  //   name: "Quick Hiits",
  //   component: QuickHiitsListPage
  // },
  // {
  //   path: "/wellness/list",
  //   name: "Wellness",
  //   component: YogaPilatesListPage
  // },
  {
    path: '/video/category/:main_slug/:sub_slug',
    name: 'Video',
    component: VideoSubCategoryPage
  },
  {
    path: '/video/category/:main_slug',
    name: 'Video',
    component: VideoMainCategoryPage
  },
  {
    path: '/video/studio-favourites',
    name: 'Video',
    component: VideoStudioFavouritesPage
  },
  {
    path: '/video/view/:file_id',
    name: 'Video',
    component: VideoViewPage
  },
  {
    path: '/my-account/subscription/success',
    name: 'My Account',
    component: MyAccountSubscriptionSuccessPageContainer
  },
  {
    path: '/my-account/subscription/cancel',
    name: 'My Account',
    component: MyAccountSubscriptionCancelPageContainer
  },
  {
    path: '/my-account',
    name: 'My Account',
    component: MyAccountPageContainer
  },
  {
    path: '/workout-builder/create',
    name: 'Workout Builder',
    component: WorkoutBuilderCreatePage
  },
  {
    path: '/workout-builder/list',
    name: 'Workout Builder List',
    component: WorkoutBuilderListPage
  },
  {
    path: '/workout-builder/view/:workout_id',
    name: 'Workout Builder View',
    component: WorkoutBuilderViewPage
  },
  {
    path: '/exercise/view/:exercise_id',
    name: 'Exercise Preview',
    component: ExercisePreviewPage
  },
  
  {
    redirect: true,
    path: '/',
    pathTo: '/dashboard',
    name: 'Dashboard'
  }
];

export default dashRoutes;
