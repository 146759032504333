import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from 'react-redux';
// import { FormattedMessage } from 'react-intl';

import {
  createNewWorkoutBuilder,
  updateNewWorkoutBuilder
} from '../../../redux/actions/workout_builder';

import {
  Card, PageHeader, Button, Typography, Row, Col
} from 'antd';

import WorkoutBuilderCreatePageComponent from '../../../components/workout_builder/WorkoutBuilderCreatePageComponent';

const background_image_1 = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_426_blend_700x400.jpg');
// const background_image_2 = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_124_blend_700x400.jpg');
// const background_image_3 = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_067_blend_700x400.jpg');
// const pink_xo = require('../../../assets/img/pink_xo.jpg');

const {Title} = Typography;

const styles = {
  root: {
    backgroundImage: `url(${background_image_1})`,
    height: '100%',
    minHeight: 360,
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    backgroundColor: '#111',
    marginBottom: 40,
    '@media (max-width: 800px)': {backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${background_image_1})`},
    '@media (max-width: 500px)': {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${background_image_1})`,
      height: '100vh',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      marginBottom: 0
    }
  },
  menu_text: {
    color: 'white',
    fontWeight: 400,
    fontSize: '1.0em',
    '@media (max-width: 500px)': {
      fontWeight: 800,
      fontSize: '1.2em'
    }
  },
  hero_text: {
    fontSize: '1.4em',
    fontWeight: 600,
    textAlign: 'justify',
    color: 'white',
    '@media (max-width: 500px)': {
      fontWeight: 600,
      fontSize: '1.2em'
    }
  },
  main_card: {
    width: '100%',
    padding: 0,
    // '@media (min-width: 800px)': {
    //   width: "80%",
    // },
    // '@media (min-width: 1000px)': {
    //   width: "80%",
    // },
    // '@media (min-width: 1200px)': {
    //   width: "50%",
    // },
  },
};

class WorkoutBuilderCreatePage extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //     category_id: 1
    // }
  }

    handle_show_all = () => {
      this.props.history.push('/workout-builder/list');
    }
  
    handle_create_workouts = () => {
      this.props.history.push('/workout-builder/create');
    }

    render() {
      const { classes } = this.props;

      return (
        <React.Fragment>
          <div
            className={`${classes.root}`}
          >
            <Row>
              <Col lg={{span: 12}}>
                <div style={{margin: 40}}>
                  <Title level={2}>Workout Builder</Title>
                  <p className={classes.hero_text}>
                    Create a New Workout
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          
          <Card
            style={{ minHeight: 360 }}
            extra={[
              <React.Fragment>
                {
                  <span>
                    <Button key="1" onClick={this.handle_show_all}>
                      My Workouts
                    </Button>
                    <Button disabled key="2" onClick={this.handle_create_workouts}>
                      Create New Workouts
                    </Button>
                  </span>
                }
              </React.Fragment>
    
            ]}
          >
            <div style={{ paddingTop: 24 }}>
              <WorkoutBuilderCreatePageComponent />
            </div>
          </Card>
        </React.Fragment>
      );

      // return (
      //     <div className={classes.root}>
      //       <WorkoutBuilderCreatePageComponent />
      //     </div>
      // );
    }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
  };
};
  
const mapDispatchToProps = dispatch => {
  return {
    createNewWorkoutBuilder: () => {
      dispatch(createNewWorkoutBuilder())
    },
    updateNewWorkoutBuilder: (key, value) => {
      dispatch(updateNewWorkoutBuilder(key, value))
    }
  }
}
  
const WorkoutBuilderCreatePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WorkoutBuilderCreatePage));
  
export default WorkoutBuilderCreatePageContainer;
