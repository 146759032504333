import PagesContainer from "../layouts/Pages.jsx";
import DashboardContainer from "../layouts/Dashboard.jsx";

// import DatePickerSample from "../components/DatePickerSample/DatePickerSample";
// import DefaultPage from "../components/DefaultPage"
// import LoginPage from "../views/Pages/LoginPage";

var indexRoutes = [
  { path: "/pages", name: "Pages", component: PagesContainer
    //component: PagesContainer 
  },
  { path: "/", name: "Home", component: DashboardContainer
    //component: Dashboard 
  }
];

export default indexRoutes;
