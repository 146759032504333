import { API } from "aws-amplify";

// export const UPDATE_CURRENT_BRAND = "UPDATE_CURRENT_BRAND";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const DATA_COUNTRIES = "DATA_COUNTRIES";
// export const UPDATE_CURRENT_USER_ID = "UPDATE_CURRENT_USER_ID";
// export const UPDATE_CURRENT_USER_LOG_OUT = "UPDATE_CURRENT_USER_LOG_OUT";
// export const UPDATE_ECONOMIC_CALENDAR = "UPDATE_ECONOMIC_CALENDAR";

export function onLoginSuccess(user) {
  return async function (dispatch) {
    try {
      // await update_client_database(aws_cognito_id, {
      //   portal_last_login_datetime: new Date().toISOString().slice(0, 19).replace('T', ' ')
      // })
      // console.log(user);
      const client_record = await get_current_client_details(user);
      const countries_list = await get_countries();
      const log_login_record = await log_login(user);
      if (client_record)
        dispatch(updateCurrentClientIntoStore(client_record));
      // const division_details = await get_current_division_details(client_record.division_id)
      // console.log(client_record)
      // console.log(division_details)
      // console.log(log_login_record);
      dispatch(updateCountriesIntoStore(countries_list));
      // dispatch(createNewStaffMemberComplete());
    } catch (e) {
      // alert(e.message);
      console.log(e);
    }
  };
}

async function get_current_client_details(user) {
  // console.log(user);
  if (user.username) {
    return API.get(
      "users",
      `/get/aws/${user.username}`
    )
      .then(response => {
        // console.log(response.payload);
        return response.payload;
      })
      .catch(error => {
        console.log(error);
        // return API.get(
        //   "users",
        //   `/get/aws/${user.username}`
        // )
        //   .then(response => {
        //     console.log(response.payload);
        //     return response.payload;
        //   })
      })
  }
  else if (user.email) {
    return API.get(
      "users",
      `/get/email/${user.email}`
    )
      .then(response => {
        // console.log(response.payload);
        return response.payload;
      })
      .catch(error => {
        console.log(error);
      })
  }
}

async function get_countries() {
  // console.log(user);
  return API.get(
    "countries",
    `/list`
  )
    .then(response => {
      // console.log(response.payload);
      return response.payload;
    })
    .catch(error => {
      console.log(error);
    })
}

async function log_login(user) {
  // console.log(user);
  if (user.username) {
    return API.post(
      "logging",
      `/create`,{
        body: {
          log_type: 1
        }
      }
    )
      .then(response => {
        // console.log(response.payload);
        return response.payload;
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export function updateCountriesIntoStore(countries) {
  return {
    type: DATA_COUNTRIES,
    countries: countries,
  };
}
export function updateCurrentClientIntoStore(client_record) {
  return {
    type: UPDATE_CURRENT_USER,
    user_id: client_record.id,
    aws_cognito_id: client_record.aws_cognito_id,
    current_client_record: client_record,
    // brand: brand,
    // current_division: division_details
  };
}
