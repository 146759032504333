
import React, { Component } from 'react';
import { connect } from "react-redux";

// import { API, Auth } from "aws-amplify";
// import { Layout, Menu, Breadcrumb, Icon, Card } from 'antd';
import { Skeleton } from 'antd';
import { PageHeader } from 'antd';
import MyAccountSubscriptionSuccessPageComponent from '../../components/pages/dashboard/my_account/MyAccountSubscriptionSuccessPageComponent';
const queryString = require('query-string');

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class MyAccountSubscriptionSuccessPage extends Component {
  constructor(props) {
    super(props);
    const parsed = queryString.parse(this.props.location.search);
    this.state = {
      session_id: parsed.session_id
    }
  }

  render() {
    // console.log(this.state);

    let main_component = <MyAccountSubscriptionSuccessPageComponent session_id={this.state.session_id} />;
    if (!this.props.app_state.current_client_record) main_component = <Skeleton active />;

    return (
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="My Account"
        />
        {/* <Card
          style={{ minHeight: 360 }}
          title="Subscription Success"
        > */}
        <div style={{ paddingTop: 24 }}>
          {main_component}
        </div>
        {/* </Card> */}
      </div>
    );
  }
}

// export default MyAccountPage;

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const MyAccountSubscriptionSuccessPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccountSubscriptionSuccessPage);

export default MyAccountSubscriptionSuccessPageContainer;
