import React from "react";
import ReactDOM from "react-dom";
import './index.css';
// import App from './App';
import AppContainer from "./App";

import * as Sentry from '@sentry/browser';
import ReactPixel from 'react-facebook-pixel';


import * as serviceWorker from './serviceWorker';

import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers/index.js";

import { createLogger } from "redux-logger";

import thunkMiddleware from "redux-thunk";


// Sentry.init({dsn: "https://10abf8a81c0e4901b7dd3cce37406767@o375487.ingest.sentry.io/5239324"});

// Facebook Pixel
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init('162437151143905', options);
ReactPixel.pageView(); // For tracking page view


const logger = createLogger({
    collapsed: true
  });
  
  const store = createStore(
    rootReducer,
    applyMiddleware(thunkMiddleware, logger)
  );
  
  ReactDOM.render(
    <Provider store={store}>
      <AppContainer />
    </Provider>,
    document.getElementById("root")
  );



  // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

