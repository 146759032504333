import { combineReducers } from "redux";

import app_state from "./app_state";
import language from "./language";
import workout_builder from "./workout_builder";

// const initialState = {
//   test_value: 0
// };

// function app_state(state = initialState, action) {
//   switch (action.type) {
//     case TEST:
//       return Object.assign({}, state, {
//          test: action.value
//       });

//     default:
//       return state;
//   }
// }

const rootReducer = combineReducers({
  app_state,
  language,
  workout_builder
});

export default rootReducer;
