import React, { Component } from 'react';
import withStyles from 'react-jss';
import {
  Card, Button, Row, Col, Typography
} from 'antd';

import WorkoutBuilderListPageComponent from '../../../components/workout_builder/WorkoutBuilderListPageComponent';

const background_image_1 = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_426_blend_700x400.jpg');
// const background_image_2 = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_124_blend_700x400.jpg');
// const background_image_3 = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_067_blend_700x400.jpg');
// const pink_xo = require('../../../assets/img/pink_xo.jpg');

const {Title} = Typography;

const styles = {
  root: {
    backgroundImage: `url(${background_image_1})`,
    height: '100%',
    minHeight: 360,
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    backgroundColor: '#111',
    marginBottom: 40,
    '@media (max-width: 800px)': {backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${background_image_1})`},
    '@media (max-width: 500px)': {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${background_image_1})`,
      height: '100vh',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      marginBottom: 0
    }
  },
  menu_text: {
    color: 'white',
    fontWeight: 400,
    fontSize: '1.0em',
    '@media (max-width: 500px)': {
      fontWeight: 800,
      fontSize: '1.2em'
    }
  },
  hero_text: {
    fontSize: '1.4em',
    fontWeight: 600,
    textAlign: 'justify',
    color: 'white',
    '@media (max-width: 500px)': {
      fontWeight: 600,
      fontSize: '1.2em'
    }
  }
};
class WorkoutBuilderListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    
  }

  handle_show_all = () => {
    this.props.history.push('/workout-builder/list');
  }

  handle_create_workouts = () => {
    this.props.history.push('/workout-builder/create');
  }

  render() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <div
          className={`${classes.root}`}
        >
          <Row>
            <Col lg={{span: 12}}>
              <div style={{margin: 40}}>
                <Title level={2}>Workout Builder</Title>
                <p className={classes.hero_text}>
                  Welcome to the XOlation workout builder.
                </p>
                <p className={classes.hero_text}>
                  This is where YOU create your own workout with what you want to work on most and how long you want to do it.
                </p>
                <p className={classes.hero_text}>
                  Don't like burpees? Fine, never pick them.
                </p>
                <p className={classes.hero_text}>
                  Create a collection of workouts where you can always have a workout you will want to have a crack at.
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <Card
          style={{ minHeight: 360 }}
          title=""
          extra={[
            <React.Fragment>
              {
                <span>
                  <Button disabled key="1" onClick={this.handle_show_all}>
                    My Workouts
                  </Button>
                  <Button key="2" onClick={this.handle_create_workouts}>
                    Create New Workouts
                  </Button>
                </span>
              }
            </React.Fragment>

          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <WorkoutBuilderListPageComponent />
          </div>
        </Card>
      </React.Fragment>

    );
  }
}

export default withStyles(styles)(WorkoutBuilderListPage);
