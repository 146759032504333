
import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";
// import { Layout } from 'antd';
import { Divider } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
// import { notification } from 'antd';
import { Input } from 'antd';
import { Table } from 'antd';

import { Tag } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

import { get_url } from "../../library/aws_library";
import { QuestionCircleOutlined } from "@ant-design/icons";

var moment = require("moment");

// const openNotificationWithIcon = (type, header, message) => {
//   notification[type]({
//     message: header,
//     description: message,
//   });
// };

class VideoHistoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //videoList:[]
    }
    this.columns = [
      {
        title: 'Date',
        //dataIndex: 'public_date', //WARN: don't use this one.. it will change the value of record
        key: 'public_date',
        // width: 150,
        responsive: ['md'],
        sorter: (a, b) => moment(a.public_date).unix() - moment(b.public_date).unix(),
        defaultSortOrder: "descend",
        render: (text, record) => {
          return {
            props: {
              style: { verticalAlign: "top" }
            },
            children: <React.Fragment>
              {/* <span>
                {moment(record.public_date).format('DD MMMM YYYY')}
                <br />
                {moment(record.public_date).format('HH:mm a')}
              </span> */}
            </React.Fragment>
          }
        }
      },
      {
        title: 'Video',
        dataIndex: 'poster',
        // width: 150,
        responsive: ['md'],

        render: (text, record) => {
          // console.log(record)
          if (!record.is_poster) {
            return <React.Fragment><div style={{ textAlign: "center", fontSize: 20 }}><QuestionCircleOutlined /></div></React.Fragment>
          }
          return <React.Fragment>
            <div>
              <NavLink to={`/video/view/${record.id}`} >
                {/* <img src={text} style={{ height: this.props.size === "small" ? 90 : 180, maxWidth: this.props.size === "small" ? 160 : 320 }}></img> */}
                <img src={text} style={{ height: 180, maxWidth: 320 }}></img>
              </NavLink>
            </div>
          </React.Fragment>
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        ...this.getColumnSearchProps('description'),
        sorter: (a, b) => moment(a.public_date).unix() - moment(b.public_date).unix(),
        defaultSortOrder: "descend",
        render: (text, record) => {
          return {
            props: {
              style: { verticalAlign: "top" }
            },
            children: <React.Fragment>

              <div style={{ height: "100%", verticalAlign: "top" }}>
                <NavLink to={`/video/view/${record.id}`} >
                  <h2>{text}</h2>
                  <em>
                    {moment(record.public_date).format('DD MMMM YYYY')}
                  </em>
                </NavLink>
                    &nbsp;
                    {record.record_datetime &&
                      <React.Fragment>
                      (workout completed {moment(record.record_datetime).format('DD MMMM YYYY: HH:mm')})
                      </React.Fragment>
                    }
                    {!record.record_datetime &&
                      <React.Fragment>
                      (workout completed {moment(record.record_date).format('DD MMMM YYYY')})
                      </React.Fragment>
                    }

                <div style={{ width: "50%" }}>
                  <Divider />
                </div>
                <span style={{ fontSize: "1.1em", fontWeight: 500 }}>
                  {record.long_description}
                  <br />
                </span>
              </div>
            </React.Fragment>
          }
        }
      },
      {
        title: 'Categories',
        dataIndex: 'free_content',
        responsive: ['lg'],
        render: (data_item, record) => {
          let free_content_tag;
          let main_category_tag;
          let sub_category_tag;
          switch (data_item) {
            case 1:
              free_content_tag = <Tag color={'green'} style={{ width: 100, textAlign: "center" }} key={data_item}>FREE CONTENT</Tag>
            default:
              free_content_tag = <Tag color={'geekblue'} style={{ width: 100, textAlign: "center" }} key={data_item}>STANDARD</Tag>
          }

          main_category_tag = <Tag color={'blue'} style={{ width: 100, textAlign: "center" }} key={record.category_main_file_by}>{record.category_main_file_by}</Tag>
          sub_category_tag = <Tag color={'orange'} style={{ width: 100, textAlign: "center" }} key={record.category_sub_file_by}>{record.category_sub_file_by}</Tag>

          return <React.Fragment>
            {free_content_tag}<br />
            {main_category_tag}<br />
            {sub_category_tag}
          </React.Fragment>
        }
      },
    ];


  }


  async componentDidMount() {
    // console.log(this.props.app_state.current_user_id);

    try {
        API.get("videos", `/user-history/${this.props.app_state.current_user_id}`)
        .then(async response => {
          let dataSource = [];

          for (let i = 0; i < response.payload.length; i++) {
            dataSource.push({
              key: response.payload[i].id,
              id: response.payload[i].id,
              poster: await get_url(response.payload[i].poster_file_id),
              is_poster: !(response.payload[i].poster_file_id == "" || response.payload[i].poster_file_id == null),
              category_main_file_by: response.payload[i].category_main_file_by,
              category_sub_file_by: response.payload[i].category_sub_file_by,
              description: response.payload[i].description,
              long_description: response.payload[i].long_description,
              file_id: response.payload[i].file_id,
              poster_file_id: response.payload[i].poster_file_id,
              record_created: response.payload[i].record_created,
              public_date: response.payload[i].public_date,
              free_content: response.payload[i].free_content,
              record_date: response.payload[i].record_date,
              record_datetime: response.payload[i].record_datetime,
            });
          }

          // }
          if (this.props.show_only) {
            dataSource = dataSource.sort((a, b) => {
              return moment(b.public_date).unix() - moment(a.public_date).unix()
            }).slice(0, Number.parseInt(this.props.show_only))
          }
          this.setState({
            dataSource: dataSource
          })
        })
        .catch(error => {
          console.log(error);
        });
    } catch (e) {
      // alert(e);
      console.log(e)
    }





  }

  componentWillReceiveProps = async (nextProps) => {





  }




  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    // console.log(this.state);
    // console.log(this.props);

    // console.log(this.props.app_state.current_client_record.current_subscription_id)
    // let subscription_view;
    // if (this.props.app_state.current_client_record.current_subscription_id) {
    //   subscription_view = {

    //   }
    // }
    // let subscription_view = null;
    // if (this.props.app_state.current_client_record){
    //   console.log(this.props.app_state.current_client_record.status_id);
    //   if (this.props.app_state.current_client_record.status_id === 3) {
    //     subscription_view = 
    //       <React.Fragment>
    //         <Table dataSource={this.state.dataSource} columns={this.columns} pagination={{ pageSize: this.props.row_size }} />
    //       </React.Fragment>

    //     return subscription_view;
    //   }


    // }

    // return (
    //   <React.Fragment>
    //     <p> Please subscribe to view this content </p>
    //   </React.Fragment>
    // );

    if (!this.state && !this.state.dataSource) return null;
    // console.log(this.state.dataSource)
    return (

      <React.Fragment>
        <Table
          dataSource={this.state.dataSource}
          columns={this.columns}
          showHeader={false}
          pagination={{ pageSize: 5 }} />
      </React.Fragment>
    );
  }
}

// export default UserSummary;

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const VideoHistoryComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoHistoryComponent);

export default VideoHistoryComponentContainer;
