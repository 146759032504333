import React, { Component } from 'react';
import { connect } from 'react-redux';

import { API } from 'aws-amplify';
import {
  Card, Button, Row, Col, Input, Table, Tag, Modal
} from 'antd';

import Highlighter from 'react-highlight-words';
import { SearchOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { get_url } from '../../library/aws_library';

import {
  // createNewWorkoutBuilder,
  updateNewWorkoutBuilder,
  setWorkoutBuilderProcessing
} from '../../redux/actions/workout_builder';
import { mod } from 'mathjs';

import ExercisePreviewPageComponentContainer from '../workout_builder/exercise_view/ExercisePreviewPageComponent'

const { confirm } = Modal;
const moment = require('moment');

class ExerciseListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      file_1_exists: false,
      file_2_exists: false,
      file_3_exists: false,
      file_4_exists: false,
      file_5_exists: false,
      file_6_exists: false,
      add_exercise_id: '',
      add_exercise_record: {},
      exercise_poster_url: '',
      view_modal_open: false,
      preview_exercise_id: '',
      playing: false
    };
    this.columns = [
      {
        title: 'Preview exercise',
        dataIndex: 'poster',
        width: 180,
        responsive: ['md'],

        render: (text, record) => {
          // console.log(record)
          if (!record.is_poster) {
            return (
              <React.Fragment>
                <div style={{
                  textAlign: 'center',
                  fontSize: 20
                }}>
                  <QuestionCircleOutlined />
                </div>
              </React.Fragment>
            );
          }
          // console.log(record)
          return (
            
            <React.Fragment>
              <div>
                {/* <img src={text} style={{ height: this.props.size === "small" ? 90 : 180, maxWidth: this.props.size === "small" ? 160 : 320 }}></img> */}
                <img
                  src={text}
                  //window.open(`/exercise/view/${record.id}`, '_blank')
                  alt="..." onClick={async() => this.handle_view_modal_new(record.id)}
                  target="_blank"
                  style={{
                    height: 100,
                    maxWidth: 150
                  }}>
                </img>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        title: <div style={{textAlign: 'right'}}>Search for exercise --></div>,
        dataIndex: 'long_description',
        key: 'long_description',
        ...this.getColumnSearchProps('short_description'),
        // sorter: (a, b) =>
        //   moment(a.public_date).unix() - moment(b.public_date).unix(),
        // defaultSortOrder: "descend",
        render: (text, record) => {
          return {
            props: { style: { verticalAlign: 'top' }, },
            children: (
              <React.Fragment>
                <div style={{
                  height: '100%',
                  verticalAlign: 'top'
                }}>
                  <h2>{record.short_description}</h2>
                  <strong><em>{record.exercise_category_text}</em></strong>
                </div>
                <div>
                  <span style={{
                    fontSize: '1.1em',
                    fontWeight: 500
                  }}>
                    {text}
                  </span>
                </div>
              </React.Fragment>
            ),
          };
        },
      },
      // {
      //   title: "Categories",
      //   dataIndex: "free_content",
      //   responsive: ["lg"],
      //   render: (data_item, record) => {
      //     let free_content_tag;
      //     let main_category_tag;
      //     let sub_category_tag;
      //     switch (data_item) {
      //       case 1:
      //         free_content_tag = (
      //           <Tag
      //             color={"green"}
      //             style={{ width: 100, textAlign: "center" }}
      //             key={data_item}
      //           >
      //             FREE CONTENT
      //           </Tag>
      //         );
      //       default:
      //         free_content_tag = (
      //           <Tag
      //             color={"geekblue"}
      //             style={{ width: 100, textAlign: "center" }}
      //             key={data_item}
      //           >
      //             STANDARD
      //           </Tag>
      //         );
      //     }

      //     // main_category_tag = (
      //     //   <Tag
      //     //     color={"blue"}
      //     //     style={{ width: 100, textAlign: "center" }}
      //     //     key={record.category_main_file_by}
      //     //   >
      //     //     {record.category_main_file_by}
      //     //   </Tag>
      //     // );
      //     // sub_category_tag = (
      //     //   <Tag
      //     //     color={"orange"}
      //     //     style={{ width: 100, textAlign: "center" }}
      //     //     key={record.category_sub_file_by}
      //     //   >
      //     //     {record.category_sub_file_by}
      //     //   </Tag>
      //     // );

      //     return (
      //       <React.Fragment>
      //         {free_content_tag}
      //         {/* <br />
      //         {main_category_tag}
      //         <br />
      //         {sub_category_tag} */}
      //       </React.Fragment>
      //     );
      //   },
      // },
      {
        title: '',
        dataIndex: 'id',
        render: (text, record) => {
          return (
            <React.Fragment>
              <Row>
                <Col xs={12}>
                  {record.file_1_description &&
                    <Card>
                      <Button type="primary" onClick={() => this.setOption1(text)}>Add: {' '}{
                        record.file_1_length ? record.file_1_length + ' sec' : ''
                      }</Button>
                    </Card>
                  }
                </Col>
                <Col>
                  {record.file_2_description &&
                    <Card>
                      <Button type="primary" onClick={() => this.setOption2(text)}>Add: {' '}{
                        record.file_2_length ? record.file_2_length + ' sec' : ''
                      }</Button>
                    </Card>
                  }
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {record.file_3_description &&
                    <Card>
                      <Button type="primary" onClick={() => this.setOption3(text)}>Add: {' '}{
                        record.file_3_length ? record.file_3_length + ' sec' : ''
                      }</Button>
                    </Card>
                  }
                </Col>
                <Col>
                  {record.file_4_description &&
                    <Card>
                      <Button type="primary" onClick={() => this.setOption4(text)}>Add: {' '}{
                        record.file_4_length ? record.file_4_length + ' sec' : ''
                      }</Button>
                    </Card>
                  }
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {record.file_5_description &&
                    <Card>
                      <Button type="primary" onClick={() => this.setOption5(text)}>Add: {' '}{
                        record.file_5_length ? record.file_5_length + ' sec' : ''
                      }</Button>
                    </Card>
                  }
                </Col>
                <Col>
                  {record.file_6_description &&
                    <Card>
                      <Button type="primary" onClick={() => this.setOption6(text)}>Add: {' '}{
                        record.file_6_length ? record.file_6_length + ' sec' : ''
                      }</Button>
                    </Card>
                  }
                </Col>
              </Row>
            </React.Fragment>
          );
        },
      },
    ];
  }

  async componentDidMount() {
    if (this.props.workout_builder.current_category_id != '0') {
      API.get(
        'workout-builder',
        `/exercises/list/${this.props.workout_builder.current_category_id}`
      )
        .then(async (response) => {
          const dataSource = [];
          // console.log(response.payload)
          for (let i = 0; i < response.payload.length; i++) {
            dataSource.push({
              key: response.payload[i].id,
              id: response.payload[i].id,
              poster: await get_url(response.payload[i].poster_file_id),
              is_poster: !(
                response.payload[i].poster_file_id == '' ||
                response.payload[i].poster_file_id == null
              ),
              exercise_category_id: response.payload[i].exercise_category_id,
              exercise_category: response.payload[i].exercise_category,
              exercise_category_text: response.payload[i].exercise_category_text,
              equipment: response.payload[i].equipment,
              // file_by: response.payload[i].file_by,
              short_description: response.payload[i].short_description,
              long_description: response.payload[i].long_description,
              // free_content: response.payload[i].free_content,
              file_1_description: response.payload[i].file_1_description,
              file_1_file: response.payload[i].file_1_file,
              file_1_gender: response.payload[i].file_1_gender,
              file_1_length: response.payload[i].file_1_length,
              file_2_description: response.payload[i].file_2_description,
              file_2_file: response.payload[i].file_2_file,
              file_2_gender: response.payload[i].file_2_gender,
              file_2_length: response.payload[i].file_2_length,
              file_3_description: response.payload[i].file_3_description,
              file_3_file: response.payload[i].file_3_file,
              file_3_gender: response.payload[i].file_3_gender,
              file_3_length: response.payload[i].file_3_length,
              file_4_description: response.payload[i].file_4_description,
              file_4_file: response.payload[i].file_4_file,
              file_4_gender: response.payload[i].file_4_gender,
              file_4_length: response.payload[i].file_4_length,
              file_5_description: response.payload[i].file_5_description,
              file_5_file: response.payload[i].file_5_file,
              file_5_gender: response.payload[i].file_5_gender,
              file_5_length: response.payload[i].file_5_length,
              file_6_description: response.payload[i].file_6_description,
              file_6_file: response.payload[i].file_6_file,
              file_6_gender: response.payload[i].file_6_gender,
              file_6_length: response.payload[i].file_6_length
            });
          }
          this.setState({ dataSource: dataSource });
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (this.props.workout_builder.current_category_id == 0) {
      API.get(
        'workout-builder',
        '/exercises/list-all'
      )
        .then(async (response) => {
          const dataSource = [];
          // console.log(response.payload)
          for (let i = 0; i < response.payload.length; i++) {
            dataSource.push({
              key: response.payload[i].id,
              id: response.payload[i].id,
              poster: await get_url(response.payload[i].poster_file_id),
              is_poster: !(
                response.payload[i].poster_file_id == '' ||
                response.payload[i].poster_file_id == null
              ),
              exercise_category_id: response.payload[i].exercise_category_id,
              exercise_category: response.payload[i].exercise_category,
              exercise_category_text: response.payload[i].exercise_category_text,
              equipment: response.payload[i].equipment,
              // file_by: response.payload[i].file_by,
              short_description: response.payload[i].short_description,
              long_description: response.payload[i].long_description,
              // free_content: response.payload[i].free_content,
              file_1_description: response.payload[i].file_1_description,
              file_1_file: response.payload[i].file_1_file,
              file_1_gender: response.payload[i].file_1_gender,
              file_1_length: response.payload[i].file_1_length,
              file_2_description: response.payload[i].file_2_description,
              file_2_file: response.payload[i].file_2_file,
              file_2_gender: response.payload[i].file_2_gender,
              file_2_length: response.payload[i].file_2_length,
              file_3_description: response.payload[i].file_3_description,
              file_3_file: response.payload[i].file_3_file,
              file_3_gender: response.payload[i].file_3_gender,
              file_3_length: response.payload[i].file_3_length,
              file_4_description: response.payload[i].file_4_description,
              file_4_file: response.payload[i].file_4_file,
              file_4_gender: response.payload[i].file_4_gender,
              file_4_length: response.payload[i].file_4_length,
              file_5_description: response.payload[i].file_5_description,
              file_5_file: response.payload[i].file_5_file,
              file_5_gender: response.payload[i].file_5_gender,
              file_5_length: response.payload[i].file_5_length,
              file_6_description: response.payload[i].file_6_description,
              file_6_file: response.payload[i].file_6_file,
              file_6_gender: response.payload[i].file_6_gender,
              file_6_length: response.payload[i].file_6_length
            });
          }
          this.setState({ dataSource: dataSource });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  componentWillReceiveProps = async (nextProps) => {
    if (
      this.props.workout_builder.current_category_id !==
      nextProps.workout_builder.current_category_id
    ) {
      // console.log(nextProps.category_id)
      this.setState({ dataSource: [] });
      if (nextProps.workout_builder.current_category_id != 0) {
        API.get(
          'workout-builder',
          `/exercises/list/${nextProps.workout_builder.current_category_id}`
        )
          .then(async (response) => {
            // console.log(response.payload)
            let dataSource = [];
            for (let i = 0; i < response.payload.length; i++) {
              dataSource.push({
                key: response.payload[i].id,
                id: response.payload[i].id,
                poster: await get_url(response.payload[i].poster_file_id),
                is_poster: !(
                  response.payload[i].poster_file_id == '' ||
                  response.payload[i].poster_file_id == null
                ),
                exercise_category_id: response.payload[i].exercise_category_id,
                exercise_category: response.payload[i].exercise_category,
                exercise_category_text: response.payload[i].exercise_category_text,
                equipment: response.payload[i].equipment,
                file_by: response.payload[i].file_by,
                short_description: response.payload[i].short_description,
                long_description: response.payload[i].long_description,
                // free_content: response.payload[i].free_content,
                file_1_description: response.payload[i].file_1_description,
                file_1_file: response.payload[i].file_1_file,
                file_1_gender: response.payload[i].file_1_gender,
                file_1_length: response.payload[i].file_1_length,
                file_2_description: response.payload[i].file_2_description,
                file_2_file: response.payload[i].file_2_file,
                file_2_gender: response.payload[i].file_2_gender,
                file_2_length: response.payload[i].file_2_length,
                file_3_description: response.payload[i].file_3_description,
                file_3_file: response.payload[i].file_3_file,
                file_3_gender: response.payload[i].file_3_gender,
                file_3_length: response.payload[i].file_3_length,
                file_4_description: response.payload[i].file_4_description,
                file_4_file: response.payload[i].file_4_file,
                file_4_gender: response.payload[i].file_4_gender,
                file_4_length: response.payload[i].file_4_length,
                file_5_description: response.payload[i].file_5_description,
                file_5_file: response.payload[i].file_5_file,
                file_5_gender: response.payload[i].file_5_gender,
                file_5_length: response.payload[i].file_5_length,
                file_6_description: response.payload[i].file_6_description,
                file_6_file: response.payload[i].file_6_file,
                file_6_gender: response.payload[i].file_6_gender,
                file_6_length: response.payload[i].file_6_length
              });
            }
            if (this.props.show_only) {
              dataSource = dataSource
                .sort((a, b) => {
                  return (
                    moment(b.public_date).unix() - moment(a.public_date).unix()
                  );
                })
                .slice(0, Number.parseInt(nextProps.show_only));
            }
            this.setState({ dataSource: dataSource });
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (nextProps.workout_builder.current_category_id == 0) {
        API.get(
          'workout-builder',
          '/exercises/list-all'
        )
          .then(async (response) => {
            // console.log(response.payload)

            const tmp = response.payload;

            //remove videos with duplicate ids
            for (let i = 0; i < tmp.length - 1; i++ ){
              try {
                if (response.payload[i].id == response.payload[i + 1].id){
                  tmp.splice(i + 1, 1);
                  i = i - 1
                }
              } catch (e){
                console.log(e)
              }
            }
            // console.log(tmp);

            let dataSource = [];
            for (let i = 0; i < response.payload.length; i++) {
              dataSource.push({
                key: response.payload[i].id,
                id: response.payload[i].id,
                poster: await get_url(response.payload[i].poster_file_id),
                is_poster: !(
                  response.payload[i].poster_file_id == '' ||
                  response.payload[i].poster_file_id == null
                ),
                exercise_category_id: response.payload[i].exercise_category_id,
                exercise_category: response.payload[i].exercise_category,
                exercise_category_text: response.payload[i].exercise_category_text,
                equipment: response.payload[i].equipment,
                // file_by: response.payload[i].file_by,
                short_description: response.payload[i].short_description,
                long_description: response.payload[i].long_description,
                // free_content: response.payload[i].free_content,
                file_1_description: response.payload[i].file_1_description,
                file_1_file: response.payload[i].file_1_file,
                file_1_gender: response.payload[i].file_1_gender,
                file_1_length: response.payload[i].file_1_length,
                file_2_description: response.payload[i].file_2_description,
                file_2_file: response.payload[i].file_2_file,
                file_2_gender: response.payload[i].file_2_gender,
                file_2_length: response.payload[i].file_2_length,
                file_3_description: response.payload[i].file_3_description,
                file_3_file: response.payload[i].file_3_file,
                file_3_gender: response.payload[i].file_3_gender,
                file_3_length: response.payload[i].file_3_length,
                file_4_description: response.payload[i].file_4_description,
                file_4_file: response.payload[i].file_4_file,
                file_4_gender: response.payload[i].file_4_gender,
                file_4_length: response.payload[i].file_4_length,
                file_5_description: response.payload[i].file_5_description,
                file_5_file: response.payload[i].file_5_file,
                file_5_gender: response.payload[i].file_5_gender,
                file_5_length: response.payload[i].file_5_length,
                file_6_description: response.payload[i].file_6_description,
                file_6_file: response.payload[i].file_6_file,
                file_6_gender: response.payload[i].file_6_gender,
                file_6_length: response.payload[i].file_6_length
              });
            }
            if (this.props.show_only) {
              dataSource = dataSource
                .sort((a, b) => {
                  return (
                    moment(b.public_date).unix() - moment(a.public_date).unix()
                  );
                })
                .slice(0, Number.parseInt(nextProps.show_only));
            }
            this.setState({ dataSource: dataSource });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  showAddItemModal = (id) => {
    // = Update into redux data =
    this.props.setWorkoutBuilderProcessing(true);
  };

  hideAddItemModal = () => {
    this.setState({
      show_modal_to_add_exercise: false,
      file_1_exists: false,
      file_2_exists: false,
      file_3_exists: false,
      file_4_exists: false,
      file_5_exists: false,
      file_6_exists: false,
      add_exercise_id: '',
      add_exercise_record: {},
      exercise_poster_url: ''
    })
    this.props.setWorkoutBuilderProcessing(false);
  }

  setOption1 = (record_id) => {

    API.get('workout-builder', `/exercises/id/${record_id}`)
      .then(async (response) => {

        // console.log(response.payload)

        const exercise_poster_url = await get_url(response.payload.poster_file_id);

        // First check the API response to see which "options" are available for this exercise
        const file_1_exists = response.payload.file_1_description;
        const file_2_exists = response.payload.file_2_description;
        const file_3_exists = response.payload.file_3_description;
        const file_4_exists = response.payload.file_4_description;
        const file_5_exists = response.payload.file_5_description;
        const file_6_exists = response.payload.file_6_description;

        const file_1_gender = response.payload.file_1_gender;
        const file_2_gender = response.payload.file_2_gender;
        const file_3_gender = response.payload.file_3_gender;
        const file_4_gender = response.payload.file_4_gender;
        const file_5_gender = response.payload.file_5_gender;
        const file_6_gender = response.payload.file_6_gender;

        const file_1_length = response.payload.file_1_length;
        const file_2_length = response.payload.file_2_length;
        const file_3_length = response.payload.file_3_length;
        const file_4_length = response.payload.file_4_length;
        const file_5_length = response.payload.file_5_length;
        const file_6_length = response.payload.file_6_length;

        // Put those values into state so that you can use them in the modal
        this.setState({
          file_1_exists,
          file_2_exists,
          file_3_exists,
          file_4_exists,
          file_5_exists,
          file_6_exists,
          file_1_gender,
          file_2_gender,
          file_3_gender,
          file_4_gender,
          file_5_gender,
          file_6_gender,
          file_1_length,
          file_2_length,
          file_3_length,
          file_4_length,
          file_5_length,
          file_6_length,
          add_exercise_id: record_id,
          add_exercise_record: response.payload,
          exercise_poster_url
        })

        // Then pop up a modal
        // this.setState({show_modal_to_add_exercise: true})
        this.setState({
          add_exercise_option: 1,
          add_exercise_option_length: file_1_length,
          add_exercise_option_gender: file_1_gender,
        }, () => this.addExerciseIntoWorkoutList())
        // console.log(exercise_list_data);

        this.props.setWorkoutBuilderProcessing(false);
      }).catch(err => {
        console.log(err);
        this.props.setWorkoutBuilderProcessing(false);
      });
  }

  setOption2 = (record_id) => {
    API.get('workout-builder', `/exercises/id/${record_id}`)
      .then(async (response) => {

        // console.log(response.payload)

        const exercise_poster_url = await get_url(response.payload.poster_file_id);

        // First check the API response to see which "options" are available for this exercise
        const file_1_exists = response.payload.file_1_description;
        const file_2_exists = response.payload.file_2_description;
        const file_3_exists = response.payload.file_3_description;
        const file_4_exists = response.payload.file_4_description;
        const file_5_exists = response.payload.file_5_description;
        const file_6_exists = response.payload.file_6_description;

        const file_1_gender = response.payload.file_1_gender;
        const file_2_gender = response.payload.file_2_gender;
        const file_3_gender = response.payload.file_3_gender;
        const file_4_gender = response.payload.file_4_gender;
        const file_5_gender = response.payload.file_5_gender;
        const file_6_gender = response.payload.file_6_gender;

        const file_1_length = response.payload.file_1_length;
        const file_2_length = response.payload.file_2_length;
        const file_3_length = response.payload.file_3_length;
        const file_4_length = response.payload.file_4_length;
        const file_5_length = response.payload.file_5_length;
        const file_6_length = response.payload.file_6_length;

        // Put those values into state so that you can use them in the modal
        this.setState({
          file_1_exists,
          file_2_exists,
          file_3_exists,
          file_4_exists,
          file_5_exists,
          file_6_exists,
          file_1_gender,
          file_2_gender,
          file_3_gender,
          file_4_gender,
          file_5_gender,
          file_6_gender,
          file_1_length,
          file_2_length,
          file_3_length,
          file_4_length,
          file_5_length,
          file_6_length,
          add_exercise_id: record_id,
          add_exercise_record: response.payload,
          exercise_poster_url
        })

        // Then pop up a modal
        // this.setState({show_modal_to_add_exercise: true})
        this.setState({
          add_exercise_option: 2,
          add_exercise_option_length: file_2_length,
          add_exercise_option_gender: file_2_gender,
        }, () => this.addExerciseIntoWorkoutList())
        // console.log(exercise_list_data);

        this.props.setWorkoutBuilderProcessing(false);
      }).catch(err => {
        console.log(err);
        this.props.setWorkoutBuilderProcessing(false);
      });
  }

  setOption3 = (record_id) => {
    API.get('workout-builder', `/exercises/id/${record_id}`)
      .then(async (response) => {

        // console.log(response.payload)

        const exercise_poster_url = await get_url(response.payload.poster_file_id);

        // First check the API response to see which "options" are available for this exercise
        const file_1_exists = response.payload.file_1_description;
        const file_2_exists = response.payload.file_2_description;
        const file_3_exists = response.payload.file_3_description;
        const file_4_exists = response.payload.file_4_description;
        const file_5_exists = response.payload.file_5_description;
        const file_6_exists = response.payload.file_6_description;

        const file_1_gender = response.payload.file_1_gender;
        const file_2_gender = response.payload.file_2_gender;
        const file_3_gender = response.payload.file_3_gender;
        const file_4_gender = response.payload.file_4_gender;
        const file_5_gender = response.payload.file_5_gender;
        const file_6_gender = response.payload.file_6_gender;

        const file_1_length = response.payload.file_1_length;
        const file_2_length = response.payload.file_2_length;
        const file_3_length = response.payload.file_3_length;
        const file_4_length = response.payload.file_4_length;
        const file_5_length = response.payload.file_5_length;
        const file_6_length = response.payload.file_6_length;

        // Put those values into state so that you can use them in the modal
        this.setState({
          file_1_exists,
          file_2_exists,
          file_3_exists,
          file_4_exists,
          file_5_exists,
          file_6_exists,
          file_1_gender,
          file_2_gender,
          file_3_gender,
          file_4_gender,
          file_5_gender,
          file_6_gender,
          file_1_length,
          file_2_length,
          file_3_length,
          file_4_length,
          file_5_length,
          file_6_length,
          add_exercise_id: record_id,
          add_exercise_record: response.payload,
          exercise_poster_url
        })

        // Then pop up a modal
        // this.setState({show_modal_to_add_exercise: true})
        this.setState({
          add_exercise_option: 3,
          add_exercise_option_length: file_3_length,
          add_exercise_option_gender: file_3_gender,
        }, () => this.addExerciseIntoWorkoutList())
        // console.log(exercise_list_data);

        this.props.setWorkoutBuilderProcessing(false);
      }).catch(err => {
        console.log(err);
        this.props.setWorkoutBuilderProcessing(false);
      });
  }
  setOption4 = (record_id) => {
    API.get('workout-builder', `/exercises/id/${record_id}`)
      .then(async (response) => {

        // console.log(response.payload)

        const exercise_poster_url = await get_url(response.payload.poster_file_id);

        // First check the API response to see which "options" are available for this exercise
        const file_1_exists = response.payload.file_1_description;
        const file_2_exists = response.payload.file_2_description;
        const file_3_exists = response.payload.file_3_description;
        const file_4_exists = response.payload.file_4_description;
        const file_5_exists = response.payload.file_5_description;
        const file_6_exists = response.payload.file_6_description;

        const file_1_gender = response.payload.file_1_gender;
        const file_2_gender = response.payload.file_2_gender;
        const file_3_gender = response.payload.file_3_gender;
        const file_4_gender = response.payload.file_4_gender;
        const file_5_gender = response.payload.file_5_gender;
        const file_6_gender = response.payload.file_6_gender;

        const file_1_length = response.payload.file_1_length;
        const file_2_length = response.payload.file_2_length;
        const file_3_length = response.payload.file_3_length;
        const file_4_length = response.payload.file_4_length;
        const file_5_length = response.payload.file_5_length;
        const file_6_length = response.payload.file_6_length;

        // Put those values into state so that you can use them in the modal
        this.setState({
          file_1_exists,
          file_2_exists,
          file_3_exists,
          file_4_exists,
          file_5_exists,
          file_6_exists,
          file_1_gender,
          file_2_gender,
          file_3_gender,
          file_4_gender,
          file_5_gender,
          file_6_gender,
          file_1_length,
          file_2_length,
          file_3_length,
          file_4_length,
          file_5_length,
          file_6_length,
          add_exercise_id: record_id,
          add_exercise_record: response.payload,
          exercise_poster_url
        })

        // Then pop up a modal
        // this.setState({show_modal_to_add_exercise: true})
        this.setState({
          add_exercise_option: 4,
          add_exercise_option_length: file_4_length,
          add_exercise_option_gender: file_4_gender,
        }, () => this.addExerciseIntoWorkoutList())
        // console.log(exercise_list_data);

        this.props.setWorkoutBuilderProcessing(false);
      }).catch(err => {
        console.log(err);
        this.props.setWorkoutBuilderProcessing(false);
      });
  }

  setOption5 = (record_id) => {
    API.get('workout-builder', `/exercises/id/${record_id}`)
      .then(async (response) => {

        // console.log(response.payload)

        const exercise_poster_url = await get_url(response.payload.poster_file_id);

        // First check the API response to see which "options" are available for this exercise
        const file_1_exists = response.payload.file_1_description;
        const file_2_exists = response.payload.file_2_description;
        const file_3_exists = response.payload.file_3_description;
        const file_4_exists = response.payload.file_4_description;
        const file_5_exists = response.payload.file_5_description;
        const file_6_exists = response.payload.file_6_description;

        const file_1_gender = response.payload.file_1_gender;
        const file_2_gender = response.payload.file_2_gender;
        const file_3_gender = response.payload.file_3_gender;
        const file_4_gender = response.payload.file_4_gender;
        const file_5_gender = response.payload.file_5_gender;
        const file_6_gender = response.payload.file_6_gender;

        const file_1_length = response.payload.file_1_length;
        const file_2_length = response.payload.file_2_length;
        const file_3_length = response.payload.file_3_length;
        const file_4_length = response.payload.file_4_length;
        const file_5_length = response.payload.file_5_length;
        const file_6_length = response.payload.file_6_length;

        // Put those values into state so that you can use them in the modal
        this.setState({
          file_1_exists,
          file_2_exists,
          file_3_exists,
          file_4_exists,
          file_5_exists,
          file_6_exists,
          file_1_gender,
          file_2_gender,
          file_3_gender,
          file_4_gender,
          file_5_gender,
          file_6_gender,
          file_1_length,
          file_2_length,
          file_3_length,
          file_4_length,
          file_5_length,
          file_6_length,
          add_exercise_id: record_id,
          add_exercise_record: response.payload,
          exercise_poster_url
        })

        // Then pop up a modal
        // this.setState({show_modal_to_add_exercise: true})
        this.setState({
          add_exercise_option: 5,
          add_exercise_option_length: file_5_length,
          add_exercise_option_gender: file_5_gender,
        }, () => this.addExerciseIntoWorkoutList())
        // console.log(exercise_list_data);

        this.props.setWorkoutBuilderProcessing(false);
      }).catch(err => {
        console.log(err);
        this.props.setWorkoutBuilderProcessing(false);
      });
  }

  setOption6 = (record_id) => {
    API.get('workout-builder', `/exercises/id/${record_id}`)
      .then(async (response) => {

        // console.log(response.payload)

        const exercise_poster_url = await get_url(response.payload.poster_file_id);

        // First check the API response to see which "options" are available for this exercise
        const file_1_exists = response.payload.file_1_description;
        const file_2_exists = response.payload.file_2_description;
        const file_3_exists = response.payload.file_3_description;
        const file_4_exists = response.payload.file_4_description;
        const file_5_exists = response.payload.file_5_description;
        const file_6_exists = response.payload.file_6_description;

        const file_1_gender = response.payload.file_1_gender;
        const file_2_gender = response.payload.file_2_gender;
        const file_3_gender = response.payload.file_3_gender;
        const file_4_gender = response.payload.file_4_gender;
        const file_5_gender = response.payload.file_5_gender;
        const file_6_gender = response.payload.file_6_gender;

        const file_1_length = response.payload.file_1_length;
        const file_2_length = response.payload.file_2_length;
        const file_3_length = response.payload.file_3_length;
        const file_4_length = response.payload.file_4_length;
        const file_5_length = response.payload.file_5_length;
        const file_6_length = response.payload.file_6_length;

        // Put those values into state so that you can use them in the modal
        this.setState({
          file_1_exists,
          file_2_exists,
          file_3_exists,
          file_4_exists,
          file_5_exists,
          file_6_exists,
          file_1_gender,
          file_2_gender,
          file_3_gender,
          file_4_gender,
          file_5_gender,
          file_6_gender,
          file_1_length,
          file_2_length,
          file_3_length,
          file_4_length,
          file_5_length,
          file_6_length,
          add_exercise_id: record_id,
          add_exercise_record: response.payload,
          exercise_poster_url
        })

        // Then pop up a modal
        // this.setState({show_modal_to_add_exercise: true})
        this.setState({
          add_exercise_option: 6,
          add_exercise_option_length: file_6_length,
          add_exercise_option_gender: file_6_gender,
        }, () => this.addExerciseIntoWorkoutList())
        // console.log(exercise_list_data);

        this.props.setWorkoutBuilderProcessing(false);
      }).catch(err => {
        console.log(err);
        this.props.setWorkoutBuilderProcessing(false);
      });
  }

  addExerciseIntoWorkoutList = () => {
    let exercise_list_data = [];
    exercise_list_data = this.props.workout_builder.new_workout_builder_data.workouts;

    for (let i = 0; i < exercise_list_data.length; i++) {
      exercise_list_data[i].key = i
    }

    // console.log(exercise_list_data);

    // console.log('run once')
    exercise_list_data.push({
      key: exercise_list_data.length,
      id: this.state.add_exercise_id,
      file_by: this.state.add_exercise_record.file_by,
      short_description: this.state.add_exercise_record.short_description,
      poster_file_id: this.state.add_exercise_record.poster_file_id,
      exercise_poster_url: this.state.exercise_poster_url,
      option: this.state.add_exercise_option,
      length: this.state.add_exercise_option_length,
      gender: this.state.add_exercise_option_gender
    });

    this.setState({ show_modal_to_add_exercise: false, })
    this.props.updateNewWorkoutBuilder('workouts', exercise_list_data);
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: 8
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handle_view_modal_cancel = e => {
    // console.log(e);
    this.setState({
      view_modal_open: false,
      playing: false,
      preview_exercise_id: ''
    });
  };

  handle_view_modal_new = (exercise_id) => {
    // console.log(exercise_id);
    this.setState({
      view_modal_open: true,
      preview_exercise_id: exercise_id,
      playing: true
    });
  };

  render() {
    return (
      <React.Fragment>
        <Table
          dataSource={this.state.dataSource}
          columns={this.columns}
          // showHeader={false}
          scroll={this.props.workout_builder.new_workout_builder_data.workouts.length > 4 ? {y: 1000} : {y: 500}}
          pagination={{
            pageSize:20 ,
            showSizeChanger: false ,
            pageSizeOptions:[20]
          }}
          showSizeChanger={false}
        />
        <Modal
          title={'Exercise Preview'}
          visible={this.state.view_modal_open}
          onOk={this.handle_view_modal_cancel}
          onCancel={this.handle_view_modal_cancel}
          width={1280}
          destroyOnClose
          footer={[
            <Button key="submit" type="primary" onClick={this.handle_view_modal_cancel}>
              Close Preview
            </Button>,
          ]}
        >
          <ExercisePreviewPageComponentContainer
            exercise_id={this.state.preview_exercise_id} playing={this.state.playing}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    language: state.language,
    workout_builder: state.workout_builder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateNewWorkoutBuilder: (key, value) => {
      dispatch(updateNewWorkoutBuilder(key, value));
    },
    setWorkoutBuilderProcessing: (value) => {
      dispatch(setWorkoutBuilderProcessing(value));
    },
  };
};

const ExerciseListComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExerciseListComponent);

export default ExerciseListComponentContainer;
