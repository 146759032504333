import {
  // UPDATE_CURRENT_BRAND,
  UPDATE_CURRENT_USER,
  DATA_COUNTRIES,
  // UPDATE_CURRENT_USER_ID,
  // UPDATE_CURRENT_USER_LOG_OUT, UPDATE_ECONOMIC_CALENDAR
} from "../actions/app_state";

const initialState = {
  // current_brand: null,
  current_user_id: null,
  // current_client_id: null,
  // current_client_aws_cognito_id: null,
  // show_economic_calendar: true
};

function app_state(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return Object.assign({}, state, {
        current_user_id: action.user_id,
        current_user_aws_cognito_id: action.aws_cognito_id,
        current_client_record: action.current_client_record,
      });

    case DATA_COUNTRIES:
      return Object.assign({}, state, {
        data_countries_list: action.countries,
      });

    default:
      return state;
  }
}

// const rootReducer = combineReducers({
//   app_state
// });

export default app_state;
