
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import withStyles from 'react-jss'

// import { API } from "aws-amplify";
import { Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Form, Divider } from 'antd';
import { Modal } from 'antd';
import { Input } from 'antd';
import { Card, Button } from 'antd';
import { message } from 'antd';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;


const styles = {
  root: {
    padding: 0,
    paddingTop: 24,
    background: '#fff',
    minHeight: 360,
    '@media (min-width: 800px)': {
      padding: 0,
      paddingTop: 24,
    },
    '@media (min-width: 1000px)': {
      padding: 0,
      paddingTop: 24,
    },
    '@media (min-width: 1200px)': {
      padding: 24,
    },
  },
  main_card: {
    width: "100%",
    '@media (min-width: 800px)': {
      width: "80%",
    },
    '@media (min-width: 1000px)': {
      width: "80%",
    },
    '@media (min-width: 1200px)': {
      width: "50%",
    },
  },
}


const warning = (text) => {
  message.warning(text, 10);
};
const info = (text) => {
  message.info(text, 10);
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};


class LoginPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      password_reset_ui: false,
      password_reset_in_process: false,
      reset_password_verify_code: "",
      reset_password_verify_code_help: "",
      reset_password_verify_code_status: "",
      reset_password_new_password: "",
      reset_password_new_password_help: "",
      reset_password_new_password_status: "",
      reset_password_confirm_password: "",
      reset_password_confirm_password_help: "",
      reset_password_confirm_password_status: "",
    }
  }

  componentDidMount() {
    // API.get("staff", `/list`)
    //   .then(response => {
    //     console.log(response);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }

  validate_form = () => {
    let valid = true;
    if (this.state.email.length < 3) {
      this.setState({
        email_status: "error"
      })
      valid = false;
    }
    if (this.state.password.length < 3) {
      this.setState({
        password_status: "error"
      })
      valid = false;
    }
    return valid;
  }

  handleChange = event => {
    // console.log(event);
    this.setState({
      [event.target.id]: event.target.value,
      [`${event.target.id}_status`]: ""
    });
  };

  handle_register = () => {
    this.props.history.push("/pages/register");
  }

  handle_forgot_password = () => {
    console.log("handle_forgot_password")
    Auth.forgotPassword(this.state.email.toLowerCase())
      .then((data) => {
        console.log(data)
        info(`Please check your email ${data.CodeDeliveryDetails.Destination} for a verification number`)
        this.setState({
          password_reset_ui: true
        })
      })
      .catch((e) => {
        console.log(e.message)
        if (e.message == "Username cannot be empty") {
          warning("You must enter an email address")
        }
      });

  }
  password_reset_validate_form = () => {
    let valid = true;
    if (this.state.reset_password_verify_code.length == 6) {
      this.setState({
        reset_password_verify_code_help: null,
        reset_password_verify_code_status: "success"
      })
    } else {
      valid = false;
      this.setState({
        reset_password_verify_code_help: "Verify Code must be 6 digits",
        reset_password_verify_code_status: "error"
      })
    }

    if (this.state.reset_password_new_password) {
      const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/;
      if (re.test(String(this.state.reset_password_new_password))) {
        this.setState({
          reset_password_new_password_help: null,
          reset_password_new_password_status: "success"
        })
      } else {
        valid = false;
        this.setState({
          reset_password_new_password_help: "8 characters long including uppercase, lowercase, a number and a symbol",
          reset_password_new_password_status: "error"
        })
      }
    } else {
      valid = false;
      this.setState({
        reset_password_new_password_help: "Please provide a password",
        reset_password_new_password_status: "error"
      })
    }

    if (this.state.reset_password_confirm_password && this.state.reset_password_new_password !== this.state.reset_password_confirm_password) {
      valid = false;
      this.setState({
        reset_password_confirm_password_help: "Must be the same password",
        reset_password_confirm_password_status: "error"
      })
    }
    return valid;
  }

  password_reset_handle_ok = () => {
    if (!this.password_reset_validate_form()) return;
    this.setState({
      password_reset_in_process: true
    })
    Auth.forgotPasswordSubmit(this.state.email.toLowerCase(), this.state.reset_password_verify_code, this.state.reset_password_new_password)
      .then((data) => {
        console.log(data)
        info(`Password has been successfully set.  You may now login using the new password.`)
      })
      .catch((err) => {
        console.log(err)
        warning(err.message)
      });
    this.setState({
      password: "",
      password_reset_ui: false,
      password_reset_in_process: false,
      reset_password_verify_code: "",
      reset_password_verify_code_help: "",
      reset_password_verify_code_status: "",
      reset_password_new_password: "",
      reset_password_new_password_help: "",
      reset_password_new_password_status: "",
      reset_password_confirm_password: "",
      reset_password_confirm_password_help: "",
      reset_password_confirm_password_status: "",
    })
  }

  password_reset_handle_cancel = () => {

    this.setState({
      password_reset_ui: false
    })
  }


  handleSubmit = async event => {
    // console.log(event);
    if (!this.validate_form()) return;
    try {
      let auth = await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
      // console.log(auth);
      this.props.userHasAuthenticated(true);

    } catch (e) {
      // alert(e.message);
      console.log(e);

      if (e.code == "UserNotConfirmedException") {
        warning("User is not confirmed.  Please check your email (and spam) for message containing activation link.")
        Auth.resendSignUp(this.state.email.toLowerCase())
      }
      if (e.code == "NotAuthorizedException") {
        warning("Incorrect username or password")
        this.setState({
          password: "",
        });
      }
    }
  }

  render() {
    // console.log(this.state);
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.root}>

          <Card
            className={classes.main_card}
            actions={[
              <div
                style={{
                  float: "right",
                  marginRight: 24,
                }}>
                <Button
                  // type="primary"
                  onClick={this.handle_register}
                >Register</Button>
                &nbsp;
                &nbsp;
                <Button
                  type="primary"
                  onClick={this.handleSubmit}
                >Log In</Button>
              </div>,
            ]}
          >
            <Form
              {...formItemLayout}
            >

              <Form.Item
                label="Email Address"
                hasFeedback
                help={this.state.email_status === "error" ? "Email address is required" : ""}
                validateStatus={this.state.email_status}
              >
                <Input
                  placeholder="Username"
                  id="email"
                  value={this.state.email}
                  onChange={(event) => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Password"
                hasFeedback
                help={this.state.password_status === "error" ? "Password is required" : ""}
                validateStatus={this.state.password_status}
              >
                <Input.Password
                  placeholder="Password"
                  id="password"
                  value={this.state.password}
                  onChange={(event) => {
                    this.handleChange(event)
                  }}
                />
                <a onClick={this.handle_forgot_password}>Forgot Password</a>
              </Form.Item>
            </Form>

          </Card>


        </div>

        <Modal
          visible={this.state.password_reset_ui}
          title="Password Reset"
          onOk={this.password_reset_handle_ok}
          onCancel={this.password_reset_handle_cancel}
          footer={[
            <Button key="back" onClick={this.password_reset_handle_cancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={this.state.password_reset_in_process} onClick={this.password_reset_handle_ok}>
              Submit
            </Button>,
          ]}
        >
          <p>
            Please check your email (including spam folder) for a verification code.
            <br />
            Enter it below, with your chosen new password.
            <br/>
            <br/>
            <strong>New password must be 8 characters including uppercase, lowercase one number and one symbol.</strong>
          </p>
          <Divider/>
          <Form
            {...formItemLayout}
          >
            <Form.Item
              label="Verification Code"
              hasFeedback
              help={this.state.reset_password_verify_code_help}
              validateStatus={this.state.reset_password_verify_code_status}
            >
              <Input
                placeholder="Verification Code"
                id="reset_password_verify_code"
                value={this.state.reset_password_verify_code}
                onChange={(event) => {
                  this.handleChange(event)
                }}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              hasFeedback
              help={this.state.reset_password_new_password_help}
              validateStatus={this.state.reset_password_new_password_status}
            >
              <Input.Password
                placeholder="Password"
                id="reset_password_new_password"
                value={this.state.reset_password_new_password}
                onChange={(event) => {
                  this.handleChange(event)
                }}
              />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              hasFeedback
              help={this.state.reset_password_confirm_password_help}
              validateStatus={this.state.reset_password_confirm_password_status}
            >
              <Input.Password
                placeholder="Password"
                id="reset_password_confirm_password"
                value={this.state.reset_password_confirm_password}
                onChange={(event) => {
                  this.handleChange(event)
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment >
    );
  }
}

export default withStyles(styles)(withRouter(LoginPageComponent));
