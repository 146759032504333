import React, { Component } from 'react';
import withStyles from 'react-jss'
// import { FormattedMessage } from 'react-intl';

// import { API, Auth } from "aws-amplify";

// import { Row, Col } from 'antd';
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Card } from 'antd';
// import { PageHeader, Button } from 'antd';
// import DailyHiitsViewPageComponentContainer from '../../../components/pages/dashboard/daily_hiits/DailyHiitsViewPageComponent';
import VideoViewPageComponentContainer from '../../../components/video/VideoViewPageComponent';
// import WorkoutsViewComponentContainer from '../../../components/pages/dashboard/workouts/WorkoutsView';
// import { Typography } from 'antd';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;
// const { Title } = Typography;


const styles = {
  root: {
    padding: 0,
    // paddingTop: 24, 
    background: '#fff',
    minHeight: 360,
    // '@media (min-width: 800px)': {
    //   padding: 0,
    //   paddingTop: 24, 
    // },
    // '@media (min-width: 1000px)': {
    //   padding: 0,
    //   paddingTop: 24, 
    // },
    // '@media (min-width: 1200px)': {
    //   padding: 24,
    // },
  },
  main_card: {
    width: "100%",
    padding: 0,
    // '@media (min-width: 800px)': {
    //   width: "80%",
    // },
    // '@media (min-width: 1000px)': {
    //   width: "80%",
    // },
    // '@media (min-width: 1200px)': {
    //   width: "50%",
    // },
  },
}

class VideoViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  handle_show_all = () => {
    this.props.history.push(`/workouts/list`);
  }

  render() {
    const { classes } = this.props;
    // console.log(this.props.match.params.file_id)
    // let tempString = String(this.props.match.params.file_id);
    // tempString = tempString.split('&');
    // console.log(tempString[0]);
    // console.log(tempString[1]);

    return (

      <div className={classes.root}>
        {/* <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="View Workout"
        /> */}
        <Card 
          className={classes.main_card}
          bodyStyle={{padding: 0}}
          // style={{ padding: 0 }}
          // title=""
          // extra={[
          //   <Button key="1" onClick={this.handle_show_all}>
          //     Show All
          // </Button>,
          // ]}
        >
          <div style={{padding: 0}}>
            <VideoViewPageComponentContainer
              file_id={this.props.match.params.file_id}
            />
          </div>
        </Card>
      </div>


    );
  }
}

export default withStyles(styles)(VideoViewPage);
