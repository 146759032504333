
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withStyles from 'react-jss'

// import { API, Auth } from "aws-amplify";
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Card, Button } from 'antd';
import { Tabs } from 'antd';
// import { Form, Input } from 'antd';
import UserSummary from './UserSummary';
import UserContact from './UserContact';
import UserAddress from './UserAddress';
import Subscription from './Subscription';

const { TabPane } = Tabs;

const queryString = require('query-string');
const styles = {
  tab_pane: {
    padding: 0,
    // paddingTop: 24, 
    // background: '#fff',
    // minHeight: 360,
    '@media (min-width: 800px)': {
      padding: 24,
      // paddingTop: 24, 
    },
    // '@media (min-width: 1000px)': {
    //   padding: 0,
    //   paddingTop: 24, 
    // },
    // '@media (min-width: 1200px)': {
    //   padding: 24,
    // },
  },
}


class MyAccountPageComponent extends Component {
  constructor(props) {
    super(props);
    const parsed = queryString.parse(this.props.location.search);
    this.state = {
      active_tab: parsed.tab || "summary"
    }
  }

  componentDidMount() {
    // API.get("clients", `/list`)
    //   .then(response => {
    //     console.log(response);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Tabs defaultActiveKey={this.state.active_tab} >
          <TabPane tab="User Summary" key="summary">
            <div className={classes.tab_pane}>
              <UserSummary />
            </div>
          </TabPane>
          <TabPane tab="Contact" key="contact">
            <div className={classes.tab_pane}>
              <UserContact />
            </div>
          </TabPane>
          <TabPane tab="Address" key="address">
            <div className={classes.tab_pane}>
              <UserAddress />
            </div>
          </TabPane>
          {/* {!this.props.app_state.current_client_record.vip && */}
            <TabPane tab="Subscription" key="subscription">
              <div className={classes.tab_pane}>
                <Subscription />
              </div>
            </TabPane>
          {/* } */}
        </Tabs>
      </div>
    );
  }
}

// export default withRouter(MyAccountPageComponent);
const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
}

const MyAccountPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccountPageComponent);

export default withStyles(styles)(withRouter(MyAccountPageComponentContainer));
