
import React, { Component } from 'react';
import { connect } from "react-redux";

import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";
// import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Modal } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form } from 'antd';
import { Typography } from 'antd';

const { Title } = Typography;
var moment = require("moment");

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class Subscription_View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscription: {
        id: "",
        record_created: "",
        product_id: ""
      },
      cancel_dialog_visible: false,
      stripe_subscription: {},
      plan: {
        id: "",
        nickname: "",
        retail_price: ""
      }
    };
    //this.setState();
  }

  async componentDidMount() {
    // const user = await this.getUserProfile(
    //   this.props.app_state.current_user_aws_cognito_id
    // );
    // console.log(user)
    // this.setState({
    //   ...user.payload
    // })
    await API.get("subscription", `/get/id/${this.props.app_state.current_client_record.current_subscription_id}`)
      .then(async (response) => {
        console.log(response)
        this.setState({
          subscription: response.payload.subscription,
          stripe_subscription: response.payload.stripe_result
        })
        await API.get("plans", `/get/id/${response.payload.subscription.plan_id}`)
          .then((response) => {
            console.log(response)
            this.setState({
              plan: response.payload,
              isLoading: true
            })
          }).catch(err => {
            //Workaround API error
            // API.get("products", `/get/id/${response.payload.product_id}`)
            //   .then((response) => {
            //     console.log(response)
            //     this.setState({
            //       product: response.payload,
            //       isLoading: true
            //     })
            //   })
            // //End of Workaround API error
            console.log(err);
          });
      }).catch(err => {
        console.log(err);
        // //Workaround API error
        // API.get("subscription", `/get/id/${this.props.app_state.current_client_record.current_subscription_id}`)
        //   .then((response) => {
        //     console.log(response)
        //     this.setState({
        //       subscription: response.payload
        //     })
        //     API.get("products", `/get/id/${response.payload.product_id}`)
        //       .then((response) => {
        //         console.log(response)
        //         this.setState({
        //           product: response.payload,
        //           isLoading: true
        //         })
        //       })
        //     //End of Workaround API error
        //   }).catch(err => {
        //     console.log(err);
        //   });
        // //End of Workaround API error

        // API.get("products", `/list`)
        //   .then((response) => {
        //     // console.log(response)
        //     this.setState({
        //       product_list: response.payload
        //     })
        //   }).catch(err => {
        //     console.log(err);
        //   });
        console.log(err);
      });
    this.setState({
      isLoading: false
    })
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // // console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   // const user = await this.getUserProfile(
    //   //   nextProps.app_state.current_user_aws_cognito_id
    //   // );
    //   // console.log(user)
    //   // this.setState({
    //   //   ...user.payload
    //   // })
    // }
    // this.setState({
    //   isLoading: false
    // })
  }


  // async getUserProfile(aws_cognito_id) {
  //   return API.get("users", `/get/aws/${aws_cognito_id}`);
  // }

  // async saveUserProfile(user) {
  //   await API.put("users", `/update/${this.state.aws_cognito_id}`, {
  //     body: user
  //   });
  //   openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  // }

  cancel_sub = async e => {
    // console.log(e);
    this.setState({
      cancel_dialog_visible: false,
    });
    // console.log(this.props.app_state.current_client_record)
    // alert("Are you sure you are going to cancel this subscription?");
    await API.put("subscription", `/update/${this.props.app_state.current_client_record.current_subscription_id}`, {
      body: {
        id: this.props.app_state.current_client_record.current_subscription_id,
        status_id: '2'
      }
    }).then(async response => {
      console.log(response);
      console.log(this.props.app_state.current_client_record.id)

      // #114 Send Email: Disable this part while testing
      await API.post("email", `/client/cancel-portal-subs`, {
        body: {
          id: this.props.app_state.current_client_record.id,
          // username: this.props.app_state.current_client_record.email,
        }
      }).then(response => {
        console.log(response);
        openNotificationWithIcon('success', "Subscription Cancellation", "Cancellation request has been sent.  We will be in touch to confirm with you shortly.")
      })
        .catch(error => {
          console.log(error);
        });


      // openNotificationWithIcon('success', "Subscription Cancellation", "Cancellation request has been sent.  We will be in touch to confirm with you shortly.")
    })
      .catch(error => {
        console.log(error);
      });

    
  }



  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  // handleSubmit = async event => {
  //   event.preventDefault();
  //   // console.log(event)
  //   this.setState({ isLoading: true });

  //   const {
  //     // aws_cognito_id,
  //     first_name,
  //     middle_name,
  //     last_name,
  //     // email,
  //   } = this.state;
  //   try {
  //     await this.saveUserProfile({
  //       // aws_cognito_id: aws_cognito_id,
  //       first_name: first_name,
  //       middle_name: middle_name,
  //       last_name: last_name,
  //     });
  //     // this.props.history.push("/");
  //     // this.props.reload_user(this.state.aws_cognito_id);
  //   } catch (e) {
  //     // alert(e);

  //       openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
  //       // this.setState({ isLoading: false });

  //     // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
  //     // this.setState({ isLoading: false });
  //   }
  // };

  showModal = () => {
    this.setState({
      cancel_dialog_visible: true,
    });
  };

  // handleOk = e => {
  //   console.log(e);
  //   this.setState({
  //     cancel_dialog_visible: false,
  //   });
  // };

  handleCancel = e => {
    console.log(e);
    this.setState({
      cancel_dialog_visible: false,
    });
  };

  render() {
    // console.log(this.state);
    // console.log(this.props);

    if (!!this.state.isLoading) {
      return null;
    } else {
      // if (!this.props.app_state || !this.props.app_state.data_countries_list) return null;

      let term = ""
      switch (this.state.plan.term) {
        case "1":
          term = "day";
          break;
        case "2":
          term = "week";
          break;
        case "3":
          term = "month";
          break;
        case "4":
          term = "year";
          break;
      }

      return (

        <React.Fragment>

          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            // size="small"
          >

            <Row gutter={[12, 0]}>
              <Col xs={12}>
                <Title level={4}> Plan details </Title>
                <Form.Item
                  label="Product"
                >
                  {this.state.plan.nickname || ""}
                </Form.Item>
                <Form.Item
                  label="Price"
                >
                  {this.state.plan.retail_price || ""} per {term || ""}
                </Form.Item>
                <Form.Item
                  label="Product Code"
                >
                  {this.state.plan.stripe_id || ""}
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Title level={4}> Subscription details </Title>
                {/* <Form layout="vertical"> */}
                <Form.Item
                  label="Subscription Code"
                >
                  {this.state.subscription.stripe_id || ""}
                </Form.Item>
                <Form.Item
                  label="Created"
                >
                  {moment.unix(this.state.stripe_subscription.created).format("LLL") == "Invalid date"
                   ? ""
                   : moment.unix(this.state.stripe_subscription.created).format("LLL")}
                </Form.Item>
                <Form.Item
                  label="Current Period"
                >
                  {moment.unix(this.state.stripe_subscription.current_period_start).format("Do MMM YYYY") == "Invalid date"
                  ? ""
                  : moment.unix(this.state.stripe_subscription.current_period_start).format("Do MMM YYYY")}
                &nbsp;
                {
                  moment.unix(this.state.stripe_subscription.current_period_start).format("Do MMM YYYY") == "Invalid date"
                  ? ""
                  : "to"
                }
                &nbsp;
                {moment.unix(this.state.stripe_subscription.current_period_end).format("Do MMM YYYY") == "Invalid date"
                ? ""
                : moment.unix(this.state.stripe_subscription.current_period_end).format("Do MMM YYYY")}
                </Form.Item>
                <Form.Item
                  label="Renews in"
                >
                  {this.state.stripe_subscription.cancel_at_period_end
                    ? "Your subscription will cancel at the end of the current period"
                    : moment().diff(moment.unix(this.state.stripe_subscription.current_period_end), 'days') * (-1) || ""
                  }
                  {moment().diff(moment.unix(this.state.stripe_subscription.current_period_end), 'days') * (-1)
                    ? " days"
                    : ""
                  }
                </Form.Item>

                {/* </Form> */}

              </Col>

            </Row>
            <Row gutter={[96, 24]} >
              <Col xs={24} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={this.showModal}
                  // onClick={this.cancel_sub}
                  disabled={this.state.stripe_subscription.cancel_at_period_end ? true : false}
                >
                  Cancel Subscription
              </Button>
              </Col>
            </Row>
          </Form>

          <Modal
            title="Cancel Subscription"
            visible={this.state.cancel_dialog_visible}
            onOk={this.cancel_sub}
            onCancel={this.handleCancel}
          >
            <p>Are you sure you are going to cancel this subscription?</p>
          </Modal>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const Subscription_ViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscription_View);

export default Subscription_ViewContainer;
