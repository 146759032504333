
import React, { Component } from 'react';
import { connect } from "react-redux";
// import { NavLink } from "react-router-dom";

// import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";
// import { Layout } from 'antd';
// import { Row, Col } from 'antd';
// import { Card } from 'antd';
// import { Button } from 'antd';
// import { notification } from 'antd';
// import { Form, Input } from 'antd';
// import { Table } from 'antd';
// import { Alert } from 'antd';

// import { Divider } from 'antd';
// import Highlighter from 'react-highlight-words';
// import { SearchOutlined } from '@ant-design/icons';

// import { get_url } from "../../../../library/aws_library";
// import { QuestionCircleOutlined } from "@ant-design/icons";
import VideoHistoryComponentContainer from '../../../video/VideoHistoryComponent';


class HistoryListPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //videoList:[]
    }

  }


  async componentDidMount() {

  }




  render() {
    // console.log(this.state);


    return (
      <React.Fragment>
        <VideoHistoryComponentContainer />
      </React.Fragment>
    );
  }
}

// export default UserSummary;

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
}

const HistoryListPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryListPageComponent);

export default HistoryListPageComponentContainer;
