import LoginPage from "../views/pages/LoginPage";
import RegisterPage from "../views/pages/RegisterPage";
// import LoginDirectPage from "../views/Pages/LoginDirectPage";
// import RegisterPage from "../views/Pages/RegisterPage";
// import MigratePage from "../views/Pages/MigratePage";

// @material-ui/icons
// import PersonAdd from "@material-ui/icons/PersonAdd";
// import Fingerprint from "@material-ui/icons/Fingerprint";
// import VerifyEmailPage from "../views/Pages/VerifyEmailPage";
// import IdentificationEditContainer from "../views/Dashboard/Identification/IdentificationEditContainer";
// import RegistrationPage from "../views/Pages/RegistrationPage";


const pagesRoutes = [
  // {
  //   path: "/pages/register-page",
  //   name: "Register Page",
  //   short: "Register",
  //   mini: "RP",
  //   invisible: true,
  //   icon: PersonAdd,
  //   //component: RegisterPage
  // },
  // // {
  // //   path: "/pages/registration",
  // //   name: "Register Page",
  // //   short: "Register",
  // //   mini: "RP",
  // //   invisible: true,
  // //   icon: PersonAdd,
  // //   component: RegistrationPage
  // // },
  // {
  //   path: "/pages/register/:referral_code",
  //   name: "Register Page",
  //   short: "Register",
  //   mini: "RP",
  //   invisible: true,
  //   icon: PersonAdd,
  //   //component: RegisterPage
  // },
  // {
  //   path: "/pages/register",
  //   name: "Register Page",
  //   short: "Register",
  //   mini: "RP",
  //   icon: PersonAdd,
  //   //component: RegisterPage
  // },
  // {
  //   path: "/pages/migrate/:referral_code",
  //   name: "Register Page",
  //   short: "Register",
  //   mini: "RP",
  //   invisible: true,
  //   icon: PersonAdd,
  //   //component: MigratePage
  // },
  // {
  //   path: "/pages/migrate/",
  //   name: "Register Page",
  //   short: "Register",
  //   mini: "RP",
  //   invisible: true,
  //   icon: PersonAdd,
  //   //component: MigratePage
  // },
  // {
  //   path: "/pages/verify-email",
  //   name: "Verify Email",
  //   short: "Verify",
  //   mini: "VE",
  //   invisible: true,
  //   icon: PersonAdd,
  //   component: VerifyEmailPage
  // },
  // {
  //   path: "/pages/login/:referral_code",
  //   name: "Login Page",
  //   short: "Login",
  //   mini: "LP",
  //   invisible: true,
  //   icon: Fingerprint,
  //   //component: LoginPageContainer
  // },
  {
    path: "/pages/login",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    // icon: Fingerprint,
    component: LoginPage
  },
  {
    path: "/pages/register",
    name: "Register Page",
    short: "Register",
    mini: "LP",
    // icon: Fingerprint,
    component: RegisterPage
  },
  // {
  //   path: "/pages/register",
  //   name: "Register Page",
  //   short: "Register",
  //   mini: "RP",
  //   // icon: Fingerprint,
  //   component: RegisterPage
  // },
  // {
  //   path: "/pages/login-page",
  //   name: "Login Page",
  //   short: "Login",
  //   mini: "LP",
  //   invisible: true,
  //   icon: Fingerprint,
  //   //component: LoginPageContainer
  // },
  // {
  //   path: "/pages/login-direct",
  //   name: "Login Page Direct",
  //   short: "Login",
  //   mini: "LP",
  //   invisible: true,
  //   icon: Fingerprint,
  //   //component: LoginDirectPage
  // },
  {
    redirect: true,
    path: "/pages",
    pathTo: "/pages/login",
    name: "Login Page"
  }
];

export default pagesRoutes;
