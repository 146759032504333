
import React, { Component } from 'react';
import { connect } from "react-redux";

// import { API, Auth } from "aws-amplify";
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Skeleton } from 'antd';
import { PageHeader, Card } from 'antd';
import MyAccountPageComponent from '../../components/pages/dashboard/my_account/MyAccountPageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class MyAccountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    // console.log(this.state);
    
    let main_component = <MyAccountPageComponent />;
    if (!this.props.app_state.current_client_record) main_component = <Skeleton active />;

    return (
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="My Account"
        />
        <Card
          style={{ minHeight: 360 }}
          title="View and update your Account Details"
        >
          <div style={{ paddingTop: 24 }}>
            {main_component}
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
}

const MyAccountPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccountPage);

export default MyAccountPageContainer;
