
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Row, Col } from 'antd';
import { Typography } from 'antd';
import { Divider } from 'antd';
import { Skeleton } from 'antd';
import { Form } from 'antd';
import { Button } from 'antd';


const { Title } = Typography;
var moment = require("moment");
// const queryString = require('query-string');


class MyAccountSubscriptionSuccessPageComponent extends Component {
  constructor(props) {
    super(props);
    // const parsed = queryString.parse(this.props.location.search);
    this.state = {
      loading: true,
      existing: false
    }
  }

  componentDidMount = async () => {
    API.get("subscription", `/process-success/${this.props.session_id}`)
      .then(async (response) => {
        console.log(response)
        if (response.payload.existing) {
          this.setState({
            existing: true,
            loading: false
          })
          return;
        };
        this.setState({
          // client: response.payload.client_result,
          product: response.payload.product_result,
          plan: response.payload.plan_result,
          stripe_subscription: response.payload.stripe_subscription,
          loading: false,
        })
        await API.post("email", `/interaction`, {
          body: {
            interaction: "Subscription Success",
            data: {
              client_id: this.props.app_state.current_user_id,
              client_nickname: this.props.app_state.current_client_record.nickname,
              client_email: this.props.app_state.current_client_record.email,
              ...response.payload.product_result,
              ...response.payload.plan_result,
              ...response.payload.stripe_subscription,
            },
            result: "Subscription Success"
          }
        })
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(err => {
        console.log(err);
        API.get("subscription", `/process-success/${this.props.session_id}`)
          .then(async (response) => {
            console.log(response)
            if (response.payload.existing) {
              this.setState({
                existing: true,
                loading: false
              })
              return;
            };
            this.setState({
              // client: response.payload.client_result,
              product: response.payload.product_result,
              plan: response.payload.plan_result,
              stripe_subscription: response.payload.stripe_subscription,
              loading: false,
            })
            await API.post("email", `/interaction`, {
              body: {
                interaction: "Subscription Success",
                data: {
                  client_id: this.props.app_state.current_user_id,
                  client_nickname: this.props.app_state.current_client_record.nickname,
                  client_email: this.props.app_state.current_client_record.email,
                  ...response.payload.product_result,
                  ...response.payload.plan_result,
                  ...response.payload.stripe_subscription,
                    },
                result: "Subscription Success"
              }
            })
              .then(response => {
                console.log(response);
              })
              .catch(error => {
                console.log(error);
              });
          })
      });
  }

  
  handleLogout = async () => {
    try {
      // await Auth.signOut();
      window.location.href = "https://portal.xolation.com";
    } catch (e) {
      alert(e.message);
      window.location.href = "https://portal.xolation.com";
    }
  };

  render() {
    // console.log(this.state);
    if (this.state.loading) {
      return <Skeleton active />;
    }

    if (this.state.existing) {
      return <p>
        You have successfully subscribed to XOlation.
      </p>
    }

    return (
      <React.Fragment>
        <Form>
          <Title level={4}>Subscription Success</Title>
          <p>
            You have successfully subscribed to XOlation.<br />
            Your plan details are listed below.
          </p>
          <Divider />
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item
                label="Product"
              >
                {this.state.product.nickname}
              </Form.Item>
              <Form.Item
                label="Plan"
              >
                {this.state.plan.nickname}
              </Form.Item>
              <Form.Item
                label="Price"
              >
                {this.state.plan.retail_price}
              </Form.Item>
              <Form.Item
                label="Subscription Created"
              >
                {moment.unix(this.state.stripe_subscription.created, "x").format("LLL")}
              </Form.Item>
              <Form.Item
                label="Current Period Starts"
              >
                {moment.unix(this.state.stripe_subscription.current_period_start, "x").format("LLL")}
              </Form.Item>
              <Form.Item
                label="Current Period Ends"
              >
                {moment.unix(this.state.stripe_subscription.current_period_end, "x").format("LLL")}
              </Form.Item>

              <p>
                Please press below to refresh this page and activate your subscription.
              </p>
              <Button key="1" type="primary" onClick={this.handleLogout}>
                Activate Me Now
              </Button>

            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

export default withRouter(MyAccountSubscriptionSuccessPageComponent);
