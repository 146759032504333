import React, {Component} from 'react';
import {connect} from 'react-redux';
import withStyles from 'react-jss';
import {API} from 'aws-amplify';

import {Layout, Menu, Icon} from 'antd';
import {NavLink} from 'react-router-dom';
import {DashboardOutlined, VideoCameraOutlined} from '@ant-design/icons';

// import { API, Auth } from "aws-amplify";
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Card } from 'antd';
// import { PageHeader } from 'antd';
import {Row, Col} from 'antd';
import {Skeleton} from 'antd';
import {Typography} from 'antd';
// import { Alert } from 'antd';
import {Card, Avatar} from 'antd';
import {get_url} from '../../../library/aws_library';
import VideoListPageComponent from '../../../components/video/VideoListComponent';

// const background_image = require("../../../assets/img/blonde_lady_with_towel.jpg");
const background_image_1 = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_426_blend_700x400.jpg');
const background_image_2 = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_124_blend_700x400.jpg');
const background_image_3 = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_067_blend_700x400.jpg');
// const category_image_067 = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_067_500x280.jpg');
// const category_image_124 = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_124_500x280.jpg');
// const category_image_426 = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_426_500x280.jpg');
const favourites_image = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_244_1.jpg');
const pink_xo = require('../../../assets/img/pink_xo.jpg');

const {Title} = Typography;
const {Meta} = Card;

const styles = {
  root: {
    backgroundImage: `url(${background_image_1})`,
    height: '100%',
    minHeight: 360,
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    backgroundColor: '#111',
    marginBottom: 40,
    '@media (max-width: 800px)': {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${background_image_1})`
    },
    '@media (max-width: 500px)': {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${background_image_1})`,
      height: '100vh',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      marginBottom: 0
    }
  },
  root_2: {
    backgroundImage: `url(${background_image_2})`,
    '@media (max-width: 800px)': {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${background_image_2})`
    },
    '@media (max-width: 500px)': {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${background_image_2})`
    }
  },
  root_3: {
    backgroundImage: `url(${background_image_3})`,
    '@media (max-width: 800px)': {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${background_image_3})`
    },
    '@media (max-width: 500px)': {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${background_image_3})`
    }
  },
  menu_text: {
    color: 'white',
    fontWeight: 400,
    fontSize: '1.0em',
    '@media (max-width: 500px)': {
      fontWeight: 800,
      fontSize: '1.2em'
    }
  },
  hero_text: {
    fontSize: '1.4em',
    fontWeight: 600,
    textAlign: 'justify',
    color: 'white',
    '@media (max-width: 500px)': {
      fontWeight: 600,
      fontSize: '1.2em'
    }
  }
};

class VideoMainCategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_main: {},
      sub: []
    };
  }

  componentDidMount = async () => {
    // console.log(this.props.match.params.main_slug)
    // console.log(this.props.match.params.sub_slug)
    await API.get('categories', `/main/slug/${this.props.match.params.main_slug}`).then(async response => {
      // console.log(response)
      this.setState({
        current_main: {...response.payload}
      });
      await API.get('categories', `/sub/list/${response.payload.id}`).then(async response => {
        // console.log(response)
        let processed_sub = [];
        for (let i = 0; i < response.payload.length; i++) {
          processed_sub.push({
            ...response.payload[i],
            small_poster_url: await get_url(
              response.payload[i].small_poster_file_id ||
                'small-poster-20200919-214131-1029b710-fa6d-11ea-bc4d-c1963b60fa5b'
            )
          });
        }
        this.setState({
          sub: processed_sub
        });
      });
    });
  };

  componentWillReceiveProps = async nextProps => {
    // console.log(this.props.match.params)
    // console.log(nextProps.match.params)
    if (this.props.match.params.main_slug !== nextProps.match.params.main_slug) {
      // console.log(nextProps.match.params.main_slug)
      this.setState({
        current_main: {
          file_by: null,
          description_long: null
        },
        sub: []
      });
      await API.get('categories', `/main/slug/${nextProps.match.params.main_slug}`).then(async response => {
        // console.log(response)
        this.setState({
          current_main: {...response.payload}
        });
        await API.get('categories', `/sub/list/${response.payload.id}`).then(async response => {
          // console.log(response)
          let processed_sub = [];
          for (let i = 0; i < response.payload.length; i++) {
            processed_sub.push({
              ...response.payload[i],
              small_poster_url: await get_url(
                response.payload[i].small_poster_file_id ||
                  'small-poster-20200919-214131-1029b710-fa6d-11ea-bc4d-c1963b60fa5b'
              )
            });
          }
          this.setState({
            sub: processed_sub
          });
        });
      });
    }
  };

  generate_top_menu = () => {
    const {classes} = this.props;
    if (this.state.sub.length < 1) return null;

    let default_poster = this.state.sub[0].small_poster_url;
    let menu_items = this.state.sub.map(item => {
      return (
        <Col xs={24} md={{span: 5}}>
          <Card
            key={item.id}
            // style={{ width: 300 }}
            style={{cursor: 'pointer'}}
            hoverable
            onClick={() =>
              this.props.history.push(`/video/category/${this.state.current_main.url_slug}/${item.url_slug}`)
            }
            cover={<img alt='example' src={item.small_poster_url} />}
            actions={
              [
                // <SettingOutlined key="setting" />,
                // <EditOutlined key="edit" />,
                // <Button type="primary" block onClick={() => this.props.history.push('/daily-hiits/list')}>View Videos</Button>,
              ]
            }
          >
            <Meta
              avatar={<Avatar shape='square' src={pink_xo} />}
              title={item.file_by}
              description={item.description_short}
            />
          </Card>
        </Col>
      );
    });

    return (
      <Row justify='center' gutter={[20, 16]}>
        {menu_items}

        {this.props.match.params.main_slug == 'daily-hiits' && (
          <Col xs={24} md={{span: 5}}>
            <Card
              key={'studio_favourites'}
              // style={{ width: 300 }}
              style={{cursor: 'pointer'}}
              hoverable
              onClick={() => this.props.history.push(`/video/studio-favourites`)}
              cover={<img alt='example' src={favourites_image} />}
              actions={
                [
                  // <SettingOutlined key="setting" />,
                  // <EditOutlined key="edit" />,
                  // <Button type="primary" block onClick={() => this.props.history.push('/daily-hiits/list')}>View Videos</Button>,
                ]
              }
            >
              <Meta
                avatar={<Avatar shape='square' src={pink_xo} />}
                title={'Studio Favourites'}
                description={'Studio Favourite Videos'}
              />
            </Card>
          </Col>
        )}
      </Row>
    );
  };

  // handle_show_all = () => {
  //   this.props.history.push(`/daily-hiits/list`);
  // }
  render() {
    const {classes} = this.props;
    // let main_component;
    // if (!this.props.app_state.current_user_id) {
    //   main_component = <VideoListPageComponent category_id={this.state.id} />;
    // } else {
    //   main_component = <Skeleton active />
    // }

    return (
      <React.Fragment>
        <div
          className={`${classes.root} ${this.state.current_main.id === 2 ? classes.root_2 : ''} ${
            this.state.current_main.id === 3 ? classes.root_3 : ''
          }`}
        >
          <Row>
            <Col lg={{span: 12}}>
              <div style={{margin: 40}}>
                <Title level={2}>{this.state.current_main.file_by || <Skeleton />}</Title>
                <p className={classes.hero_text}>{this.state.current_main.description_long || null}</p>
              </div>
            </Col>
          </Row>
        </div>
        <Row justify='space-around'>{this.generate_top_menu()}</Row>
        <div style={{padding: 24}}>
          <VideoListPageComponent category_id={this.state.current_main.id} />
        </div>
      </React.Fragment>
    );
  }
}

// export default DailyHiitsListPage;

const mapStateToProps = state => {
  return {
    app_state: state.app_state
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const VideoMainCategoryPageContainer = connect(mapStateToProps, mapDispatchToProps)(VideoMainCategoryPage);

export default withStyles(styles)(VideoMainCategoryPageContainer);
