import React, { Component } from 'react';
import {connect} from 'react-redux';
import { withRouter , NavLink } from 'react-router-dom';
import {
  Table, Row, Col, Form, Input, Button, Tag, Skeleton, Popconfirm
} from 'antd';

import { API } from 'aws-amplify';

import Highlighter from 'react-highlight-words';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import { mod } from 'mathjs';
import ReactPlayer from 'react-player';
import {get_url} from '../../library/aws_library';
import xo_64x64 from '../../assets/img/xo_64x64.jpeg';

const dayjs = require('dayjs');

const expanded_row_layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class WorkoutBuilderListPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {dataSource: [],};
    this.columns = [
      {
        title: 'Workout Name',
        dataIndex: 'file_by',
        render: (text, record) => (
          record.exercise_count != 0
            ?
            <span>
              <NavLink to={`/workout-builder/view/${record.id}`}>{text}</NavLink>
            </span>
            : ''
        ),
      },
      {
        title: 'Exercise Count',
        width: 150,
        dataIndex: 'exercise_count',
      },
      {
        title: 'Duration',
        width: 150,
        dataIndex: 'workout_duration',
        render: ( text ) => {
          return text ? <React.Fragment>{Math.floor(text / 60)}{':'}{(mod(text, 60) == 0) ? '00' : mod(text, 60)}</React.Fragment> : '';
        }
      },
      {
        title: 'Date Created',
        dataIndex: 'created_datetime',
        render: (text) => text ? dayjs(text).format('DD-MM-YYYY') : '',
      },
      {
        title: 'XO Workout',
        dataIndex: 'file_by',
        render: ( text, record ) => {
          if (!(record.client_id == this.props.app_state.current_client_record.id)) {
            return <img width='64 px' height='64 px' src={xo_64x64} />
          }
          return '';
        },
      },
      {
        title: 'Action',
        dataIndex: 'file_by',
        render: ( text, record ) => {
          console.log(record)
          return <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => this.removeItem(record.id)}
            onCancel={() => this.pop_cancel}
            disabled = {!(record.client_id == this.props.app_state.current_client_record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined/>
          </Popconfirm>;
        }
      },
    ];
    this.expandedColumns = [
      // {
      //   title: 'ID',
      //   dataIndex: 'id',
      //   width: 100,
      //   render: (text) => text
      // },
      {
        title: 'Exercise',
        dataIndex: 'short_description',
        width: 150,
        render: (text) => text
      },
      // {
      //   title: 'Exercise Name',
      //   dataIndex: 'poster_file_id',
      //   render: async (text, record) => {
      //     console.log(await get_url(text));
      //     const file_url = await get_url(text);
      //     return (
      //       <React.Fragment>
              
      //           <img src={file_url} style={{
      //             height: 100,
      //             width: 150
      //           }}></img>
              
      //       </React.Fragment>
      //     );
      //   }
      // },
      {
        title: 'Duration',
        width: 70,
        dataIndex: 'length', //exercise_duration
        render: ( text ) => {
          return text ? <React.Fragment>{Math.floor(text / 60)}{':'}{(mod(text, 60) == 0) ? '00' : mod(text, 60)}</React.Fragment> : '';
        }
      },
      //#188 Hide gender tags
      // {
      //   title: 'Gender',
      //   width: 100,
      //   dataIndex: 'gender',
      //   render: (text) => {
      //     let gender_tag;
      //     switch (text) {
      //       case 1:
      //         gender_tag = (
      //           <Tag
      //             color={'blue'}
      //             style={{
      //               width: 100,
      //               textAlign: 'center'
      //             }}
      //             key={text}
      //           >
      //                 Male
      //           </Tag>
      //         );
      //         break;
      //       case 2:
      //         gender_tag = (
      //           <Tag
      //             color={'magenta'}
      //             style={{
      //               width: 100,
      //               textAlign: 'center'
      //             }}
      //             key={text}
      //           >
      //                 Female
      //           </Tag>
      //         );
      //         break;
      //       default:
      //         gender_tag = ''
                  
      //     }

      //   return (
      //     <React.Fragment>
      //       {gender_tag}
      //     </React.Fragment>
      //   );
      // },
      // },
    ];
  }
  async componentDidMount() {
    console.log();
    if (Boolean(this.props.app_state.current_user_id)){
      await API.get('workout-builder', `/workouts/list/${this.props.app_state.current_user_id}`)
        .then((response) => {
          console.log(response);
          console.log(response.payload);

          const dataSource = [];
          for (let i = 0; i < response.payload.length; i++) {
            try {
              dataSource.push({
                key: response.payload[i].id,
                id: response.payload[i].id,
                client_id: response.payload[i].client_id,
                file_by: response.payload[i].file_by,
                exercise_details: response.payload[i].exercise_details,
                created_datetime: response.payload[i].created_datetime,
                exercise_count: response.payload[i].exercise_details
                  ? JSON.parse(
                    response.payload[i].exercise_details
                  ).length
                  : '',
                workout_duration: response.payload[i].exercise_details
                  ? JSON.parse(
                    response.payload[i].exercise_details
                  ).map( v => v['length'] ).reduce((sum, length) => Number(sum) + Number(length), 0)
                  : ''

                // short_description: response.payload[i].short_description,
                // long_description: response.payload[i].long_description,
                // free_content: response.payload[i].free_content,
                // exercise_category_id: response.payload[i].exercise_category_id,
                // category_nickname: response.payload[i].category_nickname,
                // equipment_required: response.payload[i].equipment
              });
            } catch (e){
              console.log(e)
            }
          }
          // console.log(dataSource);
          this.setState({dataSource: dataSource,});
        })
        .catch((error) => {
          console.log(error);
        });

      const video_url = await get_url('video-20210722-210308-65ce0f80-eadc-11eb-8939-0d9bc1835205');
      this.setState({video_url});

    }
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
      if (Boolean(this.props.app_state.current_user_id)){
        await API.get('workout-builder', `/workouts/list/${this.props.app_state.current_user_id}`)
          .then((response) => {
            console.log(response);
            console.log(response.payload);
  
            const dataSource = [];
            for (let i = 0; i < response.payload.length; i++) {

              let exercise_count = 0;
              let workout_duration = 0;
              try {

                exercise_count = response.payload[i].exercise_details
                  ? JSON.parse(
                    response.payload[i].exercise_details
                  ).length
                  : ''

                workout_duration = response.payload[i].exercise_details
                  ? JSON.parse(
                    response.payload[i].exercise_details
                  ).map( v => v['length'] ).reduce((sum, length) => Number(sum) + Number(length), 0)
                  : ''
              } catch (e){
                console.log(e)
              }
  
              dataSource.push({
                key: response.payload[i].id,
                id: response.payload[i].id,
                client_id: response.payload[i].client_id,
                file_by: response.payload[i].file_by,
                exercise_details: response.payload[i].exercise_details,
                created_datetime: response.payload[i].created_datetime,
                exercise_count,
                workout_duration

                // short_description: response.payload[i].short_description,
                // long_description: response.payload[i].long_description,
                // free_content: response.payload[i].free_content,
                // exercise_category_id: response.payload[i].exercise_category_id,
                // category_nickname: response.payload[i].category_nickname,
                // equipment_required: response.payload[i].equipment
              });
            }
            // console.log(dataSource);
            this.setState({dataSource: dataSource,});
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: 8
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  
  pop_cancel = (e) => {
    console.log(e);
    // console.log('Click on No');
  }

  removeItem = (key) => {
    // console.log('remove ' + key )

    let exercise_list_data = [];
    exercise_list_data = this.state.dataSource;

    function arrayRemove(arr, value) {

      return arr.filter(function (ele) {
        return ele.key != value;
      });
    }

    const result = arrayRemove(exercise_list_data, key);

    console.log(result)

    try {
      API.put('workout-builder',`/workouts/delete/${key}`)
    } catch (e) {
      console.log(e)
    }

    this.setState({dataSource: result})
  }

  render() {
    console.log(this.state);
    // console.log(this.state.dataSource);

    // var workoutStringify = JSON.stringify(this.state.dataSource.exercise_details);
    if (!this.props.app_state.current_user_id) {
      return <Skeleton active />;
    }
    console.log(this.props);
    const data = this.state.dataSource.slice(0, this.state.dataSource.length);

    return (
      <React.Fragment>
        <Row gutter={[24,0]}>
          <Col xs={16}>

            <Table
              columns={this.columns}
              dataSource={data}
              // showHeader={false}
              scroll={{ y: 500 }}
              pagination={false}
              expandedRowRender={(record, index) => {
                // console.log(record);
                // console.log(index);
                // console.log(this.state.dataSource);
                return (
                  <React.Fragment>
                    <Row>
                      <Col xs={12}>
                        <h4>Excerise List</h4>
                        <Table
                          columns={this.expandedColumns}
                          // dataSource={JSON.parse(
                          //   this.state.dataSource[`${record.id - 1}`]
                          //     .exercise_details
                          // )}
                          dataSource={JSON.parse(
                            this.state.dataSource[index]
                              .exercise_details
                          )}
                          // showHeader={false}
                          scroll={{ y: 300 }}
                          pagination={false}
                          // pagination={{
                          //   pageSizeOptions: ['10', '20', '30'],
                          //   showSizeChanger: true
                          // }}
                        />
                      
                        <Form
                          layout="vertical"
                          {...expanded_row_layout}
                        >
                          {/*<p>{JSON.stringify(this.state.dataSource[`${record.id-1}`].exercise_details)}</p>*/}
                          {/*<Form.Item label="Short Description" >

                      <TextArea
                        value={record.description_short}
                        disabled
                        autoSize={{ minRows: 2, maxRows: 8 }}
                      />
                    </Form.Item>
                    <Form.Item label="Long Description" >
                      <TextArea
                        value={record.description_long}
                        disabled
                        autoSize={{ minRows: 2, maxRows: 8 }}
                      />
                   </Form.Item>*/}
                        </Form>
                      </Col>
                    </Row>
                  </React.Fragment>
                );
              }}
            />
          </Col>
          
          <Col xs={8}>
            <div className='player-wrapper'>
              <ReactPlayer
                url='https://s3.ap-southeast-2.amazonaws.com/xolation.uploads.001/public/video-20210725-150745-3f5b0b10-ed06-11eb-81dc-43bd81d0e631'
                // playing
                style={{padding: 0}}
                // light={this.state.poster_url}
                width='100%'
                height='400px'
                controls
                // onStart={this.onVideoStart}
                // onProgress={this.onVideoProgress}
                // config={{
                //   file: {
                //     attributes: {
                //       controlsList: 'nodownload'
                //     }
                //   }
                // }}
              />
            </div>
            <p>
              How to Use:
            </p>
            <p>
              Go to the "Work Out Create" Tab. There you will find all the exercises split into categories. Find the exercises you like and add them to your workout.
            </p>
            <p>
              See the Instruction Video on how to use. Also you will find the format ideas from the pre created XO workouts just like we do in the classes listed in your workout list.
            </p>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
    workout_builder: state.workout_builder
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // updateNewWorkoutBuilder: (key, value) => {
    //   dispatch(updateNewWorkoutBuilder(key, value));
    // }
  };
};

const WorkoutBuilderListPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkoutBuilderListPageComponent);

export default withRouter(WorkoutBuilderListPageComponentContainer);
