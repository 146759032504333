
import React, { Component } from 'react';
import { connect } from "react-redux";
// import { NavLink } from "react-router-dom";

import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";
// import { Layout } from 'antd';
// import { Row, Col, Divider } from 'antd';
// import { Card } from 'antd';
// import { Button } from 'antd';
// import { notification } from 'antd';
// import { Form, Input } from 'antd';
// import { Table } from 'antd';

// import { Tag } from 'antd';
// import Highlighter from 'react-highlight-words';
// import { SearchOutlined } from '@ant-design/icons';

// import { get_url } from "../../library/aws_library";
// import { QuestionCircleOutlined } from "@ant-design/icons";

// import { Bar } from '@ant-design/charts';
import { StackedBar } from '@ant-design/charts';

var moment = require("moment");

// const openNotificationWithIcon = (type, header, message) => {
//   notification[type]({
//     message: header,
//     description: message,
//   });
// };



class LeaderboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }

  }


  async componentDidMount() {
    // API.get("videos", `/leaderboard/${this.props.app_state.current_user_id}`)
    //   .then(async response => {
    //     this.setState({
    //       data: response.payload.global_results,
    //     })
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })

      await API.get("videos", `/leaderboard-stacked/${this.props.app_state.current_user_id}`)
      .then(async response => {
        console.log(response)

        let dataSource = [];
        for (let i = 0 ; i < response.payload.stacked_results.length ; i ++){
          dataSource.push({
            // key: i,
            // id: i,
            client_name: 
              response.payload.stacked_results[i].client_name.split(' ')[0]+", "+response.payload.stacked_results[i].client_name.split(' ')[1].slice(0, 1) +".",
              category: (response.payload.stacked_results[i].category === 1)
              ? "Daily HIIT" : (response.payload.stacked_results[i].category === 2)
              ? "Quick HIIT": (response.payload.stacked_results[i].category === 3)
              ? "Wellness": (response.payload.stacked_results[i].category === 4)
              ? "Challenge": "Others",
            views: response.payload.stacked_results[i].views
          });
        }
        await dataSource.sort((a, b) => b.category.localeCompare(a.category))
        // console.log(dataSource)
        this.setState({
          data: dataSource //response.payload.stacked_results,
        })
      })
      .catch((err) => {
        console.log(err);
      })
  }


  render() {
    // console.log(this.state.data)


    const config = {
      forceFit: true,
      title: {
        visible: true,
        text: `30 Day Rolling Leaderboard (from ${moment().subtract(30, "days").format("DD-MM-YYYY")})`
      },
      description: {
        visible: true
      },
      data: this.state.data,
      yField: 'client_name',
      xField: 'views',
      //== Option 1: 41 Bright and Energetic https://visme.co/blog/color-combinations/ 
      // color: ['#c30f48','#F14D49','#F2746B'],
      //== Option 2: Blue / Purple / Orange (Will Break the Theme Colour but looks brighter)
      //color: ['#008B8B','#8B008B','#F14D49'],
      //== Option 3: Original Pink
      //color: ['#c30f48','#DB7093','#800000'],
      color: ['#C30F48','#0FC28A','#0F48C2','#0a4802'],
      label: {
        offset: 0,
        visible: true,
        position: 'middle',
        //formatter: (v) => Math.round(v / 1000) + 'thousands',
      },
      stackField: 'category',
    };
    return (
      <React.Fragment>
      {(this.state.data.length > 0) &&
        <StackedBar {...config} />
      }
      </React.Fragment>
    );
  };
  
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
}

const LeaderboardComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaderboardComponent);

export default LeaderboardComponentContainer;
