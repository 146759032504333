import React, { Component } from 'react';

import { connect } from "react-redux";
import { onLoginSuccess } from "./redux/actions/app_state";

import { IntlProvider } from "react-intl";
// import { addAppLocaleData } from "./localeData";
import flatten from 'flat'
import messages_en from "./translations/en.json";
import messages_zh from "./translations/zh.json";

import './App.css';
// import { DatePicker } from 'antd';
// import { Button } from 'antd';
import createHistory from 'history/createBrowserHistory'
import { Router, Switch } from "react-router-dom";
import AppliedRoute from "./components/routes/AppliedRoute";

import Amplify from "aws-amplify";
//import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import config from "./config/aws_config";

import indexRoutes from "./routes/index.jsx";
import ReactGA from 'react-ga';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito_staff.REGION,
    userPoolId: config.cognito_staff.USER_POOL_ID,
    identityPoolId: config.cognito_staff.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito_staff.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito_staff.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      // {
      //   name: "admin",
      //   endpoint: "https://xg61a85zpe.execute-api.us-east-1.amazonaws.com/dev",
      //   region: config.apiGateway.REGION
      // },
      {
        name: "sandbox",
        endpoint: "https://ezfrkewvsd.execute-api.us-east-1.amazonaws.com/dev/test_videos",
        region: config.apiGateway.REGION
      },
      {
        name: "categories",
        endpoint: "https://hxvm3whdg9.execute-api.us-east-1.amazonaws.com/dev/categories",
        region: config.apiGateway.REGION
      },
      {
        name: "countries",
        endpoint: "https://3rgwy8g7yh.execute-api.us-east-1.amazonaws.com/dev/countries",
        region: config.apiGateway.REGION
      },
      {
        name: "email",
        endpoint: "https://nrxmor9yde.execute-api.us-east-1.amazonaws.com/dev/email",
        region: config.apiGateway.REGION
      },
      {
        name: "logging",
        endpoint: "https://a0dmz0rkf9.execute-api.us-east-1.amazonaws.com/dev/logging",
        region: config.apiGateway.REGION
      },
      {
        name: "plans",
        endpoint: "https://3kwcwtgr67.execute-api.us-east-1.amazonaws.com/dev/plans",
        region: config.apiGateway.REGION
      },
      {
        name: "products",
        endpoint: "https://rbkv22fhm3.execute-api.us-east-1.amazonaws.com/dev/products",
        region: config.apiGateway.REGION
      },
      {
        name: "subscription",
        endpoint: "https://3jwxdrfl47.execute-api.us-east-1.amazonaws.com/dev/subscription",
        region: config.apiGateway.REGION
      },
      {
        name: "users",
        endpoint: "https://2er2ma16uj.execute-api.us-east-1.amazonaws.com/dev/users",
        region: config.apiGateway.REGION
      },
      {
        name: "videos",
        endpoint: "https://dacq8ad15e.execute-api.us-east-1.amazonaws.com/dev/videos",
        region: config.apiGateway.REGION
      },
      {
        name: "workout-builder",
        endpoint: "https://98tc2oq3c7.execute-api.us-east-1.amazonaws.com/dev/workout-builder",
        region: config.apiGateway.REGION
      }
    ]
  }
});

const messages = {
  'en': messages_en,
  'zh': messages_zh,
  // 'zh': messages_zh,
};

// const hist = createBrowserHistory();
const history = createHistory()
ReactGA.initialize('UA-112388754-3');
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: false,
      initialStateSample: "",
      current_user: null
    }
    // this.props.test(2);
  }

  async componentDidMount() {
    // this.loadFacebookSDK();

    try {
      // await Auth.currentSession();
      await Auth.currentAuthenticatedUser();
      // console.log("currentAuthenticatedUser:", currentAuthenticatedUser)
      await this.userHasAuthenticated(true);
    } catch (e) {
      // console.log(e);
      if (e !== "No current user") {
        // alert(e);
        console.log(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = async authenticated => {
    // console.log("user has authenticated", authenticated)
    if (authenticated) {
      Auth.currentAuthenticatedUser({
        bypassCache: false
      })
        .then(user => {
          // console.log(user);
          // setTimeout(() => this.props.onLoginSuccess(user), 1000);
          this.props.onLoginSuccess(user);
        })
        .catch(err => {
          console.log(err);
        });
    }

    this.setState({
      isAuthenticated: authenticated
    });
  };

  // async populateData(aws_cognito_id) {
  //   try {
  //     const user = await this.getUserProfile(aws_cognito_id);
  //     console.log(user);
  //     this.setState({
  //       current_user: user
  //     });
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }
  // async populateDataByEmail(email) {
  //   try {
  //     const user = await this.getUserProfileByEmail(email);
  //     console.log(user);
  //     // this.props.onLoginSuccess(user.aws_cognito_id);
  //     this.setState({
  //       current_user: user
  //     });
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }
  // async getUserProfile(aws_cognito_id) {
  //   return API.get("users", `/get-by-id/${aws_cognito_id}`);
  // }

  // async getUserProfileByEmail(email) {
  //   return API.get("portal", `/get-by-email/${email}`);
  // }

  handleLogout = async () => {
    try {
      await Auth.signOut();
      this.userHasAuthenticated(false);
      this.setState({
        current_user: null
      });

    } catch (e) {
      alert(e.message);
    }
  };

  render() {
    //hardcode as true for template, change it when project starts
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      set_current_user: true, //this.set_current_user,
      handleLogout: this.handleLogout,
      current_user: true, //this.state.current_user,
      reload_user: true //this.repopulateData
    };

    return (
      <IntlProvider
        locale={this.props.language.language_current_ui}
        messages={flatten(messages[this.props.language.language_current_ui])}>
        <Router history={history}>
          <Switch>
            {indexRoutes.map((prop, key) => {
              return (
                <AppliedRoute
                  path={prop.path}
                  component={prop.component}
                  key={key}
                  props={childProps}
                />
              );
            })}
          </Switch>
        </Router>
      </IntlProvider>
    );
  }
}

// class App extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       resignedDateJK: ""
//     }

//     // this.onChange = this.onChange.bind(this); //if use arrow funtion, doesn't need this
//   }

//   onChange = (date, dateString) => {
//     console.log(date, dateString);
//     // App.setState ({
//     //   selectedDate: date
//     // });
//     console.log(dateString);
//     //console.log(this);
//     this.setState({
//       resignedDateJK : dateString
//     });
//   }

//   render() {
//     console.log(this.state);
//     console.log(this.props);
//     return (


//       <div className="App">
//         <h2> Pick a date to do something </h2>
//         {/*<Button type="primary">After Babel Button</Button>*/}
//         <React.Fragment>
//           {<DatePicker 
//             onChange = {this.onChange}
//             />}
//         </React.Fragment>
//         <br/>
//         <p><React.Fragment>{this.state.resignedDateJK ? `Your resigned date is going to be ${this.state.resignedDateJK}`: ""} </React.Fragment></p>
//       </div>
//     );
//   }
// }

// export default App;

// const App = () => (
//   <div className="App">
//     <Button type="primary">Button</Button>
//   </div>
// );

// export default App;

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;


// import Link from '../components/Link'
// import App from "./App";
//import language from "./redux/reducers/language";

// const mapStateToProps = (state, ownProps) => {
//   return {
//     active: ownProps.filter === state.visibilityFilter
//   }
// }

const mapStateToProps = (state, ownProps) => {
  return {
    app_state: state.app_state,
    language: state.language
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoginSuccess: (aws_cognito_id) => {
      dispatch(onLoginSuccess(aws_cognito_id));
    }
  };
};

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default AppContainer;
