
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

// import { API, Auth } from "aws-amplify";
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Card, Button } from 'antd';
// import { Tabs } from 'antd';
// import { Form, Input } from 'antd';
// import UserSummary from './UserSummary';
// import UserContact from './UserContact';
// import UserAddress from './UserAddress';
// import Subscription from './Subscription';
import { Typography } from 'antd';

// const { TabPane } = Tabs;
const { Title } = Typography;

// const queryString = require('query-string');


class MyAccountSubscriptionCancelPageComponent extends Component {
  constructor(props) {
    super(props);
    // const parsed = queryString.parse(this.props.location.search);
    this.state = {

    }
  }

  componentDidMount = async () => {

  }

  render() {
    // console.log(this.state);
    return (
      <React.Fragment>
        <Title level={4}>Subscription Process Cancelled</Title>
        <p>
          Our billing provider has advised this subscription was cancelled.<br />
          Please <a href="/my-account?tab=subscription">Click Here</a> to create a new subscription.
          </p>

      </React.Fragment>
    );
  }
}

export default withRouter(MyAccountSubscriptionCancelPageComponent);
