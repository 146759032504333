import React, { Component } from 'react';
// import withStyles from 'react-jss';
import { connect } from "react-redux";
import withStyles from 'react-jss'

import { FormattedMessage } from 'react-intl';

// import { API, Auth } from "aws-amplify";
// import { Layout, Menu } from 'antd';
import { Row, Col } from 'antd';
// import { PageHeader } from 'antd';
import { Skeleton } from 'antd';
import { Typography } from 'antd';
// import { Divider } from 'antd';

import DashboardPageComponent from '../../components/pages/dashboard/dashboard/DashboardPageComponent';

const background_image = require("../../assets/img/blonde_lady_with_towel.jpg");


// const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;

const styles = {
  root: {
    backgroundImage: `url(${background_image})`,
    height: "100%",
    minHeight: 360,
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    backgroundColor: "#000",
    marginBottom: 40,
    '@media (max-width: 400px)': {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${background_image})`,
      height: "100%",
      backgroundPosition: "right",
      backgroundRepeat: "no-repeat",
      backgroundSize: "auto",
      backgroundColor: "black",
      marginBottom: 40,
    }
  },
  menu_text: {
    color: "white",
    fontWeight: 400,
    fontSize: "1.0em",
    '@media (max-width: 350px)': {
      fontWeight: 800,
      fontSize: "1.2em",
    }
  },
  hero_text: {
    fontSize: "1.4em", 
    fontWeight: 600, 
    textAlign: "justify", 
    color: "white",
    '@media (max-width: 500px)': {
      fontWeight: 600,
      fontSize: "1.2em",
    }
  }
}

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    // console.log(this.state);
    const { classes } = this.props;
    let main_component = <DashboardPageComponent />;
    if (!this.props.app_state.current_user_id) main_component = <Skeleton active />;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <Row>
            <Col lg={{ span: 12 }}>
              <div style={{ margin: 40 }}>
                <Title level={2}><FormattedMessage id="dashboard_page.page_title" /></Title>
                <p className={classes.hero_text}>
                  With XO Online, you can expect the same fun, high-energy, heart-pumping, muscle building, body-toning workouts you find in our studios, all taught by your favorite XO studio trainers!
                    Each day, we will put out a new workout for you to stream from any device and all workouts will be saved so you can access them on demand. <br />
                  <br />
                    Stream from anywhere and give yourself some XO anytime!
                  </p>
                <p style={{ fontSize: "1.3em", fontWeight: 600, textAlign: "right", color: "white" }}>
                  The team at <strong>FitnessXO</strong>
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div style={{ padding: 24 }}>
          {main_component}
        </div>
      </React.Fragment >
    );
  }
}


const mapStateToProps = state => {
  return {
    app_state: state.app_state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
}

const DashboardPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPage);

export default withStyles(styles)(DashboardPageContainer);
