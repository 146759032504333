import React, { Component } from 'react';
import withStyles from 'react-jss';
import {
  Card, Row, Col, Button, Typography
} from 'antd';

import WorkoutBuilderViewPageComponentContainer from '../../../components/workout_builder/WorkoutBuilderViewPageComponent';
const background_image_1 = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_426_blend_700x400.jpg');
// const background_image_2 = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_124_blend_700x400.jpg');
// const background_image_3 = require('../../../assets/img/categories/XO_Trainers_FIT_LOWRES_067_blend_700x400.jpg');
// const pink_xo = require('../../../assets/img/pink_xo.jpg');

const {Title} = Typography;

const styles = {
  root: {
    backgroundImage: `url(${background_image_1})`,
    height: '100%',
    minHeight: 360,
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    backgroundColor: '#111',
    marginBottom: 40,
    '@media (max-width: 800px)': {backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${background_image_1})`},
    '@media (max-width: 500px)': {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${background_image_1})`,
      height: '100vh',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      marginBottom: 0
    }
  },
  menu_text: {
    color: 'white',
    fontWeight: 400,
    fontSize: '1.0em',
    '@media (max-width: 500px)': {
      fontWeight: 800,
      fontSize: '1.2em'
    }
  },
  hero_text: {
    fontSize: '1.4em',
    fontWeight: 600,
    textAlign: 'justify',
    color: 'white',
    '@media (max-width: 500px)': {
      fontWeight: 600,
      fontSize: '1.2em'
    }
  }
};

class WorkoutBuilderViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handle_show_all = () => {
    this.props.history.push('/workout-builder/list');
  }

  handle_create_workouts = () => {
    this.props.history.push('/workout-builder/create');
  }

  render() {
    const {classes} = this.props;
    // console.log(this.props.match.params.client_id)

    return (

      <React.Fragment>
        <div
          className={`${classes.root}`}
        >
          <Row>
            <Col lg={{span: 12}}>
              <div style={{margin: 40}}>
                <Title level={2}>Workout Builder</Title>
                <p className={classes.hero_text}>
                View a Workout
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Card
            style={{ minHeight: 360 }}
            extra={[
              <React.Fragment>
                {
                  <span>
                    <Button key="1" onClick={this.handle_show_all}>
                      My Workouts
                    </Button>
                    <Button key="2" onClick={this.handle_create_workouts}>
                      Create New Workouts
                    </Button>
                  </span>
                }
              </React.Fragment>

            ]}
          >
            <div style={{ paddingTop: 24 }}>
              <WorkoutBuilderViewPageComponentContainer
                workout_id={this.props.match.params.workout_id}
              />

            </div>
          </Card>
        </div>
      </React.Fragment>

    );
  }
}

export default withStyles(styles)(WorkoutBuilderViewPage);