
import React, { Component } from 'react';
import withStyles from 'react-jss'
import { withRouter } from "react-router-dom";
// import { FormattedMessage } from 'react-intl';
import { API } from "aws-amplify";
import { connect } from "react-redux";

// import { API, Auth } from "aws-amplify";
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Card, Button } from 'antd';
// import { Tabs } from 'antd';
// import { Form, Input } from 'antd';
import { Row, Col } from 'antd';
import { Typography } from 'antd';
import { Divider } from 'antd';
// import { Button } from 'antd';

import { Card, Avatar } from 'antd';
// import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';


import VideoListComponentContainer from "../../../video/VideoListComponent";
import LeaderboardComponentContainer from '../../../leaderboard/LeaderboardComponent';
import ChallengeComponentContainer from '../../../leaderboard/ChallengeComponent';
import UserStatsComponentContainer from '../../../leaderboard/UserStatsComponent';

// const background_image = require("../../../../assets/img/blonde_lady_with_towel.jpg");
// const card_image_1 = require("../../../../assets/img/card_image_1.jpg");
// const card_image_2 = require("../../../../assets/img/card_image_2.jpg");
// const card_image_3 = require("../../../../assets/img/card_image_3.jpg");
const category_image_067 = require("../../../../assets/img/categories/XO_Trainers_FIT_LOWRES_067_500x280.jpg");
const category_image_124 = require("../../../../assets/img/categories/XO_Trainers_FIT_LOWRES_124_500x280.jpg");
const category_image_426 = require("../../../../assets/img/categories/XO_Trainers_FIT_LOWRES_426_500x280.jpg");
const pink_xo = require("../../../../assets/img/pink_xo.jpg");

const { Title } = Typography;
const { Meta } = Card;

const styles = {
  root: {
    backgroundColor: "#fdfdfd",
    minHeight: 320
  },
};

class DashboardPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_user_views: {}
    }
  }

  componentDidMount() {
      API.get("videos", `/usercount30/${this.props.app_state.current_user_id}`)
      .then(async response => {
        // console.log(response)

        let congratsCase = 0;
        if (response.payload[0].views > 0 && response.payload[0].views < 5) {
          congratsCase = 1
        } else if (response.payload[0].views >= 6 && response.payload[0].views < 11) {
          congratsCase = 2
        } else if (response.payload[0].views >= 11 && response.payload[0].views < 16) {
          congratsCase = 3
        } else if (response.payload[0].views >= 16 && response.payload[0].views < 21) {
          congratsCase = 4
        } else if (response.payload[0].views >= 21) { //response.payload[0].views >= 21 && response.payload[0].views < 25
          congratsCase = 5
        }

        // == Enabled and disabled for test #88 ==
        //congratsCase = 5;
        let congratsWord = ""
        switch (congratsCase) {
          case 1:
            congratsWord = "Teaspoon! (Giving it a crack)";
            break;
          case 2:
            congratsWord = "Weapon!";
            break;
          case 3:
            congratsWord = "Fitness Model of the Universe!";
            break;
          case 4:
            congratsWord = "Fitness God!";
            break;
          case 5:
            congratsWord = "Golden Soup Ladle!";
            break;
          default:
            congratsWord = "";
        }

        this.setState({
          current_user_view_count: response.payload[0].views, //1-30 // == Enabled and disabled for test #88 ==
          current_user_view_congrats_word: congratsWord
        })
      })
      .catch((err) => {
        console.log(err);
      })
  }

  render() {
    // console.log(this.state);
    // console.log(this.props);
    const { classes } = this.props;
    return (

      <React.Fragment>
        <div className={classes.root}>
          <div style={{ marginTop: 20 }}>
            <Row>
              <Col lg={{ span: 20, offset: 2 }}>
                <Title level={3}>Get your dose of XO</Title>
                <Row gutter={[20, 16]}>
                  <Col xs={24} md={6}>
                    <Card
                      // style={{ width: 300 }}
                      style={{ cursor: "pointer" }}
                      hoverable
                      onClick={() => this.props.history.push('/video/category/challenge/30daychallenge')}
                      cover={
                        <img
                          alt="example"
                          src={category_image_426}
                        />
                      }
                      actions={[
                        // <SettingOutlined key="setting" />,
                        // <EditOutlined key="edit" />,
                        // <Button type="primary" block onClick={() => this.props.history.push('/daily-hiits/list')}>View Videos</Button>,
                      ]}
                    >
                      <Meta
                        avatar={<Avatar shape="square" src={pink_xo} />}
                        title="30 for 30 Challenge"
                        description="October 2021"
                      />
                    </Card>
                  </Col>
                  <Col xs={24} md={6}>
                    <Card
                      // style={{ width: 300 }}
                      style={{ cursor: "pointer" }}
                      hoverable
                      onClick={() => this.props.history.push('/video/category/daily-hiits')}
                      cover={
                        <img
                          alt="example"
                          src={category_image_426}
                        />
                      }
                      actions={[
                        // <SettingOutlined key="setting" />,
                        // <EditOutlined key="edit" />,
                        // <Button type="primary" block onClick={() => this.props.history.push('/daily-hiits/list')}>View Videos</Button>,
                      ]}
                    >
                      <Meta
                        avatar={<Avatar shape="square" src={pink_xo} />}
                        title="Daily HIITs"
                        description="Your daily high intensity interval workout done in 40 min."
                      />
                    </Card>
                  </Col>
                  <Col xs={24} md={6}>
                    <Card
                      // style={{ width: 300 }}
                      style={{ cursor: "pointer" }}
                      hoverable
                      onClick={() => this.props.history.push('/video/category/quick-hiits')}
                      cover={
                        <img
                          alt="example"
                          src={category_image_124}
                        />
                      }
                      actions={[
                        // <SettingOutlined key="setting" />,
                        // <EditOutlined key="edit" />,
                        // <Button type="primary" block onClick={() => this.props.history.push('/quick-hiits/list')}>View Videos</Button>,
                      ]}
                    >
                      <Meta
                        avatar={<Avatar shape="square" src={pink_xo} />}
                        title="Quick HIITs"
                        description="Six exercises in six minutes. Pick and choose what suits you."
                      />
                    </Card>
                  </Col>
                  <Col xs={24} md={6}>
                    <Card
                      // style={{ width: 300 }}
                      style={{ cursor: "pointer" }}
                      hoverable
                      onClick={() => this.props.history.push('/video/category/wellness')}
                      cover={
                        <img
                          alt="example"
                          src={category_image_067}
                        />
                      }
                      actions={[
                        // <SettingOutlined key="setting" />,
                        // <EditOutlined key="edit" />,
                        // <Button type="primary" block onClick={() => this.props.history.push('/wellness/list')}>View Videos</Button>,
                      ]}
                    >
                      <Meta
                        avatar={<Avatar shape="square" src={pink_xo} />}
                        title="Wellness"
                        description="Pilates, stretching, meditation and yoga. Balance mind and body."
                      />
                    </Card>
                  </Col>
                </Row>
                <Divider />
                <React.Fragment>
                    <Row gutter={[20, 16]}>
                      <Col xs={24} md={{ span: 12, offset: 6 }} style={{ textAlign: "center" }}>
                        <Title level={3}>XOlation 30-Day Challenge</Title>
                      </Col>
                    </Row>
                  </React.Fragment>
                <Row gutter={[20, 16]}>
                  <Col xs={24} md={{ span: 12, offset: 6 }}>
                    <Card
                      // style={{ width: 300 }}
                      style={{ cursor: "pointer" }}
                      hoverable
                      // onClick={() => this.props.history.push('/wellness/list')}
                      cover={
                        <ChallengeComponentContainer />
                      }
                      actions={[
                        // <SettingOutlined key="setting" />,
                        // <EditOutlined key="edit" />,
                        // <Button type="primary" block onClick={() => this.props.history.push('/wellness/list')}>View Videos</Button>,
                      ]}
                    >
                    </Card>
                  </Col>
                </Row>
                <Divider />
                {!!this.state.current_user_view_count &&
                  <React.Fragment>
                    <Row gutter={[20, 16]}>
                      <Col xs={24} md={{ span: 12, offset: 6 }} style={{ textAlign: "center" }}>
                        <Title level={3}>Congratulations, you are now a {this.state.current_user_view_congrats_word} </Title>
                      </Col>
                    </Row>
                  </React.Fragment>
                }
                <Row gutter={[20, 16]}>
                  <Col xs={24} md={{ span: 12 }}>
                    <Card
                      // style={{ width: 300 }}
                      style={{ cursor: "pointer" }}
                      hoverable
                      // onClick={() => this.props.history.push('/wellness/list')}
                      cover={
                        <LeaderboardComponentContainer />
                      }
                      actions={[
                        // <SettingOutlined key="setting" />,
                        // <EditOutlined key="edit" />,
                        // <Button type="primary" block onClick={() => this.props.history.push('/wellness/list')}>View Videos</Button>,
                      ]}
                    >
                      <Meta
                        avatar={<Avatar shape="square" src={pink_xo} />}
                        title="30 Day Leaderboard"
                        description={`Global Leaderboard for XOlation for the past 30 days. `}
                      />
                    </Card>
                  </Col>
                  <Col xs={24} md={{ span: 12 }}>
                    <Card
                      // style={{ width: 300 }}
                      style={{ cursor: "pointer" }}
                      hoverable
                      // onClick={() => this.props.history.push('/wellness/list')}
                      cover={
                        <UserStatsComponentContainer />
                      }
                      actions={[
                        // <SettingOutlined key="setting" />,
                        // <EditOutlined key="edit" />,
                        // <Button type="primary" block onClick={() => this.props.history.push('/wellness/list')}>View Videos</Button>,
                      ]}
                    >
                      <Meta
                        avatar={<Avatar shape="square" src={pink_xo} />}
                        title="60-day Stats"
                        description={`Your 60-day statistics for workout completions`}
                      />
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Divider />
          <div style={{ marginTop: 20 }}>
            <Row>
              <Col lg={24}>
                <Title level={3}>Recent Uploads</Title>
                <p>Showing the most recent 5 videos</p>
                <React.Fragment>
                  <VideoListComponentContainer size="small" show_only="5" />
                </React.Fragment>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// export default withRouter(withStyles(styles)(DashboardPageComponent));
const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const DashboardPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPageComponent);

export default withRouter(withStyles(styles)(DashboardPageComponentContainer));
