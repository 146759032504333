import React, { Component } from 'react';
import { connect } from "react-redux";
import withStyles from 'react-jss'

import { setLanguageSelectId } from "../../../redux/actions/language";

import {
  PageHeader,
  // Button,
} from 'antd';

// import { Select } from 'antd';
// import ReactCountryFlag from "react-country-flag"

import logo from '../../../assets/img/branding/fitnessxo-logo.png';


// const { Option } = Select;

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;


const styles = {
  heading: {
    backgroundColor: '#212832',
    color: '#c30f48',
    fontWeight: 'bold'
  },
  headingText: {
    color: "white",
    fontWeight: "bold"
  },
  headingSubText: {
    fontSize: "10px",
    '@media (max-width: 500px)': {
      display: "none",
    }
  },
  top_logo: {
    height: "30px", 
    paddingLeft: 2, 
    paddingRight: 20,
    // '@media (max-width: 400px)': {
    //   display: "none",
    // },
  },
  top_language_select: {
    width: 180,
    '@media (max-width: 400px)': {
      display: "none",
    }
  }
}

class PagesHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handle_language_select = (value) => {
    this.props.setLanguageSelectId(value);
  }

  handleLogout = async event => {
    this.props.handleLogout();
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        
        <PageHeader
          className={classes.heading}
          ghost={false}
          // onBack={() => window.history.back()}
          style={{ background: '#001529', height: 70 }}
          // title={<React.Fragment><img src={logo} alt="" style={{width: 150, marginTop: -10, marginRight: 30  }} /><span style={{color: "White", fontWeight: 700,  fontSize: "1.8em" }}><strong>XOlation</strong></span></React.Fragment>}
          title={<React.Fragment>
            <img src={logo} alt="" className={classes.top_logo}/>
            <span className={classes.headingText}>XOlation <span className={classes.headingSubText}>by FitnessXO</span></span>
          </React.Fragment>}
        >
        </PageHeader>
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguageSelectId: (language_id) => {
      dispatch(setLanguageSelectId(language_id))
    }
  }
}

const PagesHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PagesHeader);

export default withStyles(styles)(PagesHeaderContainer);
