import React, { Component } from 'react';
import { connect } from "react-redux";
import withStyles from 'react-jss'

import { setLanguageSelectId } from "../../../redux/actions/language";

import {
  PageHeader,
  Button,
} from 'antd';

import { Select } from 'antd';
import ReactCountryFlag from "react-country-flag"

import { LogoutOutlined } from '@ant-design/icons';

import logo from '../../../assets/img/branding/fitnessxo-logo.png';
// const logo = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAAEElEQVR42mNkYPhfz0BFAAB0hwGAwLqecAAAAABJRU5ErkJggg=="

const { Option } = Select;

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

const styles = {
  heading: {
    backgroundColor: '#212832',
    color: '#c30f48',
    fontWeight: 'bold',
    padding: 12
  },
  headingText: {
    color: "white",
    fontWeight: "bold"
  },
  headingSubText: {
    fontSize: "10px",
    '@media (max-width: 500px)': {
      display: "none",
    }
  },
  top_logo: {
    height: "30px", 
    paddingLeft: 2, 
    paddingRight: 20,
    // '@media (max-width: 400px)': {
    //   display: "none",
    // },
  },
  top_language_select: {
    width: 180,
    '@media (max-width: 500px)': {
      display: "none",
    }
  },
  logout_button: {
    color: "white"
    // '@media (max-width: 500px)': {
    //   display: "none",
    // }
  }
}


class DashboardHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handle_language_select = (value) => {
    this.props.setLanguageSelectId(value);
  }

  handleLogout = async event => {
    this.props.handleLogout();
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <PageHeader
          className={classes.heading}
          ghost={false}
          // onBack={() => window.history.back()}
          title={<React.Fragment>
            <img src={logo} alt="" className={classes.top_logo}/>
            <span className={classes.headingText}>XOlation <span className={classes.headingSubText}>by FitnessXO</span></span>
          </React.Fragment>}
          // subTitle="This is a subtitle"
          // extra={this.props.isAuthenticated ? [
          extra={ [
            <Select
              key="language_select"
              defaultValue={this.props.language.language_current_ui}
              className={classes.top_language_select}
              onChange={this.handle_language_select}>
              <Option value="en" key="en">
                <ReactCountryFlag
                  className="emojiFlag"
                  countryCode="GB"
                  style={{
                    fontSize: '1.5em',
                    lineHeight: '1.5em',
                  }}
                  aria-label="English Language"
                />
                English
              </Option>
            </Select>,
            <Button 
              key="1" 
              type="primary" 
              className={classes.logout_button} 
              onClick={this.handleLogout}
              icon={<LogoutOutlined />}
              />
          ]}
        >
        </PageHeader>
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguageSelectId: (language_id) => {
      dispatch(setLanguageSelectId(language_id))
    }
  }
}

const DashboardHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardHeader);

export default withStyles(styles)(DashboardHeaderContainer);
