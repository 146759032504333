import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from 'react-jss';
import { withRouter } from 'react-router-dom';
import { mod } from 'mathjs';

import {
  // createNewWorkoutBuilder,
  updateNewWorkoutBuilder
} from '../../redux/actions/workout_builder';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import { API } from 'aws-amplify';
import {
  Row, Col, Table, notification, Card, Button, Skeleton, Form, Input, Tag
} from 'antd';

import ExerciseListComponentContainer from './ExerciseListComponent';
import CategoryListComponentContainer from './CategoryListComponent';

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const styles = {
  video_description: {
    padding: 0,
    // paddingTop: 24,
    // background: '#fff',
    // minHeight: 360,
    '@media (min-width: 800px)': {
      padding: 24
      // paddingTop: 24,
    }
    // '@media (min-width: 1000px)': {
    //   padding: 0,
    //   paddingTop: 24,
    // },
    // '@media (min-width: 1200px)': {
    //   padding: 24,
    // },
  }
};

const moment = require('moment');

let dragingIndex = -1;

class BodyRow extends React.Component {
  render() {
    const {
      isOver, connectDragSource, connectDropTarget, moveRow, ...restProps
    } = this.props;
    const style = {
      ...restProps.style,
      cursor: 'move'
    };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {index: props.index,};
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({connectDragSource: connect.dragSource(),}))(BodyRow),
);

class WorkoutBuilderCreatePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file_by: '',
      video: {
        id: '',
        record_created: '',
        file_id: '',
        description: '',
      }
    };
    this.workoutColumns = [
      // {
      //   title: 'Sort',
      //   dataIndex: 'sort',
      //   width: 30,
      //   className: 'drag-visible',
      //   render: () => <DragHandle />,
      // },
      // {
      //   title: "ID",
      //   dataIndex: "id",
      //   width: 0,
      //   render: (text, record) => {
      //     return {
      //       props: {
      //         style: { verticalAlign: "top" },
      //       },
      //       children: (
      //         <React.Fragment>
      //           {text}
      //         </React.Fragment>
      //       )
      //     }
      //   }
      // },
      {
        title: 'Poster',
        dataIndex: 'exercise_poster_url',
        key: 'exercise_poster_url',
        render: (text) => {
          return {
            props: { style: { verticalAlign: 'top' } },
            children: (
              <React.Fragment>
                <img src={text} style={{
                  height: 50,
                  maxWidth: 100
                }}></img>
              </React.Fragment>
            )
          };
        }
      },
      {
        title: 'Exercise',
        dataIndex: 'short_description',
        key: 'short_description',
        // ...this.getColumnSearchProps('short_description'),
        sorter: (a, b) => moment(a.public_date).unix() - moment(b.public_date).unix(),
        // defaultSortOrder: "descend",
        render: (text, record) => {
          // console.log(record)
          return text ?
            <React.Fragment>
              <h3>{text}</h3>
            </React.Fragment>
            : ''
        }
      },
      //#188 Hide gender tags
      // {
      //   title: 'Gender',
      //   dataIndex: 'gender',
      //   key: 'gender',
      //   // ...this.getColumnSearchProps('short_description'),
      //   sorter: (a, b) => moment(a.public_date).unix() - moment(b.public_date).unix(),
      //   // defaultSortOrder: "descend",
      //   render: (text, record) => {
      //     return record.gender === 1 ? (
      //       <Tag color='blue' key='male'>
      //         {' '}
      //         Male{' '}
      //       </Tag>
      //     ) : record.gender === 2 ? (
      //       <Tag color='magenta' key='female'>
      //         {' '}
      //         Female{' '}
      //       </Tag>
      //     ) : (
      //       ''
      //     )
      //   }
      // },
      {
        title: 'Length',
        dataIndex: 'length',
        key: 'length',
        // ...this.getColumnSearchProps('short_description'),
        sorter: (a, b) => moment(a.public_date).unix() - moment(b.public_date).unix(),
        // defaultSortOrder: "descend",
        render: (text, record) => {
          return record.length ? <React.Fragment>{Math.floor(record.length / 60)}{':'}{(mod(record.length, 60) == 0) ? '00' : mod(record.length, 60)}</React.Fragment> : ''
        }
      },
      {
        title: 'Actions',
        key: 'action',
        align: 'right',
        render: (text, record) => {
          // console.log(record.key)
          return record.id ?
            <React.Fragment>
              {<Button onClick={() => this.removeItem(record.key)} >Remove</Button>}
            </React.Fragment>
            : ''
        }
      }
    ];
  }

  components = {body: {row: DragableBodyRow,},};

  moveRow = (dragIndex, hoverIndex) => {
    let exercise_list_data = [];
    exercise_list_data = this.props.workout_builder.new_workout_builder_data.workouts;
    
    for (let i = 0; i < exercise_list_data.length; i++) {
      exercise_list_data[i].key = i
    }

    // console.log(exercise_list_data)
    const insert = (arr, index, newItem) => [
      // part of the array before the specified index
      ...arr.slice(0, index),
      // inserted item
      newItem,
      // part of the array after the specified index
      ...arr.slice(index)
    ]

    const dragRow = exercise_list_data[dragIndex];

    let result = [];
    if (dragIndex > hoverIndex) {
      result = insert(exercise_list_data, hoverIndex, dragRow);
    } else {
      result = insert(exercise_list_data, hoverIndex + 1, dragRow);
    }

    if (dragIndex > hoverIndex) {
      //reset keys
      for (let i = 0; i < result.length; i++) {
        result[i].key = i
      }
      result.splice(dragIndex + 1 , 1);
    } else {
      //reset keys
      for (let i = 0; i < result.length; i++) {
        result[i].key = i
      }
      result.splice(dragIndex, 1);
    }
    // console.log(result)
    this.props.updateNewWorkoutBuilder('workouts', result);
  };

  removeItem = (key) => {
    // console.log('remove ' + key )

    let exercise_list_data = [];
    exercise_list_data = this.props.workout_builder.new_workout_builder_data.workouts;

    function arrayRemove(arr, value) {

      return arr.filter(function (ele) {
        return ele.key != value;
      });
    }

    const result = arrayRemove(exercise_list_data, key);

    this.setState({ show_modal_to_add_exercise: false })

    // console.log(result)

    this.props.updateNewWorkoutBuilder('workouts', result);
  }

  handle_add_rest_30s = (key) => {
    // console.log('remove ' + key )

    let exercise_list_data = [];
    exercise_list_data = this.props.workout_builder.new_workout_builder_data.workouts;
    const result = exercise_list_data.push(
      {
        exercise_poster_url: 'https://d3o1qc2449xp6l.cloudfront.net/public/exercise-poster-20210227-181236-2b1e6ac0-78cb-11eb-b51e-a3905581efd5?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA4Z7AOTVVKBCUBBVT%2F20210524%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20210524T010414Z&X-Amz-Expires=900&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEkaCXVzLWVhc3QtMSJHMEUCIQD3%2FrB5cvoAnaUbDXout5sxpB2lsoJWp4Kq0uDyzy6lxAIgR0HXmn7%2FiCj5VaAETZOCwmr2SPkAbP%2FLJbHcxVvFWGwqzQQI4v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARACGgw4ODA0MDIxNDQ2MTgiDMbDhlCCbiA2wZYCoiqhBKWMOm7JFd%2FCxcqXy4M9G6bRtgDPQ4CXaWFHfUvaQj%2Bao4%2FisbQoVGGaooa9ImTaU6oVdfH35HhVSjH0S%2BaEaha39YbiO%2FC7TeuI2fp4fiAsd9hpOJZgmyv%2F%2FvUky87gn7AE1OGtNjf462ktISC3LGfNBP6w0IUSD09q9qtcycyC9LJxJg%2FP6lFv5O1YqnHKeuTFjj8xc%2FDcvCyg0ujH884PmCEmSiUoKosZWHkEIka5uznxvNZq6MXc059vh%2FspzD2wEYeoCvriTNirbBxjCHFi5q6gBMR5hMtyaWzk6m8Vppy0cmfi68%2F6tj7z8oDTEt9aDZ9eYQwhNSleNNiSUx3hRkWmwqm01DSihNILUP4VPDTFNnQhuWXlMkfuDCMCD0Qm4qMerwCG7iEzF5weU3zpdv1JK0XA3Wsmq82r1Ta5jpYwAnbXxt%2FORogeYZ0JwjemDiAEJ1z%2Fm0uMNrpOkvPfmcUfnXvXSprOV6yQPhmxK53Bk%2FygH1hMjkEpt6DQNm%2FQZHj%2FD3he5h0AOy84y%2FG9l5nGNltkemrrE0kERQovoYQTWKOKOnB4jOb7sRFwp1ktHOdPQpKMRLfy2tie%2B%2B1cwNrCvr2sEQRhg%2BbMwjm57cHfUuC4duFJJKp8fpqybOnsn2K%2BFNo7jcMFIjBgz9fqfLQhd0XMfnkgwpbMzCbRJrgZpJKo%2B43oV8sQXFHuD6s%2BGCBwYIGEpjv7JlDCo0ffMJX2q4UGOoUCuLIt4N%2FWRDV6RxRWeJJYQYCd%2BBGRfVmvSToQfoYC4cHZzvKvuhWYH5qfKR1STs5%2FHLMhLzHDhTbNKWdFnz5ScH6wv1%2FFxVp9kA7U88zvO1rmcqjoTmM1rTVOoTLK%2BAAG7UulhqgNmTo3FCW6Jk6MUH0ix5uMF3WRkRIF9e5dR9kdnHr5yBio9YQS3HHUC9f%2Fs1zMqhF%2FRjlSu35F6c1OX5TqbQtbBu7Fik0on4zpu74a6lT57ltxAoXZzQyQg9wmgw7VBJWiW5K8TAQg9HtA77HEnDqbRifcSaFQ37Qo6TGco%2F9V0AMB6884fMbA7ySBOIoO5d%2FyrPl2nx%2BT9joRTwmkOzeZ&X-Amz-Signature=ff484a56e90a27a8da60bede0cbf9e517bd2fd9b9980a51194411fc72c5efa27&X-Amz-SignedHeaders=host',
        file_by: null,
        gender: null,
        id: 19,
        key: exercise_list_data.length,
        length: 30,
        option: 1,
        poster_file_id: 'exercise-poster-20210227-181236-2b1e6ac0-78cb-11eb-b51e-a3905581efd5',
        short_description: 'Rest 30 seconds'
      }

    );

    this.setState({ show_modal_to_add_exercise: false })

    console.log(result)

    // this.props.updateNewWorkoutBuilder('workouts', result);
  }

  handle_add_rest_60s = (key) => {
    // console.log('remove ' + key )

    let exercise_list_data = [];
    exercise_list_data = this.props.workout_builder.new_workout_builder_data.workouts;
    const result = exercise_list_data.push(
      {
        exercise_poster_url: 'https://d3o1qc2449xp6l.cloudfront.net/public/exercise-poster-20210227-180926-b9d05fe0-78ca-11eb-b51e-a3905581efd5?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA4Z7AOTVVDDGCJPWM%2F20210524%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20210524T011242Z&X-Amz-Expires=900&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEoaCXVzLWVhc3QtMSJHMEUCIBGKX7ASjELjiTeJo3G9p4nxMn3ti47w%2BjbvWmvlUTdTAiEA6SBzMa0O253Fe%2BLkFsWa%2BIZKNuMRmK9siaaT%2BXDzlnQqzQQI4v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARACGgw4ODA0MDIxNDQ2MTgiDJ%2FRf7vpYb%2FAhUNHZSqhBBiq9HwCVi9io5RPK3RZmF571KfcNKtG6UtP%2BPiGRikh0s5YiWBcHCdcNG2Tj3ZosXnVRyoumRMJOjUBc4hEbiuGO%2BH3%2F664hzRlbLZ16X6XVfkFDtG4vkHo2P3O59AC%2FZnrmYFi%2FcmIgIsPdn57aNvW01GmiFqJdtNxY3JCOI%2F1XNfN3ci9xx7F6%2FgEdFlDehV%2BSsFwW7lvE2fj%2Fr7OivqwCbbYglB%2BGUcsH53QxUIvN3P%2Bf1KJ0pejpJx4ZNQtm7rSz8ffF8voDUPYEcU34GBihkiKq7U5i5bB7%2F9c7dOQ02SJvtNGbIss102X0Rm5ag1qVDvsxw41YoW0fsoPTMnnCvzjBWadroJedNOhk4nX0Poh%2BfEId3EdvMRw05Eq%2BdwuItMkT%2BKpIRwbYNyyJnmR7mKr4U07wpDCZ885YTr%2F47FpruilRZ1TjggnGXFjT%2BOyG9IEruSPvMbsYalWEIIPphUXJnDpSD859y5z4oVGxYIVwGtDAA6HaViqJGfEQzDkNvwp2yFjpANLospABfWINC58nGXtV0bIoDGjpAK677Yst3%2Bd3rAdl51sH6qn3bGGVqVbg%2F3xP0DaMBJORS%2F8GGmZulxr2F36LNfFHuCS6fxvSE30SXMlklDqBhFEQA8Tgcxwi5GDRhF9mS257%2FxUZYtlwfN0W3gdQALpEOz98oREQBKkA6HCWS6402tAm1UJ5QveMv4e6I1k6jFJljQwMNf5q4UGOoUCcqI12b%2B%2FxgunfqXClkgPwrY%2BzrfHRgnzu74sbgRDeI2w03jpEpiIlqyfVhdpFu99BuuCKeytC3QbpFxP2wudXKk3j7vEoD%2BT60PcIkkq3haQlchSmN0sVFAkLNKfJPMoA%2BRh2wVMEPzhbxpkYzBhy37YNUDA91n4IxA4d9YcHYNs%2B6cwjj4ck%2BGs5G26xHUq%2B9KhyEwLzFvfoJDQlWi8snLRLi%2FFOvGkyk8OksGRI6rJNemBT5fwgAxf%2FpYjcNZMmqz58CZwFAhVHKZ9KsnhuQd7NgRB3u%2FnaFVmxJ8RPpsUNCZNH%2BAzXOsESiZ4gKIOyITEchQJgEurLnU22%2B0PNTQX3HVP&X-Amz-Signature=cb0942f356a51e154a09c379e7e48ca4c16ae6927ead7c554ff5f6e19ea4ec22&X-Amz-SignedHeaders=host',
        file_by: null,
        gender: null,
        id: 17,
        key: exercise_list_data.length,
        length: 60,
        option: 2,
        poster_file_id: 'exercise-poster-20210227-180926-b9d05fe0-78ca-11eb-b51e-a3905581efd5',
        short_description: 'Rest 60 seconds',
      }

    );

    this.setState({ show_modal_to_add_exercise: false })

    console.log(result)

    // this.props.updateNewWorkoutBuilder('workouts', result);
  }

  async componentDidMount() {
    // this.props.createNewWorkoutBuilder();

    if (this.props.workout_builder.current_category_id) {
      const exercise_list_data = [];

      this.setState({exercise_list_data: exercise_list_data});
      console.log(exercise_list_data);
      this.props.updateNewWorkoutBuilder('workouts', exercise_list_data); //test
    }
  }

  handleChange = event => {
    // console.log(event)
    this.setState({ [event.target.id]: event.target.value });
    // this.props.updateNewClientCreation(event.target.id, event.target.value);
  };

  handle_saving_builder = async () => {
    // console.log(this.props);
    const workoutStringify = JSON.stringify(this.props.workout_builder.new_workout_builder_data.workouts);
    console.log(workoutStringify);

    try {
      await API.post('workout-builder', '/workouts/create', {
        body: {
          id: 1,
          file_by: this.state.file_by, //"Test",
          client_id: this.props.app_state.current_user_id,
          exercise_details: workoutStringify
        }
      });

      await API.post('email', '/interaction', {
        body: {
          interaction: 'Workouts Creation',
          data: {
            id: 1,
            file_by: this.state.file_by, //"Test",
            client_id: this.props.app_state.current_user_id,
            exercise_count: workoutStringify.length,
            exercise_details: workoutStringify
          },
          result: 'Workouts Creation'
        }
      })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });

      openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')

      await this.props.history.push('/workout-builder/list');
    } catch (e) {
      console.log(e);
      openNotificationWithIcon('error', 'Save Failed', 'An error occurred in data saving')
    }
  };

  render() {
    const { classes } = this.props;
    // console.log(this.props);

    if (!this.props.workout_builder || !this.props.workout_builder.new_workout_builder_data || !this.props.workout_builder.new_workout_builder_data.workouts) {
      return <Skeleton active />;
    }

    const data = this.props.workout_builder.new_workout_builder_data.workouts.slice(
      0,
      this.props.workout_builder.new_workout_builder_data.workouts.length
    );

    let workout_length = 0;
    for (let i = 0; i < this.props.workout_builder.new_workout_builder_data.workouts.length; i++) {
      console.log(this.props.workout_builder.new_workout_builder_data.workouts[i].short_description.toLowerCase())
      if (this.props.workout_builder.new_workout_builder_data.workouts[i].short_description.toLowerCase().includes('rest')) {
        console.log('rest', this.props.workout_builder.new_workout_builder_data.workouts[i].short_description)
        console.log(this.props.workout_builder.new_workout_builder_data.workouts[i].length)
        workout_length += this.props.workout_builder.new_workout_builder_data.workouts[i].length;
      } else {
        console.log('exercise', this.props.workout_builder.new_workout_builder_data.workouts[i].short_description)
        console.log(this.props.workout_builder.new_workout_builder_data.workouts[i].length)
        workout_length += this.props.workout_builder.new_workout_builder_data.workouts[i].length;
        workout_length += 15;
      }
    }

    return (
      <React.Fragment>
        <div className={classes.root}>
          {this.props.workout_builder.new_workout_builder_data.processing && <Skeleton active />}
          <Row gutter={[48, 24]}>
            {!this.props.workout_builder.new_workout_builder_data.processing && (
              <Col xs={8}>
                <Card
                  className={classes.main_card}
                  // bodyStyle={{ padding: 0 }}
                  // style={{ padding: 0 }}
                  title='WORKOUT'
                  actions={[
                    <React.Fragment>
                      <Button key='2' type="primary" onClick={this.handle_add_rest_30s}>
                        Add Rest 30 secs
                      </Button>
                      &nbsp;
                      <Button key='3' type="primary" onClick={this.handle_add_rest_60s}>
                        Add Rest 60 secs
                      </Button>
                      <br />
                      Exercise Count: {this.props.workout_builder.new_workout_builder_data.workouts.length}
                      &nbsp;&nbsp;|&nbsp;&nbsp; Workout Length: {
                        workout_length ? <React.Fragment>{Math.floor(workout_length / 60)}{':'}{(mod(workout_length, 60) == 0) ? '00' : mod(workout_length, 60)}</React.Fragment> : ''} &nbsp;&nbsp;&nbsp;
                      <Button key='1' disabled={!this.state.file_by} onClick={this.handle_saving_builder}>
                        Save
                      </Button>

                    </React.Fragment>
                  ]}
                >
                  <Form layout='vertical'>
                    <Row gutter={[12, 0]}>
                      <Col xs={24}>
                        <Form.Item label=''>
                          <Input
                            id='file_by'
                            placeholder="Name your workout..."
                            value={this.state.file_by}
                            onChange={event => {
                              this.handleChange(event);
                            }}
                          />
                          {!this.state.file_by && (
                            <span style={{ color: 'red' }}>** Please name your workout...</span>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                  <div style={{ padding: 0 }}>
                    {/*
                      <DndProvider backend={HTML5Backend}>
                        <Table
                          dataSource={data}
                          columns={this.workoutColumns}
                          showHeader={false}
                          scroll={{ y: 500 }}
                          pagination={false}
                          onRow={(record, index) => ({
                            index,
                            moveRow: this.moveRow,
                          })}
                        />
                        </DndProvider>*/
                    }
                    <br/>{
                      <DndProvider backend={HTML5Backend}>
                        <Table
                          columns={this.workoutColumns}
                          dataSource={data}
                          components={this.components}
                          onRow={(record, index) => ({
                            index,
                            moveRow: this.moveRow,
                          })}
                          pagination={false}
                        />
                      </DndProvider>
                    }
                  </div>
                </Card>
              </Col>
            )}

            <Col xs={4}>
              <Card
                className={classes.main_card}
                title='CATEGORY'
              >
                <div style={{ padding: 0 }}>
                  <CategoryListComponentContainer
                  />
                </div>
              </Card>
            </Col>
            <Col xs={12}>
              <Card className={classes.main_card} title='EXERCISE'>
                <div style={{ padding: 0 }}>
                  <ExerciseListComponentContainer />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
    workout_builder: state.workout_builder
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewWorkoutBuilder: (key, value) => {
      //reset key
      for (let i = 0; i < value.length; i++) {
        value[i].key = i
      }
      dispatch(updateNewWorkoutBuilder(key, value));
    }
  };
};

const WorkoutBuilderCreatePageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkoutBuilderCreatePageComponent);

export default withStyles(styles)(withRouter(WorkoutBuilderCreatePageComponentContainer));
