
import React, { Component } from 'react';
import { connect } from "react-redux";

import { API, Auth } from "aws-amplify";
// import { Auth } from "aws-amplify";
// import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Modal } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class UserSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      change_password_dialog: false,
      current_password: "",
      new_password: "",
      confirm_new_password: "",
      cancel_dialog_visible: false
    }
  }

  async componentDidMount() {
    const user = await this.getUserProfile(
      this.props.app_state.current_user_id
    );
    // console.log(user)
    this.setState({
      ...user.payload
    })
  }

  // async componentWillReceiveProps(nextProps, nextContext) {
  //   // console.log(nextProps)
  //   if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
  //     const user = await this.getUserProfile(
  //       nextProps.app_state.current_user_aws_cognito_id
  //     );
  //     console.log(user)
  //     this.setState({
  //       ...user.payload
  //     })
  //   }
  // }


  async getUserProfile(current_user_id) {
    // console.log(current_user_id)
    return API.get("users", `/get/id/${current_user_id}`);
  }

  async saveUserProfile(user) {
    await API.put("users", `/update/${this.state.aws_cognito_id}`, {
      body: user
    });
    openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  }


  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value,
      [`${[event.target.id]}_state`]: "",
      [`${[event.target.id]}_help`]: "",
    });
  };

  change_password = () => {
    this.setState({
      change_password_dialog: true
    });
  }

  // cancel_sub = e => {
  //   console.log(e);
  //   this.setState({
  //     cancel_dialog_visible: false,
  //   });

  //   // alert("Are you sure you are going to cancel this subscription?");
  //   API.post("email",`/client/cancel-portal-subs`, {
  //     body: {
  //       id: this.props.app_state.current_client_record.id,
  //       username: this.props.app_state.current_client_record.email,
  //     }
  //   }).then(response => {
  //     console.log(response);
  //     openNotificationWithIcon('success', "Subscription Cancellation", "Cancellation request has been sent.  We will be in touch to confirm with you shortly.")
  //   })
  //   .catch(error => {
  //     console.log(error);
  //   });
  // }

  handle_change_password_ok = async (current_password, new_password, confirm_new_password) => {
    // Validate the modal form
    let modal_valid = true;
    if (!current_password) {
      this.setState({
        current_password_state: "error",
        current_password_help: "Please enter current password"
      });
      modal_valid = false;
    }
    if (!new_password) {
      this.setState({
        new_password_state: "error",
        new_password_help: "Password must be 8 characters with uppercase, lowercase and numbers"
      });
      modal_valid = false;
    }
    if (!confirm_new_password) {
      this.setState({
        confirm_new_password_state: "error",
        confirm_new_password_help: "Please re-enter your password here"
      });
      modal_valid = false;
    }
    if (new_password != confirm_new_password) {
      this.setState({
        confirm_new_password_state: "error",
        confirm_new_password_help: "Password and confirm must be the same"
      });
      modal_valid = false;
    }

    if (!modal_valid) {
      return;
    }

    this.setState({
      confirmLoading: true,
    });

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, current_password, new_password);
      this.setState({
        new_password_changing: false,
        change_password_dialog: false,
        current_password: "",
        new_password: "",
        new_password_confirm: ""
      });
      openNotificationWithIcon('success', "Password Changed", "Your password has been changed successfully")
    } catch (e) {
      openNotificationWithIcon('error', "Error", e.message);
      this.setState({
        change_password_dialog: false,
        confirmLoading: false,
        current_password: "",
        new_password: "",
        new_password_confirm: ""
      });
    }
  };

  handle_change_password_cancel = () => {
    console.log('Clicked cancel button');
    this.setState({
      change_password_dialog: false,
    });
  };


  handleSubmit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const {
      // aws_cognito_id,
      first_name,
      middle_name,
      last_name,
      // email,
    } = this.state;
    try {
      await this.saveUserProfile({
        // aws_cognito_id: aws_cognito_id,
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
      });
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      try {
        await this.saveUserProfile({
          // aws_cognito_id: aws_cognito_id,
          first_name: first_name,
          middle_name: middle_name,
          last_name: last_name,
        });
        // this.props.history.push("/");
        // this.props.reload_user(this.state.aws_cognito_id);
      } catch (e) {
        // alert(e);
        openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
        // this.setState({ isLoading: false });
      }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  // showModal = () => {
  //   this.setState({
  //     cancel_dialog_visible: true,
  //   });
  // };

  // handleOk = e => {
  //   console.log(e);
  //   this.setState({
  //     cancel_dialog_visible: false,
  //   });
  // };

  // handleCancel = e => {
  //   console.log(e);
  //   this.setState({
  //     cancel_dialog_visible: false,
  //   });
  // };

  render() {
    // console.log(this.state);
    const { 
      change_password_dialog, 
      confirmLoading, 
      // ChangePasswordContent 
    } = this.state;

    return (

      <React.Fragment>
        <Form
          layout="vertical"
          onSubmit={this.handleSubmit}>

          <Row gutter={[96, 24]}>
            <Col xs={24} sm={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="First Name">
                    <Input
                      id="first_name"
                      value={this.state.first_name}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Middle Name(s)">
                    <Input
                      id="middle_name"
                      value={this.state.middle_name}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Last Name" >
                <Input
                  id="last_name"
                  value={this.state.last_name}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="E-mail">
                <Input
                  id="email"
                  disabled
                  value={this.state.email}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Change Password">
                <Button onClick={this.change_password}>
                  Change Password
              </Button>
              </Form.Item>
              {/* <Form.Item label="Cancel Subscription">
                <Button                   
                  onClick={this.showModal}
                  //onClick={this.cancel_sub}
                >
                  Cancel Sub
                </Button>
              </Form.Item> */}
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>

        {/* <Modal
          title="Cancel Subscription"
          visible={this.state.cancel_dialog_visible}
          onOk={this.cancel_sub}
          onCancel={this.handleCancel}
        >
          <p>Are you sure you are going to cancel this subscription?</p>
      </Modal> */}

        <Modal
          title="Change Password"
          visible={change_password_dialog}
          onOk={() => this.handle_change_password_ok(this.state.current_password, this.state.new_password, this.state.confirm_new_password)}
          confirmLoading={confirmLoading}
          onCancel={this.handle_change_password_cancel}
        >
          <React.Fragment>
            <p>
              Please enter your current password.
              <br />
              <br />
              You should also enter a new password (and confirm it) in the fields below.
              <br />
            </p>
            <Form layout="vertical">
              <Form.Item
                label="Current Password"
                validateStatus={this.state.current_password_state}
                help={this.state.current_password_help}
              >
                <Input.Password
                  id="current_password"
                  placeholder="Current password"
                  value={this.state.current_password}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="New Password"
                validateStatus={this.state.new_password_state}
                help={this.state.new_password_help}
              >
                <Input.Password
                  id="new_password"
                  placeholder="New password"
                  value={this.state.new_password}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Confirm New Password"
                validateStatus={this.state.confirm_new_password_state}
                help={this.state.confirm_new_password_help}
              >
                <Input.Password
                  id="confirm_new_password"
                  placeholder="Confirm new password"
                  value={this.state.confirm_new_password}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Form>
          </React.Fragment>
        </Modal>


      </React.Fragment>
    );
  }
}

// export default UserSummary;

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const UserSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSummary);

export default UserSummaryContainer;
