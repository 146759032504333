
import React, { Component } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withStyles from 'react-jss'

import { setCategorySelectId } from '../../redux/actions/workout_builder';

import { API } from 'aws-amplify';

const styles = {
  tab_pane: {
    padding: 0,
    // paddingTop: 24,
    // background: '#fff',
    // minHeight: 360,
    '@media (min-width: 800px)': {
      padding: 24,
      // paddingTop: 24,
    },
    // '@media (min-width: 1000px)': {
    //   padding: 0,
    //   paddingTop: 24,
    // },
    // '@media (min-width: 1200px)': {
    //   padding: 24,
    // },
  },
}

class CategoryListPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {dataSource: []}

    this.columns = [
      // {
      //   title: 'ID',
      //   dataIndex: 'id',
      //   render: text => <a onClick={() => this.selectCategoryId(text)}>{text.toString().padStart(5, '0')}</a>,

      // },
      {
        title: 'Select Category',
        dataIndex: 'file_by',
        width: 150,
        render: (text, record) => {
          if (text !== 'All') {
            return <React.Fragment>
              <a onClick={() => this.selectCategoryId(record.id)}>{text}</a>
            </React.Fragment>
          }
          return <React.Fragment>
            <a onClick={() => this.selectCategoryId(0)}>{text}</a>
          </React.Fragment>
          
        }
      },
      // {
      //   title: '',
      //   dataIndex: 'description',
      //   render: (text, record) => {
      //     return <React.Fragment>
      //       {text}
      //       </React.Fragment>
      //   }
      // },
      // {
      //   title: 'tag color',
      //   dataIndex: 'tag_color',
      // },
      // {
      //   title: 'Actions',
      //   key: 'action',
      //   render: (text, record) => (
      //     <span>
      //       <NavLink to={`/categories/view/${record.id}`} >View</NavLink>
      //       {/* <Divider type="vertical" />
      //       <a>Delete</a> */}
      //     </span>
      //   ),
      // },
    ];
  }

  componentDidMount() {
    API.get('workout-builder', '/exercise-categories/list')
      .then(response => {
        // console.log(response);
        // console.log(response.payload);

        const dataSource = [];
        dataSource.push({
          key: 0,
          id: 0,
          file_by: 'All',
          description: 'All Categories'
          // tag_color: response.payload[i].tag_color
        });
        for (let i = 0; i < response.payload.length; i++) {
          dataSource.push({
            key: response.payload[i].id,
            id: response.payload[i].id,
            file_by: response.payload[i].file_by,
            description: response.payload[i].description
            // tag_color: response.payload[i].tag_color
          });
        }
        // console.log(dataSource);
        this.setState({dataSource: dataSource})
      })
      .catch(error => {
        console.log(error);
      });
  }

  selectCategoryId = (id) => {
    this.setState(
      {category_id: id}
    )
    this.props.setCategorySelectId(id);
  }

  render() {
    // console.log(this.state);
    // console.log(this.dataSource);
    // console.log(this.props);
    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          dataSource={this.state.dataSource}
          showHeader={false}
          scroll={this.props.workout_builder.new_workout_builder_data.workouts.length > 4 ? {y: 1000} : {y: 500}}
          pagination={false}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
    workout_builder: state.workout_builder,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCategorySelectId: (category_id) => {
      dispatch(setCategorySelectId(category_id))
    }
  }
}

const CategoryListPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryListPageComponent);

export default withStyles(styles)(withRouter(CategoryListPageComponentContainer));
