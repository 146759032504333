import React, { Component } from 'react';
import withStyles from 'react-jss'

import { API, Auth } from "aws-amplify";

//import { Layout } from 'antd';
// import { Row, Col } from 'antd';
import { Form, Input } from 'antd';
import { Alert } from 'antd';
import { Card, Button } from 'antd';
import { notification } from 'antd';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;


const styles = {
  root: {
    padding: 0,
    paddingTop: 24, 
    background: '#fff',
    minHeight: 360,
    '@media (min-width: 800px)': {
      padding: 0,
      paddingTop: 24, 
    },
    '@media (min-width: 1000px)': {
      padding: 0,
      paddingTop: 24, 
    },
    '@media (min-width: 1200px)': {
      padding: 24,
    },
  },
  main_card: {
    width: "100%",
    '@media (min-width: 800px)': {
      width: "80%",
    },
    '@media (min-width: 1000px)': {
      width: "80%",
    },
    '@media (min-width: 1200px)': {
      width: "50%",
    },
  },
}


const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};
// const warning = (text) => {
//   message.warning(text, 10);
// };

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class RegisterPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registered: false,
      // email: "andre@pimwa.com",
      email: "",
      // password: "Surecom88",
      password: "",
      password_confirm: "",
      first_name: "",
      last_name: ""
    }
  }


  componentDidMount() {
    // API.get("staff", `/list`)
    //   .then(response => {
    //     console.log(response);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }


  handleChange = event => {
    // console.log(event);
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  validate_form = () => {
    let valid = true;
    this.setState({
      confirm_password_same_status: "success"
    })
    if (!this.state.email) {
      valid = false;
      this.setState({
        email_status: "error"
      })
    }
    if (this.state.password !== this.state.password_confirm) {
      valid = false;
      this.setState({
        confirm_password_same_status: "error"
      })
    }
    if (!this.state.password) {
      valid = false;
      this.setState({
        password_status: "error"
      })
    }
    if (!this.state.password_confirm) {
      valid = false;
      this.setState({
        password_confirm_status: "error"
      })
    }
    //this.state.first_name.length < 1
    if (!this.state.first_name) {
      this.setState({
        first_name_status: "error"
      })
      valid = false;
    }
    //this.state.last_name.length < 1
    if (!this.state.last_name) {
      this.setState({
        last_name_status: "error"
      })
      valid = false;
    }
    return valid;
  }

  handleSubmit = async event => {
    // console.log(event);
    // console.log(this.state.email)
    // console.log(this.state.password);

    if (!this.validate_form()) return;

    openNotificationWithIcon('info', "Registration", "Registration is in process")
    let email = "";
    try {
      email = this.state.email.toLowerCase();
      const newUser = await Auth.signUp({
        username: email,
        password: this.state.password
      });
      console.log(newUser);
      this.setState({
        newUser
      });

      API.post("users", `/register`, {
        body: {
          email,
          aws_cognito_id: newUser.userSub,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          xo_member_id: this.state.xo_member_id
          // client_id: uuidv4()
        }
      })

        .then(response => {
          console.log(response);
          // openNotificationWithIcon('info', <FormattedMessage id="register_page.registration_in_process_heading" />, <FormattedMessage id="register_page.registration_in_process_notification_2" />)

          API.post("email", `/interaction-open`, {
            body: {
              api_key: "92490f7c-7676-447a-8b56-91431512b3d8",
              interaction: "XOlation Web Portal: New Client Register",
              data: {
                email,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                referring_client_id: this.state.referring_client_id,
                ...response.payload.client_creation
              },
              result: "new client registered successfully"
            }
          })
            .then(response => {
              console.log(response);
              // openNotificationWithIcon('info', <FormattedMessage id="register_page.registration_in_process_heading" />, <FormattedMessage id="register_page.registration_in_process_notification_3" />)
            })
            .catch(error => {
              console.log(error);
            });
          API.post("email", `/client/new-portal-account`, {
            body: {
              api_key: "92490f7c-7676-447a-8b56-91431512b3d8",
              id: response.payload.client_creation.insertId,
              username: email,
              password: this.state.password
            }
          })
            .then(response => {
              console.log(response);
              // openNotificationWithIcon('info', <FormattedMessage id="register_page.registration_in_process_heading" />, <FormattedMessage id="register_page.registration_in_process_notification_4" />)
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);

          API.post("users", `/register`, {
            body: {
              email,
              aws_cognito_id: newUser.userSub,
              first_name: this.state.first_name,
              last_name: this.state.last_name,
              xo_member_id: this.state.xo_member_id
              // client_id: uuidv4()
            }
          })
    
            .then(response => {
              console.log(response);
              // openNotificationWithIcon('info', <FormattedMessage id="register_page.registration_in_process_heading" />, <FormattedMessage id="register_page.registration_in_process_notification_2" />)
    
              API.post("email", `/interaction-open`, {
                body: {
                  api_key: "92490f7c-7676-447a-8b56-91431512b3d8",
                  interaction: "XOlation Web Portal: New Client Register",
                  data: {
                    email,
                    first_name: this.state.first_name,
                    last_name: this.state.last_name,
                    referring_client_id: this.state.referring_client_id,
                    ...response.payload.client_creation
                  },
                  result: "new client registered successfully"
                }
              })
                .then(response => {
                  console.log(response);
                  // openNotificationWithIcon('info', <FormattedMessage id="register_page.registration_in_process_heading" />, <FormattedMessage id="register_page.registration_in_process_notification_3" />)
                })
                .catch(error => {
                  console.log(error);
                });
              API.post("email", `/client/new-portal-account`, {
                body: {
                  api_key: "92490f7c-7676-447a-8b56-91431512b3d8",
                  id: response.payload.client_creation.insertId,
                  username: email,
                  password: this.state.password
                }
              })
                .then(response => {
                  console.log(response);
                  // openNotificationWithIcon('info', <FormattedMessage id="register_page.registration_in_process_heading" />, <FormattedMessage id="register_page.registration_in_process_notification_4" />)
                })
                .catch(error => {
                  console.log(error);
                });
            })
            .catch(error => {
              console.log(error);
            });

            
        });



      openNotificationWithIcon('success', "Record Created", "New client has been created.")
      this.setState({
        registered: true
      })
      // this.props.userHasAuthenticated(true);

    } catch (e) {
      // alert(e.message);
      console.log(e);
      openNotificationWithIcon('warning', "Registration Failure", "Registration has not been successful")


    }
  }

  render() {
    //console.log(this.state);
    const { classes } = this.props;
    return (

      <div className={classes.root}>

        {/* <Row gutter={[96, 24]}>
          <Col xs={12}> */}


            <Card
              className={classes.main_card}
              actions={[
                <div style={{ float: "right", marginRight: 24 }}>
                  <Button
                    type="primary"
                    disabled={this.state.registered}
                    onClick={this.handleSubmit}
                  >Register Now</Button></div>,
              ]}
            >
              {this.state.registered &&
                <React.Fragment>
                  <Alert
                    message="Registration Successful"
                    description={<React.Fragment>Your registration is processing.<br />You will receive an email (please check spam) containing a link to verify your email address.<br /><br />Once verified <a href="/pages/login">Click Here</a> to login.</React.Fragment>}
                    type="success"
                    showIcon
                  />

                </React.Fragment>
              }

              {!this.state.registered &&
                <React.Fragment>
                  <Form
                    {...formItemLayout}
                  >
                    <Form.Item
                      label="First Name"
                      help={this.state.first_name_status === "error" ? "First Name is required" : ""}
                      validateStatus={this.state.first_name_status}
                    >
                      <Input
                        placeholder="First Name"
                        id="first_name"
                        value={this.state.first_name}
                        onChange={(event) => {
                          this.handleChange(event)
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Last Name"
                      help={this.state.last_name_status === "error" ? "Last Name is required" : ""}
                      validateStatus={this.state.last_name_status}
                    >
                      <Input
                        placeholder="Last Name"
                        id="last_name"
                        value={this.state.last_name}
                        onChange={(event) => {
                          this.handleChange(event)
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Email Address"
                      help={this.state.email_status === "error" ? "Username is required" : ""}
                      validateStatus={this.state.email_status}
                    >
                      <Input
                        placeholder="Username"
                        id="email"
                        value={this.state.email}
                        onChange={(event) => {
                          this.handleChange(event)
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Password"
                      help={
                        this.state.password_status === "error" ? "8 characters long, including at least 1 uppercase, lowercase and number" : "8 characters long, including at least 1 uppercase, lowercase and number"
                      }
                      validateStatus={
                        this.state.password_status
                      }
                    >
                      <Input.Password
                        placeholder="Enter password"
                        id="password"
                        value={this.state.password}
                        onChange={(event) => {
                          this.handleChange(event)
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Confirm Password"
                      help={
                        this.state.password_confirm_status === "error" ? "Password and Confirm password has to be the same" : ""
                      }
                      validateStatus={
                        this.state.password_confirm_status
                      }
                    >
                      <Input.Password
                        placeholder="Confirm password"
                        id="password_confirm"
                        value={this.state.password_confirm}
                        onChange={(event) => {
                          this.handleChange(event)
                        }}
                      />
                    </Form.Item>
                    {/* <Form.Item
                      label="XO Member ID">
                      <Input
                        placeholder="optional"
                        id="xo_member_id"
                        value={this.state.xo_member_id}
                        onChange={(event) => {
                          this.handleChange(event)
                        }}
                      />
                    </Form.Item> */}

                  </Form>
                </React.Fragment>
              }

            </Card>

          {/* </Col> */}

          {/* <Col xs={12}> */}

          {/* </Col> */}
        {/* </Row> */}

      </div>

    );
  }
}

export default withStyles(styles)(RegisterPageComponent);
