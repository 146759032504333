
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ReactPlayer from 'react-player';
import { get_url } from '../../../library/aws_library';

import { API } from 'aws-amplify';

import {
  Row, Col, Card, notification , Form, Input, InputNumber, Typography
} from 'antd';

const { Title } = Typography;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class ExercisePreviewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // description: "",
      // file_id,
      file_1_file: '',
      file_2_file: '',
      file_3_file: '',
      file_4_file: '',
      file_5_file: '',
      file_6_file: '',
      playing: true
      // video_1_url: "",
      // video_2_url: "",
      // video_3_url: "",
      // video_4_url: "",
      // video_5_url: "",
      // video_6_url: ""
    }
  }

  async componentDidMount() {
    // console.log(exercise)
    this.getExerciseRecord(this.props.exercise_id)
    // this.setState({
    //   ...exercise.payload
    // })
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   const user = await this.getUserProfile(
    //     nextProps.app_state.current_user_aws_cognito_id
    //   );
    //   // console.log(user)
    //   this.setState({
    //     ...user.payload
    //   })
    // }
  }

  async getExerciseRecord(id) {
    // return API.get("exercises", `/id/${id}`);
    // console.log(this.props)
    API.get('workout-builder', `/exercises/id/${id}`).then(
      async (response) => {
        // console.log(response)
        // console.log(this.state);
        if (response.payload.file_1_file) {
          const video_1_url = await get_url(response.payload.file_1_file);
          this.setState({ video_1_url })
        }
        if (response.payload.file_2_file) {
          const video_2_url = await get_url(response.payload.file_2_file);
          this.setState({ video_2_url })
        }
        if (response.payload.file_3_file) {
          const video_3_url = await get_url(response.payload.file_3_file);
          this.setState({ video_3_url })
        }
        if (response.payload.file_4_file) {
          const video_4_url = await get_url(response.payload.file_4_file);
          this.setState({ video_4_url })
        }
        if (response.payload.file_5_file) {
          const video_5_url = await get_url(response.payload.file_5_file);
          this.setState({ video_5_url })
        }
        if (response.payload.file_6_file) {
          const video_6_url = await get_url(response.payload.file_6_file);
          this.setState({ video_6_url })
        }
        // if (response.payload.file_id) {
        //   let video_url = await get_url(response.payload.file_id);
        //   this.setState({ video_url })
        // }
        // if (response.payload.poster_file_id) {
        //   let poster_url = await get_url(response.payload.poster_file_id);
        //   this.setState({ poster_url })
        // }

        this.setState({
          exercise: response.payload,
          id: response.payload.id,
          file_1_file: response.payload.file_1_file,
          file_2_file: response.payload.file_2_file,
          file_3_file: response.payload.file_3_file,
          file_4_file: response.payload.file_4_file,
          file_5_file: response.payload.file_5_file,
          file_6_file: response.payload.file_6_file,
          file_1_length: response.payload.file_1_length,
          file_2_length: response.payload.file_2_length,
          file_3_length: response.payload.file_3_length,
          file_4_length: response.payload.file_4_length,
          file_5_length: response.payload.file_5_length,
          file_6_length: response.payload.file_6_length,
          file_1_description: response.payload.file_1_description,
          file_1_gender: response.payload.file_1_gender,
          file_2_description: response.payload.file_2_description,
          file_2_gender: response.payload.file_2_gender,
          file_3_description: response.payload.file_3_description,
          file_3_gender: response.payload.file_3_gender,
          file_4_description: response.payload.file_4_description,
          file_4_gender: response.payload.file_4_gender,
          file_5_description: response.payload.file_5_description,
          file_5_gender: response.payload.file_5_gender,
          file_6_description: response.payload.file_6_description,
          file_6_gender: response.payload.file_6_gender
          // id: response.payload.id,
          // record_created: response.payload.record_created,
          // exercise_category_id: response.payload.exercise_category_id,
          // free_content: response.payload.free_content,
          // short_description: response.payload.short_description,
          // long_description: response.payload.long_description,
          // trainer_staff_ids:response.payload.trainer_staff_ids,
          // tags: response.payload.tags,
          // equipment: response.payload.equipment
        })

      }).catch(err => {
      console.log(err);
    });
  }

  handleChange = event => {
    // console.log(event)
    this.setState({[event.target.id]: event.target.value});
  };

  handleFile1NumberonChange = (value) => {
    // console.log('changed', value);
    this.setState({file_1_length: parseInt(value)});
  }

  handleFile2NumberonChange = (value) => {
    // console.log('changed', value);
    this.setState({file_2_length: parseInt(value)});
  }

  handleFile3NumberonChange = (value) => {
    // console.log('changed', value);
    this.setState({file_3_length: parseInt(value)});
  }

  handleFile4NumberonChange = (value) => {
    // console.log('changed', value);
    this.setState({file_4_length: parseInt(value)});
  }

  handleFile5NumberonChange = (value) => {
    // console.log('changed', value);
    this.setState({file_5_length: parseInt(value)});
  }

  handleFile6NumberonChange = (value) => {
    // console.log('changed', value);
    this.setState({file_6_length: parseInt(value)});
  }

  render() {
    // console.log(this.state);
    // console.log(this.props)
    if (!this.props.exercise_id) {
      return null;
    }

    return (

      <React.Fragment>
        <Row gutter={[12, 0]}>
          <Col xs={24}>
            {this.state.video_1_url && <React.Fragment>
              <Form.Item>
                {/*<p>{this.state.file_1_file}</p>*/}
                <div className='player-wrapper'>
                  <ReactPlayer url={this.state.video_1_url} //'https://tom-test-1234.s3-ap-southeast-1.amazonaws.com/Saturday+1.mp4'
                    playing = {this.props.playing}
                    width='100%'
                    height='100%'
                    controls
                  />
                </div>
              </Form.Item>
            </React.Fragment>
            }
          </Col>
        </Row>
      </React.Fragment>

    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = () => {
  return {}
}

const ExercisePreviewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExercisePreviewPageComponent);

export default withRouter(ExercisePreviewPageComponentContainer);
