// import { API } from "aws-amplify";

export const CATEGORY_SET_ID = "CATEGORY_SET_ID ";

export function setCategorySelectId(id) {
  return {
    type: CATEGORY_SET_ID,
    value: id.toString().toLowerCase()
  };
}

//WB - Workout Builder
export const WB_START_NEW_WB_CREATION = "WB_START_NEW_WB_CREATION";
export const WB_UPDATE_NEW_WB_CREATION = "WB_UPDATE_NEW_WB_CREATION";
export const WB_SET_PROCESSING = "WB_SET_PROCESSING";


export function createNewWorkoutBuilder() {
  return {
    type: WB_START_NEW_WB_CREATION
  };
}

export function updateNewWorkoutBuilder(key, value) {
  // console.log(key, value);
  return {
    type: WB_UPDATE_NEW_WB_CREATION,
    value: { key, value }
  };
}

export function setWorkoutBuilderProcessing(value) {
  // console.log(key, value);
  return {
    type: WB_SET_PROCESSING,
    value: { value }
  };
}


