
import React, { Component } from 'react';
import { connect } from "react-redux";
// import { NavLink } from "react-router-dom";

import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";
// import { Layout } from 'antd';
// import { Row, Col, Divider } from 'antd';
// import { Card } from 'antd';
// import { Button } from 'antd';
// import { notification } from 'antd';
// import { Form, Input } from 'antd';
// import { Table } from 'antd';

// import { Tag } from 'antd';
// import Highlighter from 'react-highlight-words';
// import { SearchOutlined } from '@ant-design/icons';

// import { get_url } from "../../library/aws_library";
// import { QuestionCircleOutlined } from "@ant-design/icons";

import { Area } from '@ant-design/charts';


var dayjs = require('dayjs')

class UserStatsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }

  }

  async componentDidMount() {
    API.get("videos", `/user/${this.props.app_state.current_user_id}`)
      .then(async response => {
        let data = []
        for (let i = 60; i >= 0; i--) {
          let view_date = dayjs().startOf('day').subtract(i, 'day')
          let view_count = 0;
          for (let j = 0; j < response.payload.length; j++) {
            let record = response.payload[j];
            if (record.record_datetime) {
              if (dayjs(record.record_datetime).isSame(view_date, 'day')) {
                view_count++;
              } 
            } else {
              if (dayjs(record.record_date).isSame(view_date, 'day')) {
                view_count++;
              } 
            }
          }
          data.push({
            day: view_date.format("DD-MM-YYYY"),
            views: view_count
          })
        }
        this.setState({
          data
        })
      })
      .catch((err) => {
        console.log(err);
      })
  }


  render() {
    const config = {
      data: this.state.data,
      title: {
        visible: true,
        text: 'Your Stats',
      },
      xField: 'day',
      yField: 'views',
      color: ['#c30f48'],
      xAxis: {
        type: 'dateTime',
        // tickCount: 60,
      },
      meta: {
        day: {
          alias: " "
        }
      }
    };
    return (
      <React.Fragment>
        <Area {...config} />
      </React.Fragment>
    );
  }
}

// export default UserSummary;

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const UserStatsComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserStatsComponent);

export default UserStatsComponentContainer;
