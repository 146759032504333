
import React, { Component } from 'react';
import { connect } from "react-redux";

// import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";
// import { Layout } from 'antd';
// import { Row, Col } from 'antd';
// import { Card } from 'antd';
// import { Button } from 'antd';
// import { notification } from 'antd';
// import { Form, Input } from 'antd';
import Subscription_NewContainer from './Subscription_New';
import Subscription_ViewContainer from './Subscription_View';

// const openNotificationWithIcon = (type, header, message) => {
//   notification[type]({
//     message: header,
//     description: message,
//   });
// };

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  async componentDidMount() {
    // const user = await this.getUserProfile(
    //   this.props.app_state.current_user_id
    // );
    // console.log(user)
    // this.setState({
    //   ...user.payload
    // })
  }

  // async componentWillReceiveProps(nextProps, nextContext) {
  //   // console.log(nextProps)
  //   if (nextProps.app_state.current_user_aws_cognito_id && nextProps.app_state.current_user_aws_cognito_id) {
  //     const user = await this.getUserProfile(
  //       nextProps.app_state.current_user_aws_cognito_id
  //     );
  //     console.log(user)
  //     this.setState({
  //       ...user.payload
  //     })
  //   }
  // }


  // async getUserProfile(current_user_id) {
  //   return API.get("users", `/get/id/${current_user_id}`);
  // }




  render() {
    // console.log(this.state);

    // console.log(this.props.app_state.current_client_record.current_subscription_id)
    let subscription_view;
    if (this.props.app_state.current_client_record.current_subscription_id) {
      subscription_view = <Subscription_ViewContainer />
    } else {
      subscription_view = <Subscription_NewContainer />
    }


    return (

      <React.Fragment>
          {subscription_view}
      </React.Fragment>
    );
  }
}

// export default UserSummary;

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
}

const SubscriptionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscription);

export default SubscriptionContainer;
