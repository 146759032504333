import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from 'react-jss';
import ReactPlayer from 'react-player';
import { mod } from 'mathjs';

import {
  Table, Typography, Tag, Skeleton, Row, Col, Form, Spin
} from 'antd';

import { API, Storage } from 'aws-amplify';

const { Title } = Typography;

const styles = {
  video_description: {
    padding: 0,
    // paddingTop: 24,
    // background: '#fff',
    // minHeight: 360,
    '@media (min-width: 800px)': {
      padding: 24,
      // paddingTop: 24,
    },
    // '@media (min-width: 1000px)': {
    //   padding: 0,
    //   paddingTop: 24,
    // },
    // '@media (min-width: 1200px)': {
    //   padding: 24,
    // },

    'my-active-row': {background_color: '#ffffff',},
    table_row_dark: {background_color: '#fbfbfb'},
  },
};

// const openNotificationWithIcon = (type, header, message) => {
//   notification[type]({
//     message: header,
//     description: message,
//   });
// };

// var moment = require("moment");
class WorkoutBuilderViewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video_urls: [],
      currentExerciseIndex: 0,
      video: {
        id: '',
        record_created: '',
        // file_id: "",
        exercise_id: '',
        short_description: '',
      },
      hasAdminWorkoubuilderSetupResult: 0
    };

    this.columns = [
      {
        title: 'Exercise',
        dataIndex: 'short_description',
        render: (text, record, index) => {
          // console.log(record)
          return {
            props: {
              style: {
                verticalAlign: 'top',
                background: this.state.hasAdminWorkoubuilderSetupResult ?
                  index === this.state.currentExerciseIndex - 1 ? '#eee' : '#fff'
                  : index === this.state.currentExerciseIndex ? '#eee' : '#fff'
              },
            },
            children: (
              <React.Fragment>
                <div>
                  {text}
                  <br />
                </div>
              </React.Fragment>
            ),
          };
        },
      },
      //#188 Hide gender tags
      // {
      //   title: 'Gender',
      //   dataIndex: 'gender',
      //   render: (text, record, index) => {
      //     // console.log(record)
      //     return {
      //       props: {
      //         style: {
      //           verticalAlign: 'top',
      //           background: this.state.hasAdminWorkoubuilderSetupResult ?
      //             index === this.state.currentExerciseIndex - 1 ? '#eee' : '#fff'
      //             : index === this.state.currentExerciseIndex ? '#eee' : '#fff'
      //         },
      //       },
      //       children: (
      //         <React.Fragment>
      //           <div>
      //             {
      //               record.gender === 1
      //                 ? <Tag color="blue" key="male"> Male </Tag>
      //                 : record.gender === 2 ? <Tag color="magenta" key="female"> Female </Tag>
      //                   : ''

      //             }
      //             <br />
      //           </div>
      //         </React.Fragment>
      //       ),
      //     };
      //   },
      // },
      {
        title: 'Length',
        dataIndex: 'length',
        render: (text, record, index) => {
          console.log()
          return {
            props: {
              style: {
                verticalAlign: 'top',
                background: this.state.hasAdminWorkoubuilderSetupResult ?
                  index === this.state.currentExerciseIndex - 1 ? '#eee' : '#fff'
                  : index === this.state.currentExerciseIndex ? '#eee' : '#fff'
              },
            },
            children: (
              record.length ? <React.Fragment>{Math.floor(record.length / 60)}{':'}{(mod(record.length, 60) == 0) ? '00' : mod(record.length, 60)}</React.Fragment> : ''
            ),
          };
        },
        
      },
    ];
  }

  async componentDidMount() {
   
    await API.get('workout-builder', '/workouts/admin-workoutbuilder-setup').then(response => {
      const admin_workoutbuilder_setup_result = response
      if (response.payload[0].preWorkoutVideo && response.payload[0].postWorkoutVideo) {
        this.setState({
          admin_workoutbuilder_setup_result: admin_workoutbuilder_setup_result,
          hasAdminWorkoubuilderSetupResult: 1
        })
      }
    }
    ).catch((err) => {
      console.log(err);
      this.setState({hasAdminWorkoubuilderSetupResult: 0})
    });
    await API.get('workout-builder', `/workouts/id/${this.props.workout_id}`)
      .then(async (response) => {
        // console.log(response);
        // console.log(this.state.admin_workoutbuilder_setup_result.payload[0])
        const posterDataObj = JSON.parse(response.payload.exercise_details);
        // console.log(posterDataObj);
        const video_list = [];
        if (this.state.hasAdminWorkoubuilderSetupResult === 1){
          video_list.push(
            await Storage.get(this.state.admin_workoutbuilder_setup_result.payload[0].preWorkoutVideo, {expires: 6000,})
          );
        }
        for (let i = 0; i < posterDataObj.length; i++) {
          // let file_url;
          // if (posterDataObj[i].poster_file_id) {
          //   file_url = await Storage.get(posterDataObj[i].poster_file_id, {
          //     expires: 600,
          //   });
          // }

          // file_list.push({
          //   poster_file_id: posterDataObj[i].poster_file_id,
          //   option: posterDataObj[i].option,
          //   file_url,
          // });
          // console.log(file_list)

          await API.get(
            'workout-builder',
            `/exercises/by-option/${posterDataObj[i].option}/${posterDataObj[i].id}`
          ).then(async (response) => {
            // console.log(response.payload);
            // console.log(posterDataObj[i].option);
            // let video_list = [];

            switch (posterDataObj[i].option) {
              case 1:
                video_list.push(
                  await Storage.get(response.payload.file_1_file, {expires: 6000,})
                );
                break;
              case 2:
                video_list.push(
                  await Storage.get(response.payload.file_2_file, {expires: 6000,})
                );
                break;
              case 3:
                video_list.push(
                  await Storage.get(response.payload.file_3_file, {expires: 6000,})
                );
                break;

              case 4:
                video_list.push(
                  await Storage.get(response.payload.file_4_file, {expires: 6000,})
                );
                break;

              case 5:
                video_list.push(
                  await Storage.get(response.payload.file_5_file, {expires: 6000,})
                );
                break;

              case 6:
                video_list.push(
                  await Storage.get(response.payload.file_6_file, {expires: 6000,})
                );
                break;
              default:
                break;
            }

            // console.log(video_list);
          });
        }
        if (this.state.hasAdminWorkoubuilderSetupResult === 1){
          video_list.push(
            await Storage.get(this.state.admin_workoutbuilder_setup_result.payload[0].postWorkoutVideo, {expires: 6000,})
          );
        }
        this.setState({video_urls: video_list,});

        // console.log(posterDataObj);
        this.setState({
          file_by: response.payload.file_by,
          exercise_details: posterDataObj,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async componentWillReceiveProps(nextProps, nextContext) {}

  video_on_start = (e) => {};

  video_on_progress = (e) => {};

  video_on_ended = (e) => {
    console.log('Finished video ' + this.state.currentExerciseIndex);

    if (this.state.hasAdminWorkoubuilderSetupResult === 1){
      if (this.state.currentExerciseIndex - 1 !== this.state.exercise_details.length){
        this.setState({currentExerciseIndex: this.state.currentExerciseIndex + 1,});
      }
    } else {
      if (this.state.currentExerciseIndex + 1 !== this.state.exercise_details.length){
        this.setState({currentExerciseIndex: this.state.currentExerciseIndex + 1,});
      }
    }
  };

  // select the row
  // onClickRow = (record) => {
  //   return {
  //     onClick: () => {
  //       this.setState({
  //         rowId: record.id,
  //       });
  //     },
  //   };
  // }
  // setRowClassName = (record) => {
  //   return record.id === this.state.rowId ? 'clickRowStyl' : '';
  // }

  render() {
    const { classes } = this.props;
    console.log(this.state);
    // console.log(this.props);
    if (!this.state.file_by) {
      return <React.Fragment>
                <div style={{textAlign: 'center'}}>
                  <Title level={2}>Workout Loading</Title>
                  <Title level={3}>get ready to give it a crack</Title>
                  <Spin size="large" />
              </div>
      </React.Fragment>
    }

    // const data = this.state.exercise_details.slice(
    //   0,
    //   this.state.exercise_details.length
    // );

    if (this.state.video_urls.length < 1) {
      return null;
    }
    console.log('currently playing:', this.state.currentExerciseIndex);

    // var dataObj = JSON.parse(data);
    return (
      <React.Fragment>
        <div className={classes.root}>
          {/*<p>{data}</p>*/}
          <Row gutter={[16, 0]}>
            <Col xs={8}>
              <Form layout="vertical">
                <Title level={4}> {this.state.file_by || ''} </Title>
                <Table
                  columns={this.columns}
                  dataSource={this.state.exercise_details}
                  rowClassName='my-active-row'
                  pagination={
                    false
                    // {
                    //    pageSizeOptions: ["10", "20", "30"],
                    //    showSizeChanger: true,
                    // }
                  }
                  scroll={{ y: 500 }}
                />
              </Form>
            </Col>
            <Col xs={8}>
              <div className="player-wrapper">
                <ReactPlayer
                  url={[this.state.video_urls[this.state.currentExerciseIndex]]}
                  playing
                  style={{
                    'position': 'relative',
                  }}
                  light={this.state.poster_url}

                  width="200%"
                  height="200%"
                  controls
                  onStart={this.video_on_start}
                  onProgress={this.video_on_progress}
                  onEnded={() => {
                    console.log('Index + 1');
                    this.video_on_ended();
                  }}
                  config={{file: {attributes: {controlsList: 'nodownload',},},}}
                />
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  };
};

const WorkoutBuilderViewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkoutBuilderViewPageComponent);

export default withStyles(styles)(WorkoutBuilderViewPageComponentContainer);
