import React from 'react';
import { connect } from "react-redux";
import withStyles from 'react-jss'
import { API } from "aws-amplify";

import { NavLink } from "react-router-dom";
import { Switch, Redirect } from "react-router-dom";
// import PropTypes from "prop-types";

// import { API, Auth } from "aws-amplify";
import { Layout, Menu, Icon } from 'antd';
import { Alert } from 'antd';

// import { Button } from 'antd';

import dashboardRoutes from "../routes/dashboard.jsx";
import AuthenticatedRoute from "../components/routes/AuthenticatedRoute";
import DashboardHeaderContainer from '../components/layout/dashboard/DashboardHeader.jsx';

import { DashboardOutlined, VideoCameraOutlined } from '@ant-design/icons'

// import logo from '../assets/img/branding/fitnessxo-logo.png';
// const logo = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAAEElEQVR42mNkYPhfz0BFAAB0hwGAwLqecAAAAABJRU5ErkJggg=="

// const { Header, Content, Footer, Sider } = Layout;
const {
  Header,
  Content,
  Footer
} = Layout;

// const { SubMenu } = Menu;

const styles = {

  menu_icon: {
    color: "white",
    fontWeight: 400,
    fontSize: "1.0em",
    '@media (max-width: 350px)': {
      fontWeight: 800,
      fontSize: "1.2em",
    }
  },
  menu_text: {
    color: "white",
    fontWeight: 400,
    fontSize: "1.0em",
    '@media (max-width: 350px)': {
      fontWeight: 800,
      fontSize: "1.2em",
    }
  },
  alert_message: {
    fontSize: window.screen.width < 400 ? "0.8em" : "1.0em",
  },
  alert_text: {
    fontSize: window.screen.width < 400 ? "0.8em" : "1.0em",
  }
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      isAuthenticated: false,
      categories_main_list: []
    };
    // this.resizeFunction = this.resizeFunction.bind(this);
  }

  async componentDidMount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.refs.mainPanel, {
    //     suppressScrollX: true,
    //     suppressScrollY: false
    //   });
    //   document.body.style.overflow = "hidden";
    // }
    // window.addEventListener("resize", this.resizeFunction);
    await API.get("categories", `/main/list`)
      .then(async (response) => {
        // console.log(response)
        this.setState({
          categories_main_list: response.payload,
        })
      });
  }

  // componentWillUnmount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy();
  //   }
  //   window.removeEventListener("resize", this.resizeFunction);
  // }
  // componentDidUpdate(e) {
  //   if (e.history.location.pathname !== e.location.pathname) {
  //     this.refs.mainPanel.scrollTop = 0;
  //     if (this.state.mobileOpen) {
  //       this.setState({ mobileOpen: false });
  //     }
  //   }
  // }
  // handleDrawerToggle = () => {
  //   this.setState({ mobileOpen: !this.state.mobileOpen });
  // };
  // getRoute() {
  //   return this.props.location.pathname !== "/maps/full-screen-maps";
  // }
  // sidebarMinimize() {
  //   this.setState({ miniActive: !this.state.miniActive });
  // }
  // resizeFunction() {
  //   if (window.innerWidth >= 960) {
  //     this.setState({ mobileOpen: false });
  //   }
  // }

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };


  switchRoutes() {
    // console.log(this.props)
    return (
      <Switch>
        {dashboardRoutes.map((prop, key) => {
          if (prop.redirect)
            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
          if (prop.collapse)
            return prop.views.map((prop, key) => {
              return (
                <AuthenticatedRoute
                  path={prop.path}
                  component={prop.component}
                  key={key}
                  props={{
                    isAuthenticated: this.props.isAuthenticated,
                    current_user: this.props.current_user,
                    reload_user: this.props.reload_user
                  }}
                />
              );
            });
          return (
            <AuthenticatedRoute
              path={prop.path}
              component={prop.component}
              key={key}
              props={{
                isAuthenticated: this.props.isAuthenticated,
                current_user: this.props.current_user,
                reload_user: this.props.reload_user
              }}
            />
          );
        })}
      </Switch>
    );
  }

  // generate_sidebar = () => {
  //   return <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" style={{ height: "100%" }}>
  //     <Menu.Item key="1">
  //       <NavLink to="/" >
  //         <Icon type="user" />
  //         <span>Dashboard</span>
  //       </NavLink>
  //     </Menu.Item>
  //     <Menu.Item key="2">
  //       <NavLink to="/page2" >
  //         <Icon type="pie-chart" />
  //         <span>Page 2</span>
  //       </NavLink>
  //     </Menu.Item>
  //     <Menu.Item key="9">
  //       <NavLink to="/my-account" >
  //         <Icon type="user" />
  //         <span>My Account</span>
  //       </NavLink>
  //     </Menu.Item>
  //   </Menu>
  // }

  generate_top_menu = () => {
    const { classes } = this.props;

    let menu_items = this.state.categories_main_list.map((item) => {
      return (<Menu.Item key={item.url_slug}>
        <NavLink to={`/video/category/${item.url_slug}`}>
          {/* <VideoCameraOutlined className={classes.menu_icon} /> */}
          <span className={classes.menu_text}>{item.file_by}</span>
        </NavLink>
      </Menu.Item>
      )
    });


    return <Menu
      theme="dark"
      mode="horizontal"
      // inlineCollapsed={true}
      defaultSelectedKeys={['1']}
    // style={{width: "100%"}}
    >
      <Menu.Item key="dashboard">
        <NavLink to="/" >
          <DashboardOutlined className={classes.menu_icon} />
          <span className={classes.menu_text}>Dashboard</span>
        </NavLink>
      </Menu.Item>
      {menu_items}
      <Menu.Item key="favourites">
        <NavLink to="/favourites" >
          {/* <DashboardOutlined className={classes.menu_icon} /> */}
          <span className={classes.menu_text}>Favourites</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="history">
        <NavLink to="/history" >
          {/* <DashboardOutlined className={classes.menu_icon} /> */}
          <span className={classes.menu_text}>My History</span>
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item key="daily-hiits">
        <NavLink to="/daily-hiits/list" >
          <VideoCameraOutlined className={classes.menu_icon} />
          <span className={classes.menu_text}>Daily HIITS</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="quick-hiits">
        <NavLink to="/quick-hiits/list" >
          <VideoCameraOutlined className={classes.menu_icon} />
          <span className={classes.menu_text}>Quick HIITs</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="wellness">
        <NavLink to="/wellness/list" >
          <VideoCameraOutlined className={classes.menu_icon} />
          <span className={classes.menu_text}>Wellness</span>
        </NavLink>
      </Menu.Item> */}
      <Menu.Item key="my_account">
        <NavLink to="/my-account" >
          <Icon type="user" className={classes.menu_icon} />
          <span className={classes.menu_text}>My Account</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="workout_builder">
        <NavLink to="/workout-builder/list" >
          <Icon type="user" className={classes.menu_icon} />
          <span className={classes.menu_text}>Workout Builder</span>
        </NavLink>
      </Menu.Item>
    </Menu>
  }

  render() {
    const { classes } = this.props;
    // console.log(this.props);
    // if (!this.props.isAuthenticated) return null;
    // if (!this.props.app_state.current_user_id) return null;
    // const { classes, ...rest } = this.props;
    // console.log(this.props);
    // const mainPanel =
    //   classes.mainPanel +
    //   " " +
    //   cx({
    //     [classes.mainPanelSidebarMini]: this.state.miniActive,
    //     [classes.mainPanelWithPerfectScrollbar]:
    //       navigator.platform.indexOf("Win") > -1
    //   });
    //   // console.log(this.props);

    // if (!this.props.app_state.current_client_record) return null;
    // if (!this.props.app_state.current_user_id) return null;


    let no_subscription_alert;
    if (
      ((this.props.app_state.current_client_record?.status_id === 2) ||
        (!this.props.app_state.current_client_record?.current_subscription_id)) && !(this.props.app_state.current_client_record?.vip)) {
      no_subscription_alert = <Alert
        message={<div className={classes.alert_message}>You are currently operating in FreeView Mode</div>}
        description={<div className={classes.alert_text}>To get the full <strong>XOlation</strong> experience, please <a href="/my-account?tab=subscription">Click Here</a> to purchase a subscription.</div>}
        type="error"
        showIcon
      // style={{backgroundColor: "#c30f48", borderColor: "#c30f48"}}
      />
    }

    return (
      <Layout style={{ minHeight: '100vh' }}>
        {/* <Sider style={{ minHeight: '100vh' }} collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
          <div className="logo" />
          <img src={logo} alt="" style={{ width: "99%", paddingBottom: 10 }} />
          {this.generate_sidebar()}
        </Sider> */}
        <Layout>
          <Header style={{ background: '#fff', padding: 0 }}>
            <DashboardHeaderContainer
              isAuthenticated={this.props.isAuthenticated}
              userHasAuthenticated={this.props.userHasAuthenticated}
              handleLogout={this.props.handleLogout}
            />
          </Header>
          <Layout>
            <Content style={{ margin: '0' }}>
              {no_subscription_alert}
              {this.generate_top_menu()}
              <div style={{ padding: 0, background: '#fff', minHeight: 360 }}>
                <div >{this.switchRoutes()}</div>
              </div>
            </Content>
          </Layout>
          <Footer style={{ textAlign: 'center' }}><strong>XOlation </strong>Client Portal <small>©2020 Fitness XO</small><br /><small>Development by <a href="https://pimwatech.com" target="_blank">Pimwa Technologies</a></small></Footer>
        </Layout>
      </Layout>

      // <div className={classes.wrapper} style={{backgroundImage:`url(${background_image})`}}>
      //   <SidebarContainer
      //     routes={dashboardRoutes}
      //     logoText={"Forex Worldwide"}
      //     logo={logo}
      //     image={image}
      //     handleDrawerToggle={this.handleDrawerToggle}
      //     open={this.state.mobileOpen}
      //     color="blue"
      //     bgColor="black"
      //     miniActive={this.state.miniActive}
      //     {...rest}
      //   />
      //   <div className={mainPanel} ref="mainPanel">
      //     <HeaderContainer
      //       sidebarMinimize={this.sidebarMinimize.bind(this)}
      //       miniActive={this.state.miniActive}
      //       routes={dashboardRoutes}
      //       handleDrawerToggle={this.handleDrawerToggle}
      //       {...rest}
      //     />
      //     {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
      //     {this.getRoute() ? (
      //       <div className={classes.content}>
      //         <div className={classes.container}>{this.switchRoutes()}</div>
      //       </div>
      //     ) : (
      //       <div className={classes.map}>{this.switchRoutes()}</div>
      //     )}
      //     {this.getRoute() ? <Footer fluid /> : null}
      //   </div>
      // </div>
    );
  }
}

// Dashboard.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default Dashboard;


const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default withStyles(styles)(DashboardContainer);