import React from 'react';
import withStyles from 'react-jss'

import { NavLink } from "react-router-dom";
import { Switch, Redirect } from "react-router-dom";
// import PropTypes from "prop-types";

// import { API, Auth } from "aws-amplify";
import { Layout, Menu, Icon } from 'antd';
// import { Input } from 'antd';
// import { Button } from 'antd';

import pagesRoutes from "../routes/pages.jsx";
// import AuthenticatedRoute from "../components/routes/AuthenticatedRoute";
import UnauthenticatedRoute from "../components/routes/UnauthenticatedRoute";
import PagesHeaderContainer from '../components/layout/pages/PagesHeader.jsx';

// import logo from '../assets/img/branding/fitnessxo-logo.png';
// const logo = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAAEElEQVR42mNkYPhfz0BFAAB0hwGAwLqecAAAAABJRU5ErkJggg=="

// const { Header, Content, Footer, Sider } = Layout;
const { 
  Content, 
  Footer, 
  Sider } = Layout;
// const { SubMenu } = Menu;

const styles = {

  main_content: {
    // backgroundColor: "red",
    padding: 24,
    minHeight: 360,
    '@media (max-width: 420px)': {
      padding: 0,
      // paddingRight: 0,
      // backgroundColor: "red"
    }
  },
}



class Pages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      isAuthenticated: false
    };
    // this.resizeFunction = this.resizeFunction.bind(this);
  }
  // componentDidMount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(this.refs.mainPanel, {
  //       suppressScrollX: true,
  //       suppressScrollY: false
  //     });
  //     document.body.style.overflow = "hidden";
  //   }
  //   window.addEventListener("resize", this.resizeFunction);
  // }
  // componentWillUnmount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy();
  //   }
  //   window.removeEventListener("resize", this.resizeFunction);
  // }
  // componentDidUpdate(e) {
  //   if (e.history.location.pathname !== e.location.pathname) {
  //     this.refs.mainPanel.scrollTop = 0;
  //     if (this.state.mobileOpen) {
  //       this.setState({ mobileOpen: false });
  //     }
  //   }
  // }
  // handleDrawerToggle = () => {
  //   this.setState({ mobileOpen: !this.state.mobileOpen });
  // };
  // getRoute() {
  //   return this.props.location.pathname !== "/maps/full-screen-maps";
  // }
  // sidebarMinimize() {
  //   this.setState({ miniActive: !this.state.miniActive });
  // }
  // resizeFunction() {
  //   if (window.innerWidth >= 960) {
  //     this.setState({ mobileOpen: false });
  //   }
  // }

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  switchRoutes() {
    return (
      <Switch>
        {pagesRoutes.map((prop, key) => {
          if (prop.collapse) {
            return null;
          }
          if (prop.redirect) {
            return (
              <Redirect from={prop.path} to={prop.pathTo} key={key} />
            );
          }
          return (
            <UnauthenticatedRoute
              path={prop.path}
              component={prop.component}
              key={key}
              props={{
                isAuthenticated: this.props.isAuthenticated,
                userHasAuthenticated: this.props.userHasAuthenticated,
                set_current_user: this.props.set_current_user
              }}
            />
          );
        })}
      </Switch>
    );
  }

  generate_sidebar = () => {
    return <Menu
      theme="dark"
      defaultSelectedKeys={['1']}
      mode="inline"
      style={{ height: "100%" }}>
      <Menu.Item key="1" style={{ marginTop: 0 }}>
        <NavLink to="/pages/login" >
          <Icon type="user" />
          <span>Login</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to="/pages/register" >
          <Icon type="user" />
          <span>Register</span>
        </NavLink>
      </Menu.Item>
    </Menu>
  }

  render() {
    // const { classes, ...rest } = this.props;
    const { classes } = this.props;
    // console.log(this.props);
    // const mainPanel =
    //   classes.mainPanel +
    //   " " +
    //   cx({
    //     [classes.mainPanelSidebarMini]: this.state.miniActive,
    //     [classes.mainPanelWithPerfectScrollbar]:
    //       navigator.platform.indexOf("Win") > -1
    //   });
    //   // console.log(this.props);
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Layout>
          {/* <Header style={{ background: '#001529', padding: 0 }}> */}

          <PagesHeaderContainer
            isAuthenticated={this.props.isAuthenticated}
            userHasAuthenticated={this.props.userHasAuthenticated}
            handleLogout={this.props.handleLogout}
          />
          {/* </Header> */}
          <Layout>
            <Sider
              style={{ minHeight: '100vh' }}
              breakpoint="md"
              collapsedWidth="0"
              collapsible
              collapsed={this.state.collapsed}
              onCollapse={this.onCollapse}
            >
              <div className="logo" />

              {this.generate_sidebar()}
            </Sider>

            <Content style={{ margin: '16px' }}>
              <div
                className={classes.main_content}
              >
                {/* <DatePickerSample/> */}

                <Switch>
                  {pagesRoutes.map((prop, key) => {
                    if (prop.collapse) {
                      return null;
                    }
                    if (prop.redirect) {
                      return (
                        <Redirect from={prop.path} to={prop.pathTo} key={key} />
                      );
                    }
                    return (
                      <UnauthenticatedRoute
                        path={prop.path}
                        component={prop.component}
                        key={key}
                        props={{
                          isAuthenticated: this.props.isAuthenticated,
                          userHasAuthenticated: this.props.userHasAuthenticated,
                          set_current_user: this.props.set_current_user
                        }}
                      />
                    );
                  })}
                </Switch>
              </div>
            </Content>
          </Layout>
          <Footer style={{ textAlign: 'center' }}><strong>XOlation </strong>Client Portal <small>©2020 Fitness XO</small><br/><small>Development by <a href="https://pimwatech.com" target="_blank">Pimwa Technologies</a></small></Footer>
        </Layout>
      </Layout>

      // <div className={classes.wrapper} style={{backgroundImage:`url(${background_image})`}}>
      //   <SidebarContainer
      //     routes={dashboardRoutes}
      //     logoText={"Forex Worldwide"}
      //     logo={logo}
      //     image={image}
      //     handleDrawerToggle={this.handleDrawerToggle}
      //     open={this.state.mobileOpen}
      //     color="blue"
      //     bgColor="black"
      //     miniActive={this.state.miniActive}
      //     {...rest}
      //   />
      //   <div className={mainPanel} ref="mainPanel">
      //     <HeaderContainer
      //       sidebarMinimize={this.sidebarMinimize.bind(this)}
      //       miniActive={this.state.miniActive}
      //       routes={dashboardRoutes}
      //       handleDrawerToggle={this.handleDrawerToggle}
      //       {...rest}
      //     />
      //     {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
      //     {this.getRoute() ? (
      //       <div className={classes.content}>
      //         <div className={classes.container}>{this.switchRoutes()}</div>
      //       </div>
      //     ) : (
      //       <div className={classes.map}>{this.switchRoutes()}</div>
      //     )}
      //     {this.getRoute() ? <Footer fluid /> : null}
      //   </div>
      // </div>
    );
  }
}

// Pages.propTypes = {
//   classes: PropTypes.object.isRequired
// };

export default withStyles(styles)(Pages);
