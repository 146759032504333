
import React, { Component } from 'react';
import { connect } from "react-redux";
// import { NavLink } from "react-router-dom";

import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";
// import { Layout } from 'antd';
// import { Row, Col, Divider } from 'antd';
// import { Card } from 'antd';
// import { Button } from 'antd';
// import { notification } from 'antd';
// import { Form, Input } from 'antd';
// import { Table } from 'antd';

// import { Tag } from 'antd';
// import Highlighter from 'react-highlight-words';
// import { SearchOutlined } from '@ant-design/icons';

// import { get_url } from "../../library/aws_library";
// import { QuestionCircleOutlined } from "@ant-design/icons";

// import { Bar } from '@ant-design/charts';
import { StackedBar } from '@ant-design/charts';


var moment = require("moment");

// const openNotificationWithIcon = (type, header, message) => {
//   notification[type]({
//     message: header,
//     description: message,
//   });
// };

class ChallengeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }

  }

  async componentDidMount() {

    //== Debug message #108 - printing out the Old API results, compared to check the total counts are the same

    // API.get("videos", `/challenge/${this.props.app_state.current_user_id}`)
    // .then(async response => {
    //   console.log(response.payload.global_results);
    //   let dataSource1 = []

    //   for (let i = 0; i < response.payload.global_results.length; i++) {
    //     dataSource1.push({
    //       key: response.payload.global_results[i].client_id,
    //       id: response.payload.global_results[i].client_id,
    //       finish: response.payload.global_results[i].finish,
    //       first_name: response.payload.global_results[i].first_name + ", " + response.payload.global_results[i].last_name.slice(0, 1) + ".",
    //       // client_name:
    //       //   response.payload.stacked_results[i].client_name.split(' ')[0] + ", " + response.payload.stacked_results[i].client_name.split(' ')[1].slice(0, 1) + ".",
    //       category: (response.payload.global_results[i].category === 1)
    //         ? "Daily HIIT" : (response.payload.global_results[i].category === 2)
    //           ? "Quick HIIT" : (response.payload.global_results[i].category === 3)
    //             ? "Wellness" : "Others",
    //       record_date: response.payload.global_results[i].record_date,
    //       start: response.payload.global_results[i].start,
    //       views: response.payload.global_results[i].views
    //     });
    //   }
    //   //== #108 Debug message order the datasource by total views
    //   console.log(dataSource1);
    // })

    API.get("videos", `/challenge2/${this.props.app_state.current_user_id}`)
    // API.get("videos", `/challenge2/72`)
      .then(async response => {
        console.log(response);

        // console.log(response.payload.global_results);
        // console.log(response.payload.stacked_results);
        let dataSource = []

        for (let i = 0 ; i < response.payload.stacked_results.length ; i ++){
          dataSource.push({
            // key: i,
            // id: i,
            client_name: 
              response.payload.stacked_results[i].client_name.split(' ')[0]+", "+response.payload.stacked_results[i].client_name.split(' ')[1].slice(0, 1) +".",
            category: (response.payload.stacked_results[i].category === 1)
              ? "Daily HIIT" : (response.payload.stacked_results[i].category === 2)
              ? "Quick HIIT": (response.payload.stacked_results[i].category === 3)
              ? "Wellness": (response.payload.stacked_results[i].category === 4)
              ? "Challenge": "Others",
            views: response.payload.stacked_results[i].views
          });
        }
        await dataSource.sort((a, b) => b.category.localeCompare(a.category))
        // console.log(dataSource)
        this.setState({
          data: dataSource //response.payload.stacked_results,
        })
      })
      .catch((err) => {
        console.log(err);
      })
  }


  render() {
    // console.log(this.state.data)

    const config = {
      forceFit: true,
      title: {
        visible: false,
        text: `Leaderboard (from ${moment("2021-10-01").format("DD-MM-YYYY")})`
      },
      description: {
        visible: false
      },
      data: this.state.data,
      yField: 'client_name',
      xField: 'views',
      stackField: 'category',
      color: ['#C30F48','#0FC28A','#0F48C2','#0a4802'],
      label: {
        offset: 0,
        visible: true,
        position: 'middle',
      },
    };
    return (
      <React.Fragment>
        <h3>Results for 01 October to 31 October ...</h3>
        {(this.state.data.length < 1) &&
          <h4>Data for first day processing... Check back soon...</h4>
        }
        {/*(this.state.data.length > 0) &&
          <Bar {...config} />
        */}
        {(this.state.data.length > 0) &&
          <StackedBar {...config} />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
}

const ChallengeComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeComponent);

export default ChallengeComponentContainer;
