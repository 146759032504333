import React, { Component } from 'react';
import { connect } from "react-redux";
import withStyles from 'react-jss'


// import { API, Auth } from "aws-amplify";
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Card } from 'antd';
// import { PageHeader } from 'antd';
import { Row, Col } from 'antd';
import { Skeleton } from 'antd';
import { Typography } from 'antd';
// import { Alert } from 'antd';
import HistoryListPageComponentContainer from '../../../components/pages/dashboard/history/HistoryListPageComponent';

// const background_image = require("../../../assets/img/blonde_lady_with_towel.jpg");
const background_image = require("../../../assets/img/categories/XO_Trainers_FIT_LOWRES_426_blend_700x400.jpg");

const { Title } = Typography;

const styles = {
  root: {
    backgroundImage: `url(${background_image})`,
    height: "100%",
    minHeight: 360,
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    backgroundColor: "#111",
    marginBottom: 40,
    '@media (max-width: 800px)': {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${background_image})`,
    },
    '@media (max-width: 500px)': {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${background_image})`,
      height: "100vh",
      backgroundPosition: "center",
      backgroundSize: "cover",
      marginBottom: 0,
    }
  },
  menu_text: {
    color: "white",
    fontWeight: 400,
    fontSize: "1.0em",
    '@media (max-width: 500px)': {
      fontWeight: 800,
      fontSize: "1.2em",
    }
  },
  hero_text: {
    fontSize: "1.4em", 
    fontWeight: 600, 
    textAlign: "justify", 
    color: "white",
    '@media (max-width: 500px)': {
      fontWeight: 600,
      fontSize: "1.2em",
    }
  },
}

class HistoryListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  handle_show_all = () => {
    this.props.history.push(`/daily-hiits/list`);
  }
  render() {
    const { classes } = this.props;
    let main_component = <HistoryListPageComponentContainer />;
    if (!this.props.app_state.current_user_id) main_component = <Skeleton active />;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Row>
            <Col lg={{ span: 12 }}>
              <div style={{ margin: 40 }}>
                <Title level={2}>
                  Workout History
                </Title>
                <p className={classes.hero_text}>
                  Easy access to your workout history
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div style={{ padding: 24 }}>
          {main_component}
        </div>
      </React.Fragment>
    );
  }
}

// export default DailyHiitsListPage;


const mapStateToProps = state => {
  return {
    app_state: state.app_state,
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
}

const HistoryListPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryListPage);

export default withStyles(styles)(HistoryListPageContainer);