import {
  CATEGORY_SET_ID,
  WB_START_NEW_WB_CREATION,
  WB_UPDATE_NEW_WB_CREATION,
  WB_SET_PROCESSING
} from "../actions/workout_builder.js";

const initialState = {
  new_workout_builder_data: {
    client_id: "",
    id: "",
    processing: false,
    workouts: [],
  },
  new_workout_builder_submitted: false,
  new_workout_builder_created: false,
  current_category_id: "1",
  //is_fetching: false,
  //did_invalidate: false,
  category_list: ['1', '2', '3'],
  // news_detail_fetching: false,
  // news_detail_loaded: false,
  // news_detail_id: null,
  // new_news_data: {},
  // new_news_type: null,
  // new_news_submitted: false,
  // new_news_created: false
};

function workout_builder(state = initialState, action) {
  switch (action.type) {

    case CATEGORY_SET_ID:
      return Object.assign({}, state, {
        current_category_id: action.value
      });

    case WB_START_NEW_WB_CREATION:
      console.log("=debug=");
      return Object.assign({}, state, {
        // staff_detail: action.staff_detail,
        new_workout_builder_data: {
          client_id: "",
          id: "",
          processing: false,
          workouts: [],
        },
        new_workout_builder_submitted: false,
        new_workout_builder_created: false
        // client_detail_loaded: true
        // staff_detail_loaded: true
      });
    case WB_UPDATE_NEW_WB_CREATION:
      return Object.assign({}, state, {
        new_workout_builder_data: Object.assign({}, state.new_workout_builder_data, {
          [action.value.key]: action.value.value
        }),
      });
    case WB_SET_PROCESSING:
      return Object.assign({}, state, {
        new_workout_builder_data: Object.assign({}, state.new_workout_builder_data, {
          processing: action.value.value
        }),
      });

    default:
      return state;
  }
}

export default workout_builder;