import React, { Component } from 'react';

// import { API, Auth } from "aws-amplify";

//import { Layout } from 'antd';
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Input } from 'antd';
// import { Button } from 'antd';
import { PageHeader } from 'antd';
import RegisterPageComponent from '../../components/pages/pages/RegisterPageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultStateSample: "",
      collapsed: false,
    }
  }



  render() {
    // console.log(this.state);
    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Client Registration"
        // subTitle="This is a subtitle"
        />
        <RegisterPageComponent />


      </div>
    );
  }
}

export default RegisterPage;
